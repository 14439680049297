import React, { useEffect,useContext, useState, useRef } from "react";
import { useForm } from 'react-hook-form';
import { Box, Typography, Grid, Button, TextField, Autocomplete } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import './style.css';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { AppContext, AppContextType } from "../../App";
import { PatientDemographics } from "../demographics/PatientDemographics";
import { RegularAutocomplete } from "../autocomplete";
import { Navigate, useNavigate } from "react-router-dom";
import { updatePatient } from '../../features/patient/patientSlice';
import  PatientService  from '../../services/PatientService';
import HashReturnObject from '../../models/HashReturnObject';
import Patient from '../../models/Patient';
import { onAppointmentTypesChange, setSessionState } from "../../features/session/sessionSlice";
import { getBaseAPI } from "../../environments/Environments";
import Spinner from "../spinner/Spinner";
import "./SelectAppointmentTime";
import { NavigatePage, PageName, getProperURL } from "../../utils/Util";
import useApptConfirmNav from "../hooks/useApptConfirmNav";
import { AppendLabel, Event, PageView } from '../../utils/GA';


const phoneValidation = /^[2-9]\d{9}$/
const SelectAppointmentTime = ({ }): JSX.Element => {

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [coverExist, setCoverExist] = useState(false);
    const { formData, setFormData } = useContext(AppContext) as AppContextType
    const [locationName, setLocationName] = useState("");
    const [pageLoading, setPageLoading] = useState(false);
    const state = useAppSelector(state => state)
    const [leapYearError, setLeapYearError] = useState("");
    const[hasDobState,setHasDobState]=useState(false);
    //If appointment confirmation is complete, force user back to the confirmation screen
    useApptConfirmNav();

    let validateday = formData.day
    let validatemonth = formData.month
    let validateyear = formData.year
    let dobInput = new Date(validateyear, validatemonth - 1, validateday);
    let currentDate = new Date()

    const validationSchema = yup.object().shape({
        firstName: yup.string()
            .required('First Name*')
            .matches(/^[A-Za-z ]*$/, 'Invalid First Name')
            .max(25, 'First Name must be at most 25 characters'),
        lastName: yup.string()
            .required('Last name *')
            .matches(/^[A-Za-z ]*$/, 'Invalid Last Name')
            .max(25, 'Last Name must be at most 25 characters'),
        pNumber: yup.string()
            .required('Phone Number*')
            .matches(phoneValidation, 'Invalid Phone number'),
        email: yup.string()
            .required('Email*')
            .email('Invalid Email')
            .matches(
                /^(([^<>()[\]\\.,;:\s@"&]+(\.[^<>()[\]\\.,;:\s@&]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Invalid Email Format'
            )
            .max(100, 'Email Address must be at most 100 characters'),
        day: yup.number().when('month',(months, schema) => {
                if ([4, 6, 9, 11].includes(months[0])){
                    return schema.max(30, 'DOB Date Invalid');
                }else if(months[0] == 2 ){
                    return schema.max(29, 'DOB Date Invalid');
                }else {
                    return schema.max(31, 'DOB Date Invalid')
                }
            })
            .required('day*')
            .typeError("Invalid Day")
            .min(1, "Invalid Day")
            .max(31, "Invalid Day"),
        month: yup.number()
            .required('month*')
            .typeError("Invalid Month")
            .min(1, "Invalid Month")
            .max(12, "Invalid Month"),
        year: yup.number()
            .required('year*')
            .typeError("Invalid Year")
            .min(1900, "year must be after 1900"),
    });

    const { register, handleSubmit, reset, formState: { errors }, formState } = useForm<any>({
        resolver: yupResolver(validationSchema), mode: "onChange" 
    });

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (state.availability.getInitialLocation)
            setLocationName(state.availability.getInitialLocation.Name) // + "-" + state.availability.getInitialLocation.Address)
        }, [state.availability.getInitialLocation]); 
    
    const getData = async () => {
        const url = await getBaseAPI() + '/api/v1/Patient/showPatientInfos';
        // const url = 'https://localhost:5001/api/v1/Patient/showPatientInfos';
        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                const newCovers = data.map((cover: any) => {
                    return cover.covers
                });
                const coverKey = state.session.CoverKey ? state.session.CoverKey.toString() : "";
                setCoverExist(newCovers.includes(coverKey));
               // dispatch(setSessionState({...state.session,CurrentPage :  PageName.SelectAppointmentTime}))
            });
    }
    useEffect(() => {
        PageView(
            `pageview_patient_info_screen_${AppendLabel(state.session.CoverKey, state.session.LocKey?.toString(), state.session.AcctKey)}`
        )        
        getData()
    }, []);

    useEffect(() => {
        if ( formData.year)
        {
        let strDate = formData.month + formData.day + formData.year;
        let DOB = new Date(strDate.replace(/(\d\d)(\d\d)(\d\d\d\d)/, "$3-$1-$2"));

        const newVal = { ...state.patient, FirstName: formData.firstName, LastName: formData.lastName, Home: formData.pNumber, Email: formData.email, DOB: DOB }
        dispatch(updatePatient(newVal));
        }
    }, [formData.firstName, formData.lastName, formData.pNumber, formData.email, formData.day, formData.month, formData.year])


    const handleClick = (data: any) => {
        setPageLoading(true)
        //GA-06
        Event(
            false,
            'click_continue_patient_info_screen',
            AppendLabel(state.session.CoverKey, state.session.LocKey?.toString(), state.session.AcctKey)
        )

        const provider = state.providers.providers.find((x) => x.AcctKey == state.availability?.availability.AcctKey);
        PatientService.getPatient(state.patient,{ ...state.session, AcctKey: provider?.AcctKey })
            .then((response: HashReturnObject<Patient>) => {
                setPageLoading(false)
                const updateSession = { ...state.session, AddKey: response.data[0].AddKey?.toString(), EmailAddress : formData.email }
                dispatch(setSessionState(updateSession))
                NavigatePage(dispatch,"next", state, navigate, PageName.PatientDemographics)
                //const url =  getProperURL('/PatientDemographics', state.embed)
                //navigate(url)                
                //navigate('/PatientDemographics')
            })
            .catch((e:any) => {
                console.error(e);
                setPageLoading(false)
                setOpen(true)
            });
    }

    const selectTime = state.availability.availability
    const dDate = new Date(selectTime.AppointmentDate).toDateString()
    const dTime = (selectTime.AppointmentDateTime)
    const TimeConvert =(date: any) => { 
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ap = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes.toString().padStart(2, '0')
        let mergeTime = hours + ':' + minutes + ' ' + ap;
        return mergeTime;
    }
    TimeConvert(dTime)

    const providerName = state.appointmentData.appointmentType.Name
    const providerFirstname = state.providers.provider.FirstName
    const ProviderLastName = state.providers.provider.LastName
    const providerDegree = state.providers.provider.Degree

    const filterNonNumeric = (e: any) => {
        const onlyDigits = e.target.value.replace(/\D/g, "");
        setFormData({ ...formData, [e.target.name]: onlyDigits })
    };

    const [monthValue, setMonthValue] = useState('')
    const [dateValue, setDateValue] = useState('')
    const [yearValue, setYearValue] = useState('')

    function isLeapYear(year: number) {
        return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)
    }

    
    const handleChangeMonth = (e: any) => {
        const checkError = errors?.month?.message && errors?.day?.message && errors?.year?.message
        const leapYear = isLeapYear(validateyear);
        const newMonth = e.target.value
        if (newMonth.length === 2) {
            setMonthValue(newMonth);
            document.getElementById("dateinput")?.focus()
        } else {
            setMonthValue(newMonth)
        }
        if (newMonth === "02" || newMonth === "2") {
            if (!leapYear) {
                if (validateday <= 28) {
                    setLeapYearError("")
                    setHasDobState(false)
                }
                else {

                    setLeapYearError("DOB Date Invalid")
                    setHasDobState(true)
                }
            }
            if (leapYear) {
                if (validateday > 29) {
                    if (checkError || checkError === undefined) {
                        setLeapYearError("DOB Date Invalid")
                        setHasDobState(true)
                    }
                    else {
                        setLeapYearError("")
                        setHasDobState(false)
                    }
                }
                else {
                    setLeapYearError("")
                    setHasDobState(false)
                }
            }
        }
        else{
            setLeapYearError("")
            setHasDobState(false)
        }
    }

    const handleChangeDate = (e: any) => {
        // setFormData({ ...formData, [e.target.name]: e.target.value })
        const checkError = errors?.month?.message && errors?.day?.message && errors?.year?.message
        const leapYear = isLeapYear(validateyear);
        const newDate = e.target.value
        if (newDate.length === 2) {
            setDateValue(newDate);
            document.getElementById("yearinput")?.focus()
        } else {
            setDateValue(newDate)
        }
        if (validatemonth === "02" || validatemonth === "2") {
            if (!leapYear) {
                if (newDate <= 28) {
                    setLeapYearError("")
                    setHasDobState(false)
                }
                else {

                    setLeapYearError("DOB Date Invalid")
                    setHasDobState(true)
                }
            }
            if (leapYear) {
                if (newDate > 29) {
                    if (checkError) {
                        setLeapYearError("DOB Date Invalid")
                        setHasDobState(true)
                    }
                    else {
                        setLeapYearError("")
                        setHasDobState(false)
                    }
                }
                else {
                    setLeapYearError("")
                    setHasDobState(false)
                }
            }
        }
    }
    const checkFutureDays = (month :any,days:any,year:any) =>{
        let dobInput = new Date(year, month - 1, days);
        let currentDate = new Date()
       

            if(dobInput >= currentDate){
                return setHasDobState(true)
            } 
            else{
                return setHasDobState(false)  
            }
        
    }
    const handleChangeYear = (e: any) => {
        // setFormData({ ...formData, [e.target.name]: e.target.value }) 
        const checkError = errors?.month?.message && errors?.day?.message && errors?.year?.message
        const newYear = e.target.value
        const leapYear = isLeapYear(newYear);
        if (/^\d{4}$/.test(newYear)) {
            setYearValue(newYear);
        } else {
            setYearValue(newYear)
        }
        if (validatemonth === "02" || validatemonth === "2") {
            if (!leapYear) {
                if (validateday <= 28) {
                    setLeapYearError("")
                    setHasDobState(false)
                }
                else {

                    setLeapYearError("DOB Date Invalid")
                    setHasDobState(true)
                }
            }
            if (leapYear) {
                if (validateday > 29) {
                    if (checkError) {
                        setLeapYearError("DOB Date Invalid")
                        setHasDobState(true)
                    }
                    else {
                        setLeapYearError("")
                        setHasDobState(false)
                    }
                }
                else {
                    setLeapYearError("")
                    setHasDobState(false)
                }
            }
        }
        checkFutureDays(validatemonth,validateday,newYear)
    }

    return <div className="p-2">
        <Box
            display={"flex"}
            alignItems={"center"}
            className="header-partical"
        >
            <Box flexGrow={1}>
                <Typography variant="h6"
                    fontWeight={"500"}
                    sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                    }} >
                    {locationName}
                </Typography>
            </Box>
            <Box sx={{}}>
                <Typography
                    variant="h6"
                    fontWeight={"500"}
                    sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                    }}
                >
                    <div>{dDate} - {TimeConvert(dTime)}</div>
                </Typography>
            </Box>
        </Box>
        <Box borderBottom={"0.5px solid #979797 !important"} className="Box-p heading-box">
            <div className="Typogrphy-pb title-name"><b>{providerName}</b> <span className="patient-name-title">with <b>{providerFirstname} {ProviderLastName},{providerDegree}</b></span></div>
        </Box>
        <form className="form-div">
            <Box borderBottom={"0.5px solid #979797 !important"} className="Box-p patient-details-wrapper">
                <Typography className="Typogrphy-p">
                    <Typography
                        variant="h6"
                        fontWeight={"500"}
                        sx={{
                            fontSize: "20px",
                            fontWeight: "600",
                            lineHeight: "27.24px",
                            margin: "15px 0px",
                        }}
                    >
                        Please enter the patient's information below:
                    </Typography>
                    {
                        coverExist ? <Typography sx={{ marginBottom: "15px" }}>If you're attempting to book for another person, please add their (not your) information below, to ensure the correct person is scheduled:</Typography> : null
                    }
                    <div className="form-control">
                        <label className="field-label">First Name*</label>
                        <div className="error">
                            <> {errors.firstName?.message}  </>
                            <input  {...register("firstName", { onChange: (e) => setFormData({ ...formData, [e.target.name]: e.target.value }) })} value={formData.firstName} name='firstName' type="text" placeholder="First Name" maxLength={25}/>
                        </div>
                    </div>
                    <div className="form-control">
                        <label className="field-label">Last Name*</label>
                        <div className="error">
                            <>{errors?.lastName?.message}</>
                            <input {...register("lastName", { onChange: (e) => setFormData({ ...formData, [e.target.name]: e.target.value }) })} name='lastName' value={formData.lastName} type="text" placeholder="Last Name" maxLength={25}/>
                        </div>
                    </div>
                    <div className="form-control">
                        <label className="field-label">Email Address*</label>
                        <div className="error">
                            <>{errors?.email?.message}</>
                            <input {...register("email", { onChange: (e) => setFormData({ ...formData, [e.target.name]: e.target.value }) })} value={formData.email} name='email' type="text" placeholder="Email Adress" maxLength={100}/>
                        </div>
                    </div>
                    <div className="form-control">
                        <label className="field-label">Phone Number*</label>
                        <div className="error">
                            <>{errors?.pNumber?.message}</>
                            <input {...register("pNumber", { onChange: (e) => filterNonNumeric(e) })} value={formData.pNumber} name='pNumber' type="text" placeholder="Phone Number" maxLength={10} />
                        </div>
                    </div>
                    <div className="form-control date-error-msg">
                        <span className="errorMessage">
                            <>
                                { errors?.month?.message ||
                                errors?.day?.message || 
                                errors?.year?.message}
                                {dobInput >= currentDate && 'Dob must in the past'}
                                {leapYearError}
                            </>
                        </span>
                    </div>
                    <div className="form-control date-control">                    
                    <label className=" field-label date-lable">Date Of Birth*</label>                   
                    <div className="dob birth-date-dob">
                        <div className="form-control">
                            <div className="error">
                                <input 
                                {...register("month", { onChange: (e) => setFormData({ ...formData, [e.target.name]: e.target.value }) || handleChangeMonth(e) },)} value={formData.month } name='month' type="text" placeholder="MM" maxLength={2}/>
                            </div>
                            {/* <span className="errorMessage"><>{errors?.month?.message}
                                {dobInput >= currentDate && 'Dob must in the past'}
                            </></span> */}
                        </div>
                        <div className="form-control">
                            <div className="error">
                                <input {...register("day", { onChange: (e) => setFormData({ ...formData, [e.target.name]: e.target.value }) || handleChangeDate(e) })} value={formData.day } name='day' type="text" placeholder="DD" maxLength={2} id="dateinput" />
                            </div>
                            {/* <span className="errorMessage"><>{errors?.day?.message}</> </span> */}
                        </div>
                        <div className="form-control">
                            <div className="error">
                                <input {...register("year", { onChange: (e) => setFormData({ ...formData, [e.target.name]: e.target.value }) || handleChangeYear(e) })} value={formData.year } name='year' type="text" placeholder="YYYY" maxLength={4} id="yearinput"/>
                            </div>
                            {/* <span className="errorMessage"><>{errors?.year?.message}</></span> */}
                        </div>
                    </div>
                    </div>
                </Typography>
            </Box>
            <Box>
                <Grid className="text-continue">
                    <span>We always ensure <b> your data is secure</b> and <b>never share </b> your personal information with any third parties</span>
                    {
                        hasDobState ?  <Button variant="contained" style={{ backgroundColor: "var(--fpc-blue)" }} disabled={hasDobState} onClick={handleClick} >Continue</Button>:
                        <Button variant="contained" style={{ backgroundColor: "var(--fpc-blue)" }} disabled={!formState.isValid || pageLoading} onClick={handleClick} >Continue</Button>
                    }
                </Grid>
            </Box>
        </form>
        <Snackbar
           anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            autoHideDuration={4000}
            onClose={() => setOpen(!open)}
            message="Application encountered an error. Please try again."
         />        
        {pageLoading === true &&
      <>
          <div className="spinner">
              <Spinner />
          </div>

      </>}        
    </div>
}

export { SelectAppointmentTime };

