import { SessionModel } from "./Session"

export default interface Patient {
  AddKey: string | undefined | null
  , AcctKey: string | undefined | null
  , InsKey: string | undefined | null
  , LocKey: string | undefined | null
  , Lang: string | undefined | null
  , PatientID: string | undefined | null
  , Status: string | undefined | null
  , FirstName: string | undefined | null
  , LastName: string | undefined | null
  , Title: string | undefined | null
  , Address1: string | undefined | null
  , Address2: string | undefined | null
  , City: string | undefined | null
  , State: string | undefined | null
  , Zip: string | undefined | null
  , Home: string | undefined | null
  , Work: string | undefined | null
  , Cel: string | undefined | null
  , Email: string | undefined | null
  , Fax: string | undefined | null
  , Created: string | undefined | null
  , L4DSSN: string | undefined | null
  , Country: string | undefined | null
  , DOB: Date | undefined | null
  , IsValid: boolean | undefined | null
  ,Gender : string | undefined | null
}

export interface PatientDetailsData {
  addKey: string | undefined | null,
  acctKey: string | undefined | null,
  insKey: string | undefined | null,
  locKey: string | undefined | null,
  lang: string | undefined | null,
  patientID: string | undefined | null,
  status: string | undefined | null,
  firstName: string | undefined | null,
  lastName: string | undefined | null,
  title: string | undefined | null,
  address1: string | undefined | null,
  address2: string | undefined | null,
  city: string | undefined | null,
  state: string | undefined | null,
  zip: string | undefined | null,
  home: string | undefined | null,
  work: string | undefined | null,
  cel: string | undefined | null,
  email: string | undefined | null,
  fax: string | undefined | null,
  created: string | undefined | null,
  l4DSSN: string | undefined | null,
  country: string | undefined | null,
  dob: Date | undefined | null,
  gender: string | undefined | null,
}

export const createEmptyPatient : Patient = {
  AddKey: ''
    ,AcctKey: ''
    ,InsKey: ''
    ,LocKey: ''
    ,Lang: ''
    ,PatientID: ''
    ,Status: ''
    ,FirstName: ''
    ,LastName: ''
    ,Title: ''
    ,Address1: ''
    ,Address2: ''
    ,City: ''
    ,State: ''
    ,Zip: ''
    ,Home: ''
    ,Work: ''
    ,Cel: ''
    ,Email: ''
    ,Fax: ''
    ,Created: ''
    ,L4DSSN: ''
    ,Country: ''
    ,DOB: new Date()
    ,IsValid: false
    ,Gender:''
};

export function mapPatientDetailsData(data: any): Patient {
  return {
    AddKey: data.addKey,
    AcctKey: data.acctKey,
    InsKey: data.insKey,
    LocKey: data.locKey,
    Lang: data.lang,
    PatientID: data.patientID,
    Status: data.status,
    FirstName: data.firstName?.trim(),
    LastName: data.lastName?.trim(),
    Title: data.title?.trim(),
    Address1: data.address1?.trim(),
    Address2: data.address2?.trim(),
    City: data.city?.trim(),
    State: data.state?.trim(),
    Zip: data.zip?.trim(),
    Home: data.home?.trim(),
    Work: data.work?.trim(),
    Cel: data.cel?.trim(),
    Email: data.email?.trim(),
    Fax: data.fax?.trim(),
    Created: data.created,
    L4DSSN: data.l4DSSN,
    Country: data.country?.trim(),
    DOB: data.dob ? new Date(data.dob) : null,
    IsValid: true,
    Gender: data.gender
  }
}

export function mapPatientDataForRetrieve(data: Patient, session: SessionModel): unknown {
  return {
    "firstName": String(data.FirstName?.trim().replace(' ', '') ?? ''),
    "lastName": String(data.LastName?.trim().replace(' ', '') ?? ''),
    "home": String(data.Home?.trim().replace(' ', '') ?? ''),
    "email": String(data.Email?.trim().replace(' ', '') ?? ''),
    "dob": data.DOB,
    "logKey": String(session.LogKey ?? ''),
    "coverKey": String(session.CoverKey ?? ''),
    "groupKey": 0,
    "locKey": String(session.LocKey ?? ''),
    "acctKey": String(session.AcctKey ?? ''),
    "addKey": 0,
    "taskKey": 0,
    "apptKey": 0,
    "currentPage": 0
  }
}
export function mapPatientDataForSave(data: Patient, session: SessionModel): unknown {
  return {
    "logKey": session.LogKey ?? '',
    "coverKey": session.CoverKey ?? '',
    "groupKey": session.GroupKey ?? '',
    "locKey": session.LocKey ?? '',
    "acctKey": session.AcctKey ?? '',
    "addKey": session.AddKey ?? '',
    "taskKey": session.TaskKey ?? '',
    "apptKey": session.ApptKey ?? '',
    "apptDateTime": session.ApptDateTime,
    "currentPage": session.CurrentPage ?? 0,
    "data": {
      "addKey": 0,
      "locKey": session.LocKey,
      "acctKey": session.AcctKey,
      "status": 0,
      "fax": "",
      "created": new Date(),
      "country": "",
      "patientID": "",
      "title": "",
      "firstName": data.FirstName?.trim(),
      "lastName": data.LastName?.trim(),
      "home": data.Home?.trim(),
      "work": "",
      "cel": "",
      "email": data.Email?.trim(),
      "address1": "",
      "address2": "",
      "city": "",
      "state": "",
      "zip": "",
      "dob": data.DOB,
      "gender": "",
      "lang": "",
      "insKey": "",
      "l4DSSN": "",
      "bPatIsKnown": "",
      "pRefB": ""
    }
  };
}