/*REACT IMPORTS*/
import React, { useState, useEffect } from "react";
//
/* MUI Imports*/
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import { AutocompleteRenderInputParams } from "@mui/material/Autocomplete";
import { InputProps } from "@mui/material/Input";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
//
/* ICONS*/
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneSharpIcon from "@mui/icons-material/PhoneSharp";
import AccessTimeSharpIcon from "@mui/icons-material/AccessTimeSharp";
//
/* MOCK DATA*/

//
/* COMPONENTS */

//

/* TYPES */
import { ILocationData, ITimeInfo } from "../../models";
import LocationDetails from "../../models/LocationDetails";
import { useSelector } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import ColorSelect from "../select/ColorSelect";

interface ILocationProps {
  onLocationSelection: (data: LocationDetails) => void;
  locations: LocationDetails[];
  selectedLocation?: LocationDetails | null;
}

const NewLocation = ({
  selectedLocation,
  locations,
  onLocationSelection,
}: ILocationProps) => {
  let state=useAppSelector(state => state)
  console.log(state.availability.getInitialLocation,selectedLocation,'7777')
  const gridSpacing = 2;
  selectedLocation= locations.length == 1 ? locations[0] : state.availability.getInitialLocation
  const renderLocation = (data: LocationDetails) => {
    return (
      <>
        {data.Name && <LocationOnIcon sx={{ mr: 1 }} />}
        {data.Name}
      </>
    );
  };

  const getLocationInputProps = (
    params: AutocompleteRenderInputParams
  ): InputProps => {
    if (!state.availability.getInitialLocation) return params.InputProps;
    const obj = {
      ...params.InputProps,
      startAdornment: (
        <InputAdornment position="start">
          <LocationOnIcon
            sx={{
              m: 1,
              color: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.common.black
                  : theme.palette.common.white,
            }}
          />
        </InputAdornment>
      ),
    };
    return obj;
  };

//   const getTimeInputProps = (
//     params: AutocompleteRenderInputParams
//   ): InputProps => {
//     if (!selectedTime) return params.InputProps;
//     const obj = {
//       ...params.InputProps,
//       startAdornment: (
//         <InputAdornment position="start">
//           <AccessTimeSharpIcon
//             sx={{
//               m: 1,
//               color: (theme) =>
//                 theme.palette.mode === "light"
//                   ? theme.palette.common.black
//                   : theme.palette.common.white,
//             }}
//           />
//         </InputAdornment>
//       ),
//     };
//     return obj;
//   };
console.log(state.availability?.getInitialLocation?.Name,'9797') 
  const RenderPhoneNumber = (): JSX.Element => {
    if (!state.availability.getInitialLocation) return <></>;

    return (
      <Box sx={{ ml: 1 }}>
        <Link
          href={`tel: + ${state.availability.getInitialLocation.PhoneNumberFormated}`}
          underline="none"
          color="inherit"
        >
          <Box display={"flex"}>
            <PhoneSharpIcon />
            <Typography sx={{ ml: 1, fontWeight: "700", mb: 2 }}>
              {state.availability.getInitialLocation.PhoneNumberFormated}
            </Typography>
          </Box>
        </Link>
      </Box>
    );
  };

  const toMapsUrl = (streetAddress: string): string => {
    const url = `https://www.google.com/maps/place/${encodeURIComponent(streetAddress)}`;
    return url;
  }

  const RenderAddress = (): JSX.Element => {
    if (!state.availability.getInitialLocation) return <></>;
    const initialLocAddress = state.availability.getInitialLocation?.Address;
    const addressFormat: any = initialLocAddress?.split(' ')
    const add1 = addressFormat.slice(0, addressFormat.length/2).join(" ")
    const add2 = addressFormat.slice(addressFormat.length/2).join(" ")
    return (
      <Box sx={{ ml: 1 }}>
        <Typography>{add1}</Typography>
        <Typography>
          {/* {`${selectedLocation?.address.city}, ${selectedLocation?.address.state} ${selectedLocation?.address.zipCode}`} */}
          {add2}
        </Typography>
        {initialLocAddress !== undefined &&
          <Link href={toMapsUrl(initialLocAddress)}
                target="_blank"
                rel="noopener"
                className="get-directions-link">Get directions</Link>
        }
      </Box>
    );
  };

  const renderTime = (data: ITimeInfo) => {
    return (
      <>
        <AccessTimeSharpIcon sx={{ mr: 1 }} />
        {data.timeName}
      </>
    );
  };

  const validateLocationSelection = (
    option: LocationDetails,
    value: LocationDetails
  ) => {
    return (
      option.LocKey === value.LocKey &&
      option.Name === value.Name
    );
  };
  const validateTimeSelection = (option: ITimeInfo, value: ITimeInfo) => {
    return option.timeName === value.timeName && option.timeId === value.timeId;
  };
  return (
    <Grid container spacing={gridSpacing} direction={"column"}>
     {
      (state.appointmentData.appointmentType?.AcctKey || state.availability.getInitialLocation) &&  
      <Grid item xs={12} md={12} sm={12} lg={12}>
        <ColorSelect
          data={locations}
          getOptionLabel={(item) => item.Name}
          renderOptions={renderLocation}
          selected={state.availability.getInitialLocation }
          onSelection={onLocationSelection}
          size={"small"}
          placeholder={"Select Location"}
          validateOptionSelected={validateLocationSelection}
        />
      </Grid>
/*       ):(
        <Grid item xs={12} md={12} sm={12} lg={12}>
          <Box display={"flex"} sx={{ ml: 1 }}>
         {state.availability.getInitialLocation?.Name  && <LocationOnIcon sx={{ mr: 1}} />}
        <Typography sx={{fontWeight: "700"}}>{state.availability.getInitialLocation?.Name}</Typography>
      </Box> 
      </Grid>
      ) */
     }
      
      <Grid item xs={12} md={12} sm={12} lg={12}>
        <RenderAddress />
      </Grid>
      <Grid item xs={12} md={12} sm={12} lg={12}>
        <RenderPhoneNumber />
      </Grid>
      {/* {locationTimes?.length > 0 && (
        <Grid item xs={12} md={12} sm={12} lg={12}>
          <RegularAutocomplete
            data={locationTimes}
            getOptionLabel={(item) => item.timeName}
            renderOptions={renderTime}
            selected={selectedTime}
            onSelection={onTimeSelection}
            getInputProps={getTimeInputProps}
            size={"small"}
            placeholder={"Select"}
            validateOptionSelected={validateTimeSelection}
          />
        </Grid>
      )} */}
    </Grid>
  );
};

export default NewLocation;
