import PatientInsurance, { mapPatientInsuranceFromApi, mapPatientInsuranceToApi } from '../models/PatientInsurance'
import HashReturnObject from '../models/HashReturnObject';
import { getBaseAPI } from "../environments/Environments";
import { reject } from "q";
import { StateModel } from '../models/StateModel';
import { mapSessionData, SessionModel } from '../models/Session';

const PatientInsuranceService = {
    getPatientInsurance: (state: StateModel): Promise<HashReturnObject<PatientInsurance>> => {
        return new Promise( async (resolve, reject) => {
            const url = await getBaseAPI() + '/api/v1/Patient/getPatient_Insurance';
            // const url = 'https://localhost:5001/api/v1/Patient/getPatient_Insurance';

            fetch(url, {
                method: 'POST',
                body: JSON.stringify(mapSessionData(state.session)),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => res.json())
                .then((result) => Promise.resolve(result))
                .then((hashReturnObject: HashReturnObject<PatientInsurance>) => {
                        resolve({
                            data: [ mapPatientInsuranceFromApi(hashReturnObject.data[0]) ],
                            hash: hashReturnObject.hash,
                            sessionId: hashReturnObject.sessionId
                        });
                })
                .catch((e) => {
                    console.log('request error', e);
                    reject(e);
                });
        });
    },
    savePatientInsurance: (patientInsurance: PatientInsurance, session: SessionModel): Promise<PatientInsurance> => {
        return new Promise(async (resolve) => {
            // const url = 'https://localhost:5001/api/v1/Patient/savePatient_Insurance';

            const url = await getBaseAPI() + '/api/v1/Patient/savePatient_Insurance';
            var json = JSON.stringify(mapPatientInsuranceToApi(patientInsurance, session))
            fetch(url, {
              method: 'POST',
              body: JSON.stringify(mapPatientInsuranceToApi(patientInsurance, session)),
              headers: {
                'Content-Type': 'application/json'
            }
            })
                .then((res) => res.json())
                .then((result) => Promise.resolve(result))
                .then((patientInsurance) => {
                    resolve(patientInsurance)
            })
                .catch((e) => {
                    console.log('request error', e);
                    reject(e);
                });
        });
    }
}
export default PatientInsuranceService;