import { useEffect, useState } from "react";
import useApptConfirmed from "./useApptConfirmed";
import { NavigatePage, PageName, getProperURL } from "../../utils/Util";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useNavigate } from "react-router-dom";

const navigationForcedKey = "navigationForced";

const setNavigationForced = (forced: boolean): void => {
  sessionStorage.setItem(navigationForcedKey, forced.toString());
}
const getNavigationForced = (): boolean => {
  const trueBool = true;
  return sessionStorage.getItem(navigationForcedKey) === trueBool.toString();
}

/**
 * Checks if the specified appointment has already been confirmed in this browser session, and if so,
 * automatically navigates to the appointment confirmation screen.
 */
const useApptConfirmNav = (): (() => void) => {
  const state = useAppSelector(state => state)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [confirmedAlready, setApptConfirmed] = useApptConfirmed();
  const [manuallyConfirmed, setManuallyConfirmed] = useState(false);

  const url =  getProperURL('/appointmentConfirmation', state.embed);
  useEffect(() => {
    if(confirmedAlready) {
        if(!manuallyConfirmed) {
          setNavigationForced(true);
        }
        NavigatePage(dispatch,"next",state,navigate,PageName.appointmentConfirmation)
       // navigate(url)
    }
  }, [confirmedAlready, manuallyConfirmed, navigate, url]);

  useEffect(() => {
    if(manuallyConfirmed) {
      setApptConfirmed();
    }
  }, [manuallyConfirmed, setApptConfirmed]);

  const manualConfirm = (): void => {
    setManuallyConfirmed(true);
  }

  return manualConfirm;
}

/**
 * Displays an alert if the user has been forcibly navigated to the appointment confirmation page (e.g. due to
 * having clicked the back button)
 */
export const useNavigationForcedAlert = (): void => {
  useEffect(() => {
    if(getNavigationForced()) {
      setNavigationForced(false);

      //Introduce a split second pause to ensure the target page has been re-rendered before sending the alert, or else
      //the alert will look like it came from the page the user is being navigated from. There may be a better
      //way to handle this race condition, but using useState to ensure one render has occurred has not worked.
      setTimeout(() => {
        alert("This appointment has been confirmed and cannot be undone. Please do not resubmit the information.");
      }, 50);
    }
  }, []);
}

export default useApptConfirmNav;
