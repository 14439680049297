import { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { setEcpConfiguration } from '../features/embed/embedSlice'
import { setSessionState } from '../features/session/sessionSlice'
import { EcpConfigurationAction } from '../models/EcpPatientConfiguration'
import { SessionModel } from '../models/Session'
import { EcpPatientConfigurationService } from '../services/EcpPatientConfigurationService'
import { SessionService } from '../services/SessionService'
import { Grid, Container ,Typography } from "@mui/material";

const Session = ({ children }: any) => {
    const [isReady, setIsReady] = useState(false)
    const [isWithError, setIsWithError] = useState(false)
    const dispatch = useAppDispatch()
    const state = useAppSelector(state => state)
    function merge(embed: any, hash: any, session: any): SessionModel {
        const answer: any = {};
        for (const key in session) {
            if (session[key]) {
                answer[key] = session[key];
            } else if (hash[key]) {
                answer[key] = hash[key];
            } else if (embed[key]) {
                answer[key] = embed[key];
            }
        }
        return answer;
    }

    
    useEffect(() => {
 
        if (state.session.LogKey === undefined || state.session.LogKey == null) {
            setIsReady(false);  
            // const getSessionDetails = async () => {
                const url_string = window.location.href;
                const url = new URL(url_string);
               // const sessionId = url.searchParams.get("SessionID");
                const source = url.searchParams.get("Source");
                const CoverKey =  url.searchParams.get("CoverKey") ?? `${process.env.REACT_APP_DEFAULT_COVERKEY as string}`               

               SessionService(
                    String(state?.session?.LogKey ?? ""),
                    String(CoverKey),
                    source == undefined && source == null ? "" : source,
                    state,
                    dispatch
                ).then((session) => {
                    setIsReady(true); 
                 })

        }
        else
           setIsReady(true); 
    }, [])
    return <>{isReady && !isWithError ? 
        children :
        !isWithError ?
        <Container >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={8}
                        lg={8}
                        xl={8}
                        display={{ xs: "block", sm: "block", md: "block", lg: "block" }}
                    >
                            <Typography
                            variant="h6"
                            fontWeight={"700"}
                            sx={{ 
                                fontSize: "22px", 
                                lineHeight: "25px", 
                                textAlign: "center",
                                marginTop : "25px"
                            }}
                            className="Loading"
                            >
                               Loading Please wait ...  
                            </Typography>                        
                                   
                    </Grid>
                </Grid> 
            </Grid> 
          </Container>
          :
          <Container >
          <Grid container spacing={2}>
              <Grid item xs={12}>
                  <Grid
                      item
                      xs={12}
                      sm={6}
                      md={8}
                      lg={8}
                      xl={8}
                      display={{ xs: "block", sm: "block", md: "block", lg: "block" }}
                  >
                          <Typography
                          variant="h6"
                          fontWeight={"700"}
                          sx={{ 
                              fontSize: "22px", 
                              lineHeight: "25px", 
                              textAlign: "center",
                              marginTop : "25px"
                          }}
                          className="Loading"
                          >
                             Error Getting Cover Informatoin. Please contact the administrator. 
                          </Typography>                        
                                 
                  </Grid>
              </Grid> 
          </Grid> 
        </Container>          
       }</>
}
export default Session