export interface SessionModel {
    LogKey: string | null | undefined;
    CoverKey: string | null | undefined;
    LocKey: string | null |number| undefined;
    GroupKey: string | null | undefined;
    AcctKey: string | null | undefined;
    AddKey: string | null | undefined;
    TaskKey: string | null | undefined;
    ApptKey: string | null | undefined;
    ClientIP: string | null | undefined;
    CreatedOn: string | null | undefined;
    CurrentPage: number | null | undefined;
    ApptDateTime: Date | null | undefined | any;
    SkipDemographics?: boolean;
    StandAlone?: boolean;
    SkipPatientInsurance?: boolean;
    SkipCustomQuestions?: boolean;
    V4Scenario: number | null | undefined;
    TrackingID?: string | null | undefined;
    OrgName?: string | null | undefined;
    DesktopLogo?: string ;
    MobileLogo?: string ;
    LastPage? : boolean;
    EmailAddress? : string | null | undefined;
  }


export function mapSessionData(session: SessionModel): unknown {
    return {
      "logKey": session.LogKey ?? '',
      "coverKey": session.CoverKey ?? '',
      "groupKey": session.GroupKey ?? '',
      "locKey": session.LocKey ?? '',
      "acctKey": session.AcctKey ?? '',
      "addKey": session.AddKey ?? '',
      "taskKey": session.TaskKey ?? '',
      "apptKey": session.ApptKey ?? '',
      "apptDateTime": session.ApptDateTime,
      "currentPage": session.CurrentPage ?? 0,
      "trackingID": session.TrackingID ?? '0',
    };
  }

  export function mapServiceProviderData(session: SessionModel): unknown {
    return {
      "logKey": session.LogKey ?? '',
      "coverKey": session.CoverKey ?? '',
      "groupKey": session.GroupKey ?? '',
      "locKey": '',
      "acctKey": session.AcctKey ?? '',
      "addKey": session.AddKey ?? '',
      "taskKey": session.TaskKey ?? '',
      "apptKey": session.ApptKey ?? '',
      "apptDateTime": session.ApptDateTime,
      "currentPage": session.CurrentPage ?? 0,
      "trackingID": session.TrackingID ?? '0',
    };
  }
