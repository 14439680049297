import CustomQuestionsService from "../../services/CustomQuestionService";
import React, { useEffect, useContext, useState } from 'react';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Grid, Box, Button, Typography } from '@mui/material';
import { CustomQuestionsModel } from '../../models/CustomQuestion';
import { useNavigate } from 'react-router-dom';
import './CustomQuestion.css';
import { mapSessionDataForSaveCustomQuestion } from '../../models/SaveCustomQuestion';
import { AppContext, AppContextType } from "../../App";
import  AppointmentValidation  from "../../components/appointment/AppointmentValidation";
import ExistingAppointmentService from '../../services/ExistingAppointmentService'; 
import HashReturnObject from "../../models/HashReturnObject";
import MultipleAppointmentDetails from "../../models/MultipleAppointments";
import { updateMultipleAppointments } from "../../features/MultipleAppointments/multipleAppointmentsSlice";
import Spinner from "../spinner/Spinner";
import { NavigatePage, PageName, getProperURL } from "../../utils/Util";
import { AppendLabel, Event , PageView} from '../../utils/GA';

const CustomQuestion: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const state = useAppSelector(state => state)
    const selectTime = state.availability.availability
    const dDate = new Date(selectTime.AppointmentDate).toDateString()
    const dTime = (selectTime.AppointmentDateTime)
    const TimeConvert =(date: any) => { 
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ap = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes.toString().padStart(2, '0')
        let mergeTime = hours + ':' + minutes + ' ' + ap;
        return mergeTime;
    }
    TimeConvert(dTime)

    const providerName = state.appointmentData.appointmentType.Name
    const providerFirstname = state.providers.provider.FirstName
    const ProviderLastName = state.providers.provider.LastName
    const providerDegree = state.providers.provider.Degree
    const [capName, setCapName] = useState("");
    const [locationName, setLocationName] = useState("");
    const { formData, setFormData } = useContext(AppContext) as AppContextType
    const patientFirstName = state.patient.FirstName;
    const patientLastName = state.patient.LastName;
    const patientEmail = state.patient.Email;
    const [showPage, setShowPage] = useState(false);
    const dispatch = useAppDispatch();

    const [customQuestions, setCustomQuestions] = useState<CustomQuestionsModel[]>([]);
    const [canContinue, setCanContinue] = useState<boolean>(false);
    useEffect(() => {

        const provider = state.providers.providers.find((x) => x.AcctKey == state.availability?.availability.AcctKey);
        CustomQuestionsService.retrieveCustomQuestions({ ...state.session, AcctKey: provider?.AcctKey })
            .then((response) => {
                response.filter(r => r.setting !== '-1')
                const appended = response.map((r, k) => {
                    return {
                        ...r,
                        setting: r.setting?.toString(),
                        answer: '',
                        error: ''
                    }
                })
                if (response.length > 0)
                {
                    setShowPage(true)
                    setCustomQuestions(appended)
                }
                else
                {
                    ValidateAppointment();
                }             
            });
            capitalize(patientFirstName); 
    }, []);
    useEffect(() => {
        // Validate
        let arr: any = []
        customQuestions.forEach((c) => {
            if (c.answer === '' && c.setting === '1') {
                arr.push(false)
            } else {
                arr.push(true)
            }
        })
        const isAllValid = arr.every((e: any) => e === true);
        setCanContinue(isAllValid)
    }, [customQuestions])


    useEffect(() => {
        if (state.availability.getInitialLocation)
            setLocationName(state.availability.getInitialLocation.Name) //+ "-" + state.availability.getInitialLocation.Address)
        }, [state.availability.getInitialLocation]); 

        useEffect(() => {
            PageView(
                `pageview_custom_question_screen_${AppendLabel(state.session.CoverKey, state.session.LocKey?.toString(), state.session.AcctKey)}`
            )
        },[])


    const handleInputChange = (e: any, index: number) => {
        const { value } = e.target
        let formVal = [...customQuestions]
        formVal[index]['answer'] = value

        // Validate
        if (formVal[index].setting === '1' && value === '') {
            formVal[index]['error'] = 'Required'
        } else {
            formVal[index]['error'] = ''
        }

        setCustomQuestions(formVal)

    }

    const capitalize = (data: any) => {
        let capitalizeName = data.charAt(0).toUpperCase() + data.slice(1);
        setCapName(capitalizeName);
    }
    const renderQuestions = () => {
        const jsx = customQuestions?.map((c: any, k: any) => {
            return (
                <div className={'parent-questions'} key={k}>
                    <div className="parent-question">
                        <div className="num-question"><strong>{`${k + 1}. `}</strong></div>
                        <div className="multi-question"><strong>{`${c.question} ${c.setting === '1' ? '*' : ''}`}</strong></div>
                    </div>
                    <input
                        className="input-question"
                        maxLength={50}
                        value={c.answer}
                        readOnly={false}
                        style={{ width: "100%" }}
                        onChange={(e) => handleInputChange(e, k)}       
                        
                    />
                </div>
            )
        })

        return jsx
    }
    const navigate = useNavigate()
    const handleClick = () => {
        setLoading(true)
        let stubapi = {
            sessionObject: mapSessionDataForSaveCustomQuestion(state.session),
            notes: '',
            pRefB: state.demographics.demographics.ReferredBy1,
            pRefB1: customQuestions[0] ? customQuestions[0].answer : '',
            pRefB2: customQuestions[1] ? customQuestions[1].answer : '',
            pRefB3: customQuestions[2] ? customQuestions[2].answer : '',
            pRefB4: customQuestions[3] ? customQuestions[3].answer : '',
            pRefB5: customQuestions[4] ? customQuestions[4].answer : '',
        }

        CustomQuestionsService.saveCustomQuestion(stubapi).then((response) => {
            console.log(response);
            ValidateAppointment();
        });

        // Navigate('/AppointmentValidation')

    //GA-09
	Event(
        false,
        'click_custom_question_screen',
        AppendLabel(state.session.CoverKey, state.session.LocKey?.toString(), state.session.AcctKey)
    )
    }

    const ValidateAppointment = () => 
    {
        ExistingAppointmentService.existingAppointment({
            ...state.session,
        TaskKey: state.availability.availability.TaskKey,
        AcctKey: state.availability.availability.AcctKey,
        LocKey: state.availability.availability.LocKey
            })
            .then((mutipleAppointment: HashReturnObject<MultipleAppointmentDetails>) => {
                setLoading(false)
                if(mutipleAppointment.data.length > 0){
                    dispatch(updateMultipleAppointments(mutipleAppointment.data));
                    NavigatePage(dispatch, "next", state, navigate, PageName.MultipleAppointment)
                    //const url =  getProperURL('/multipleAppointment', state.embed)
                   // Navigate(url)                    
                  //  Navigate('/multipleAppointment')
                }
                else{
                    NavigatePage(dispatch,"next", state, navigate, PageName.AppointmentVisitDetails)
                    //const url =  getProperURL('/appointmentvisitdetails', state.embed)
                    //Navigate(url)           
                    //Navigate('/appointmentvisitdetails')
                }
            })
            .catch((err) => {
                console.error(err);
            })
    }
    
    return (
        <>
        { showPage && 
            <div className="p-2">
                <Box display={"flex"}
                    alignItems={"center"}
                    className="header-partical">
                    <Box flexGrow={1}>
                        <Typography variant="h6"
                            fontWeight={"500"}
                            sx={{
                                fontSize: "16px",
                                fontWeight: "600",
                            }} >
                            {locationName}
                        </Typography>
                    </Box>
                    <Box sx={{}}>
                        <Typography
                            variant="h6"
                            fontWeight={"500"}
                            sx={{
                                fontSize: "16px",
                                fontWeight: "600",
                            }}
                        >
                            <div>{dDate} - {TimeConvert(dTime)}</div>
                        </Typography>
                    </Box>
                </Box>
                <Box borderBottom={"0.5px solid black !important"} className="Box-p">
                     <div className="Typogrphy-pb title-name"><b>{providerName}</b><span className="patient-name-title"> with <b>{providerFirstname} {ProviderLastName},{providerDegree}</b></span></div>
                </Box>
                <Box borderBottom={"0.5px solid #979797 !important"} className="Box-p">
                    <div className="Typogrphy-pb title-name">Patient:<b>{" " + capName + " " + patientLastName}</b>  <span className="patient-name-title"> &nbsp;&nbsp;&nbsp;Email: <b>{patientEmail}</b></span></div>
                </Box>
                <Box className="Box-p">
                    <form className="form-div">
                        <Box borderBottom={"!important"} >
                            <Typography className="Typogrphy-p">
                                <Typography
                                    variant="h4"
                                    fontWeight={"500"}
                                    sx={{
                                        fontSize: "24px",
                                        fontWeight: "630",
                                        lineHeight: "1.2",
                                    }}
                                >
                                    We like to learn more about you!
                                </Typography>
                                <br />

                                <Typography
                                    variant="h6"
                                    fontWeight={"500"}
                                    sx={{
                                        fontSize: "16px",
                                        fontWeight: "420",
                                    }}
                                >
                                    Please answer the following questions to help us prepare for your upcoming appointment.
                                </Typography>


                            </Typography>
                        </Box>
                        <br />
                        <Box>

                            {renderQuestions()}

                        </Box>
                        <Box className="footerdata">
                            <span className="footer-form"></span>
                            <Grid className="text-continue">
                                <Button style={{backgroundColor : "var(--fpc-blue)"}}  variant="contained" disabled={!canContinue} onClick={handleClick}>Continue</Button>
                            </Grid>
                        </Box>
                    </form>
                </Box>
            </div>}
            
            { loading === true && 
            <>
                <div className='spinner'>
                    <Spinner />
                </div>

            </> }
        </>
    )
}

export default CustomQuestion