import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EmbedState, EcpConfigurationAction } from "../../models/EcpPatientConfiguration";

const initialState: EmbedState = {
    CoverKey: null,
    LocKey: null,
    GroupKey: null,
    AcctKey: null,
    TaskKey: null,
    Path: null,
    Stylings: null,
    SkipDemographics: false,
    StandAlone: false,
    SkipPatientInsurance: false,
    SkipCustomQuestions: false,
    SkipPatientInformation : false,
    TrackingID: null,
    Embed : null
}

export const embedSlice = createSlice({
    name: 'embed',
    initialState,
    reducers: {
        setEmbedState: (
            state,
            action
        ) => {
            if (action.payload) {
                state = action.payload
            }
        },
        setEcpConfiguration: (
            state,
            action: PayloadAction<EcpConfigurationAction>
        ) => {
            if (action.payload) {
                state.CoverKey = action.payload.CoverKey ? action.payload.CoverKey : state.CoverKey;
                state.LocKey = action.payload.LocKey ? action.payload.LocKey : state.LocKey;
                state.AcctKey = action.payload.AcctKey ? action.payload.AcctKey : state.AcctKey;
                state.TaskKey = action.payload.TaskKey ? action.payload.TaskKey : state.TaskKey;
                state.Stylings = action.payload.Stylings ? action.payload.Stylings : state.Stylings;
                state.SkipCustomQuestions = action.payload.SkipCustomQuestions ? action.payload.SkipCustomQuestions : state.SkipCustomQuestions;
                state.SkipDemographics = action.payload.SkipDemographics ? action.payload.SkipDemographics : state.SkipDemographics;
                state.SkipPatientInsurance = action.payload.SkipPatientInsurance ? action.payload.SkipPatientInsurance : state.SkipPatientInsurance;
                state.SkipPatientInformation = action.payload.SkipPatientInformation ? action.payload.SkipPatientInformation : state.SkipPatientInformation;
            }
        }
    }
})
export const { setEmbedState, setEcpConfiguration } = embedSlice.actions
export default embedSlice.reducer