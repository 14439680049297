// @mui
import React, { useEffect, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate , useLocation } from "react-router-dom"
import { useAppSelector } from "../../../app/hooks";
import { AppendLabel, Event , PageView} from '../../../utils/GA'
// utils
import { bgBlur } from "../../../utils/cssStyles";
import { Form } from "react-router-dom";

// ----------------------------------------------------------------------

const NAV_WIDTH = -1;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  //   ...bgBlur({ color: theme.palette.background.default }),
  //backgroundColor: alpha(theme.palette.primary.main, 0.8),
  //2065D1
  backgroundColor: theme.palette.primary.contrastText,
  color: "#000",
  borderBottom: "10px solid  var(--fpc-blue)",
  boxShadow: "none",
  backdropFilter: `blur(${6}px)`,
  WebkitBackdropFilter: `blur(${6}px)`,
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

export default function Header() {
  const navigate = useNavigate()
  const [locationName, setLocationName] = useState("");
  const [coverDesktopLogo, setCoverDesktopLogo] = useState<any>("");
  const [coverMobileLogo, setCoverMobileLogo] = useState<any>("");
  const location = useLocation()
  const state = useAppSelector(stat => stat)
  const [timeDate, setSetTimeDate] = useState<any>(new Date().getTime());

/*   useEffect(() => {

    if (state.session.LocKey && state.session.DesktopLogo !== "")
    {
        var d = new Date()
        if (state.session.OrgName)
          setLocationName(state.session.OrgName)
        
        var logo = state.session.DesktopLogo !== "" && state.session.DesktopLogo ? state.session.DesktopLogo + "?ver=" + d.getTime() : ""

        setCoverDesktopLogo(logo)
        setCoverMobileLogo(state.session.MobileLogo)
    }
  }, [state.session]);  */


  const handleClickBack = () => {
  //GA-11
      Event(
        false,
        'click_back_header',
        AppendLabel(state.session.CoverKey, state.session.LocKey?.toString(), state.session.AcctKey)
    )
    navigate(-2)
}

  return (
    <StyledRoot>
      <StyledToolbar >
        {/* <IconButton>
          <NavigateBeforeIcon />
        </IconButton> */}
        {(location.pathname == "/appointmentwindow" || location.pathname == "/SelectAppointmentTime" || 
          location.pathname == "/PatientDemographics" || location.pathname == "/visioninsurance" || 
          location.pathname == "/multipleAppointment" || location.pathname == "/appointmentvisitdetails" || 
          location.pathname == "/CustomQuestion") &&
        <Button
          variant="text"
          color="inherit"
          sx={{ textTransform: "none", display: "flex" }}
          startIcon={<NavigateBeforeIcon />}
          disableRipple
          onClick={handleClickBack}
        >
          Back
        </Button>
        }       
        {/* <Searchbar />*/}
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
          
        >
          {(state.session.DesktopLogo !== "" && state.session.DesktopLogo) && <Box
            component="img"
            sx={{
              height : "auto",
              width : "auto",
              maxHeight: 80,
              maxWidth: 280,
            }} 
            alt={locationName}
            src={state.session.DesktopLogo !== "" && state.session.DesktopLogo ? state.session.DesktopLogo + "?ver=" + timeDate : ""}
          />}          
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
