import LocationDetails from "../models/LocationDetails"
import LandingPage from "./LandingPage";

/**
 * Use this to customize any differences between the normal landing page and the "iframe.html" version
 */

interface IIframeLandingProps {
  newLocations: LocationDetails[]
}
const IframeLanding = (props: IIframeLandingProps) => {
  return <LandingPage newLocations={props.newLocations} />
}

export default IframeLanding;
