import React,{Children, useEffect, useState} from "react"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import { setHashProperties } from "../features/hasher/hasherSlice"
import { getStateFromHash } from "../services/UrlHashingService"

const UrlHasher = ({children}: any)=> {
    const [isReady , setIsReady] = useState(false)
    const dispatch = useAppDispatch()
    const state = useAppSelector(state=> state)
    useEffect(()=> {
        getStateFromHash(window.location.hash).then((res)=> {
            dispatch(setHashProperties(res))
            setIsReady(true)
        })
    },[])
    useEffect(()=> {
        if (state.hash.Hash) {
            window.location.hash = state.hash.Hash
        }
    })
    return <> {
        isReady && children
    }
    </>
}
export default UrlHasher