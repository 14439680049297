import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from '@mui/material'
import "./AppointmentConfirmation.css";
import { useNavigate } from 'react-router-dom';
import { AppContext, AppContextType } from "../../App";
import FlagIcon from '@mui/icons-material/Flag';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { NavigatePage, PageName } from "../../utils/Util";
import { setSessionState } from "../../features/session/sessionSlice";



function AppointmentNotAvailable() {

    const state = useAppSelector(state => state)
    const startTime = state?.availability.availability.AppointmentDateTime;
    const endTime = new Date(state?.availability?.availability.AppointmentDateTime?.getTime() + 30*60000).toISOString().replace(/-/g, "").replace(/:\s*/g, "");
    const { formData, setFormData } = useContext(AppContext) as AppContextType;
    const ics = require('ics');
       const patientFirstName = state.patient.FirstName;
    const patientLastName = state.patient.LastName;
    const patientEmail = state.patient.Email;
    const homeNumber = state.patient.Home;
    const [customMessage, setCustomMessage] = useState("")

    let dtFormat = new Intl.DateTimeFormat('en-US',{
        day:'2-digit',
        month:'short',
        year:'numeric',
        hour:'numeric',
        minute:'numeric'
    })

    const Navigate = useNavigate();
    const dispatch = useAppDispatch();
   
    const onSelectNewTime = () => {
        const updateSession = {...state.session, LastPage : true}
        dispatch(setSessionState(updateSession))
        NavigatePage(dispatch,"next",state,Navigate,PageName.LandingPage)
        //Navigate('/')
    }

    
   
    const onAppleCalClick = () => {
        ics.createEvent(Event, (error:any, value:any) => {
            if (error) {
                console.error(error);
            }

            const element = document.createElement('a');
            element.setAttribute('href', 'data:text/calendar;charset=utf-8,' + encodeURIComponent(value));
            element.setAttribute('download', "event.ics");

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        })
    }
  
    const handleClickBack = () => {
        Navigate(-1)
    }
    return (
        <div>
            <div className='confirmation-appointment-page'>
                <div className='header-texts'>
                    <Typography variant="h6"
                        color={"red"}
                        fontWeight={"500"}
                        sx={{
                            fontSize: "18px",
                            fontWeight: "600",
                        }} >
                        Oops! The time you initially selected is no longer available. Please click &lsquo;Select a New Time&rsquo; to choose an available appointment. 
                    </Typography>
                </div>
                <div className='box details-box'>
                    <div className='confirmation-appointment-label'>
                        <div className='mainPage inner-box details-container'>
                            <div className='header-text appointment-details-header-text'>
                                {/* <Box className="icon appointment-icon" sx={{ p: 6 }}><FlagIcon/></Box> */}
                                <p className="icon appointment-icon"><FlagIcon/></p>
                                <p className='appointment-details-header header-texts'>Appointment Details.</p>
                            </div>
                            <div>
                                <p className='confirmationetials details-heading'>Time</p>
                                <p>{dtFormat.format(startTime)} </p>
                            </div>
                            <div>
                                <p className='confirmationetials details-heading'><b>Location</b></p>
                               <p> {`${state?.appointmentData.locations[0].Address}`}</p>
                            </div>
                            <div>
                                <p className='confirmationetials details-heading head-title confirmation-height'><b>Doctor</b></p>
                                <div className='confirmation-height'>
                                    <p>Physician:  {`${state?.providers.provider.FirstName} ${state?.providers.provider.LastName}`}</p>
                                    <p>Appointment Type: {`${state?.appointmentData.appointmentType.Name}`}</p>
                                </div>
                            </div>
                            <div>
                                <p className='confirmationetials head-title confirmation-height'><b>Patient</b></p>
                                <div className='confirmation-height'>
                                    <p>Name: {state.patient.FirstName + " " + state.patient.LastName}</p>
                                    <p>Email: {state.patient.Email ?? ''}</p>
                                    <p>Phone: {state.patient.Home ?? ''}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Box className="footer-data footer-details" >
                <Button variant="contained" type="submit" sx={{ borderRadius: 10 }} onClick={onSelectNewTime}>
                    <div className='button-box'>Select a New Time</div>
                </Button>
            </Box>
        </div>
    )
}

export default AppointmentNotAvailable