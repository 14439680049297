import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from '@mui/material'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import "./AppointmentVisitDetails.css";
import ConfirmationService from '../../services/ConfirmationService';
import MultipleAppointmentSelected from "../../models/MultipleAppointmentSelected";
import dayjs from 'dayjs';
import Spinner from '../../components/spinner/Spinner';
import { NavigatePage, PageName, getProperURL } from "../../utils/Util";
import AppointmentKey from "../../models/AppointmentKey";
import useApptConfirmNav from "../hooks/useApptConfirmNav";
import { AppendLabel, Event , PageView} from '../../utils/GA';

function AppointmentVisitDetails() {
    const Navigate = useNavigate()
    const dispatch = useAppDispatch();
    const state = useAppSelector(state => state)
    const [locationName, setLocationName] = useState("");
    const [patientName, setPatientName] = useState("");
    const [patientEmail, setPatientEmail] = useState("");

    const selectTime = state.availability.availability
    const dDate = new Date(selectTime.AppointmentDate).toDateString()
    const dTime = (selectTime.AppointmentDateTime)
    const TimeConvert =(date: any) => { 
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ap = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes.toString().padStart(2, '0')
        let mergeTime = hours + ':' + minutes + ' ' + ap;
        return mergeTime;
    }
    TimeConvert(dTime)

    const appointmentType = state.appointmentData.appointmentType.Name
    const providerFirstname = state.providers.provider.FirstName
    const ProviderLastName = state.providers.provider.LastName
    const providerDegree = state.providers.provider.Degree
    const TaskKey = state.availability.availability.TaskKey;
    const AcctKey = state.availability.availability.AcctKey;
    const LocKey = state.availability.availability.LocKey;
    const LogKey = state.session.LogKey;
    const _session = state.session;
    const [multipleSelectedAppointments,setMultipleSelectedAppointments] = useState<MultipleAppointmentSelected[]>(state.multipleAppointmentSelected.multipleAppointmentSelected);
    const [loading, setLoading] = useState(false);
    const handleClickBack = ()=>{
            //GA-15
            Event(
                false,
                'click_back_appointment_visit_details_screen',
                AppendLabel(state.session.CoverKey, state.session.LocKey?.toString(), state.session.AcctKey)
            )
        Navigate(-2)
    }

    const setApptConfirmed = useApptConfirmNav();
    const confirmAppointment = () => {
        setLoading(true)

          //GA-12
        Event(
            false,
            'click_confirm_appointment_visit_details_screen',
            AppendLabel(state.session.CoverKey, state.session.LocKey?.toString(), state.session.AcctKey)
        )
    
        if ( multipleSelectedAppointments.length > 0)
        {
            ConfirmationService.cancelAppointment(LogKey, multipleSelectedAppointments)
            .then((response) => {
                if(response.sessionId){
                }
            });
        }

        ConfirmationService.confirmAppointment({
            ..._session,
             TaskKey:  TaskKey,
             AcctKey: AcctKey,
             LocKey: LocKey
        }).then((response: any) => {
            if (Boolean(response.data[0].isStatus) !== true) {
                // alert("Failed appointment confirmation");  
                  //GA-13
            	Event(
                    false,
                    'booking_confirmation_failure',
                    AppendLabel(state.session.CoverKey, state.session.LocKey?.toString(), state.session.AcctKey)
                )
                NavigatePage(dispatch,"next",state,Navigate,PageName.AppointmentNotAvailable)
                //const url =  getProperURL('/AppointmentNotAvailable', state.embed)
                //Navigate(url)              
                //Navigate('/AppointmentNotAvailable')
            } else {
                  //GA-14
            	Event(
                    false,
                    'booking_confirmation_success',
                    AppendLabel(state.session.CoverKey, state.session.LocKey?.toString(), state.session.AcctKey)
                )
                setApptConfirmed(); //This will automatically navigate to the appointment confirmation screen
            }
        }).catch((e) => {
            //setShow('error');
             console.error(e);
            // dispatch(AvailabilityActions.mapAvailabilityData(null));
        });
    }

    useEffect(() => {
    if (state.availability.getInitialLocation)
        setLocationName(state.availability.getInitialLocation.Name) // + "-" + state.availability.getInitialLocation.Address ?? "")
        setPatientName(state.patient.FirstName + " " + state.patient.LastName )
        setPatientEmail(state.patient.Email ?? "")
    }, [state.availability.getInitialLocation]);


    useEffect(() => {
        PageView(
            `pageview_appointment_visit_details_screen_${AppendLabel(state.session.CoverKey, state.session.LocKey?.toString(), state.session.AcctKey)}`
        )
    },[])


  return (
    <div>
        <Box
            display={"flex"}
            alignItems={"center"}
            className="header-partical"
        >
            <Box flexGrow={1}>
                <Typography variant="h6"
                    fontWeight={"500"}
                    sx={{
                        fontSize: "18px",
                        fontWeight: "600",
                    }} >
                    {locationName}
                </Typography>
            </Box>
            <Box sx={{}}>
                <Typography
                    variant="h6"
                    fontWeight={"500"}
                    sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        marginTop: "5px"
                    }}
                >
                    <div>{dDate} - {TimeConvert(dTime)}</div>
                </Typography>
            </Box>
        </Box>
        <Box borderBottom={"0.5px solid black !important"} className="Box-p">
            <div className="Typogrphy-pb top-heading">
                <div><b>{appointmentType}</b>
                <span className="heading-subText"> with <b>{providerFirstname} {ProviderLastName},{providerDegree}</b></span></div>
            </div>
        </Box>
        <div className="header-top">
            <div><label>Patient:</label> <b>{patientName}</b></div>
            <div><label>Email:</label> <b>{patientEmail}</b></div>
        </div>
        <div className='visited-appointment-page'>    
        <div className='box details-box'>
            <div className='header-top-m d-flex header-title'>
                <Box className="icon appointment-icon" sx={{p:6}}><CalendarMonthIcon sx={{ fontSize:50}}/></Box>
                <div className='header-text-mobile'><h2>Your Appointment</h2></div>
                <div className='header-text'>
                    <p className='appointment-details-header'>Appointment Visit Details.</p>
                    <p className='appointment-details-subheader'>Please review all the details of your upcoming scheduled appointment below.</p>
                </div>
            </div>
            <div className='visited-appointment-label'>
                <div className='mainPage inner-box details-container'>
                    <div>
                        <p className='appointmentDetials heading-title'>Time</p>
                        {/* <p>Wednesday, Mar 8th- 1:45pm</p> */}
                        <p className="patient-info">{dayjs(state.availability.availability.AppointmentDateTime).format('dddd, MMMM DD')} - {dayjs(state.availability.availability.AppointmentDateTime).format('h:mm A')}</p>
                       
                    </div>
                    <div>
                        <p className='appointmentDetials heading-title'><b>Location</b></p>
                        <p className="patient-info">{state.availability.getInitialLocation.Address ?? ""}</p>
                    </div>
                    <div>
                        <p className='appointmentDetials'><b>Patient</b></p>
                        <p className="patient-info">{patientName}</p>
                    </div>
                </div>
            </div>  
            </div>   
        </div>
        <Box className="footer-data footer-details" >
            <div className='footer-content'>
                <p>if any of the information above is inaccurate please choose the <b>BACK</b> button.</p>
            </div>
            <div className="button-container">
            <Button className='back-footer' variant="contained"  sx={{borderRadius:10}} onClick={handleClickBack}>
                <span className='button-box'>Back</span>
            </Button>
            <Button style={{backgroundColor : "var(--fpc-blue)"}}  variant="contained" type="submit" sx={{borderRadius:10}} onClick={confirmAppointment}>
                <span className='button-box'>Confirm & Schedule</span>
            </Button>
            </div>
        </Box>
        { loading === true && 
            <>
                <div className="spinner">
                    <Spinner />
                </div>

            </> }
    </div>
  )
}

export default AppointmentVisitDetails