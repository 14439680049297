import PatientInsurance, { PatientInsuranceState } from "./PatientInsurance";
import UserInputField from "./UserInputField";
// import { getDateFromInputDateFormatUTC, getInputDateFormat, dobValidation } from "../../Utils/Util";

export interface PatientInsuranceUserInputField extends UserInputField {
    enabled: number
}

export default interface PatientInsuranceUserInput {
  DisplayVisionInsurance: number,
  DisplayMedicalInsurance: number,
  VisionInsuranceCarrier: PatientInsuranceUserInputField,
  VisionInsuranceOthersName: PatientInsuranceUserInputField,  
  VisionInsuranceCarrierServiceNum: PatientInsuranceUserInputField,
  VisionInsuranceGroupNumber: PatientInsuranceUserInputField,
  VisionInsuranceRelationToPatient: PatientInsuranceUserInputField,
  VisionInsuranceSubscriberDOB: PatientInsuranceUserInputField,
  VisionInsuranceSubscriberID: PatientInsuranceUserInputField,
  VisionInsuranceSubscriberName: PatientInsuranceUserInputField,
  MedicalInsuranceCarrier: PatientInsuranceUserInputField,
  MedicalInsuranceOthersName: PatientInsuranceUserInputField, 
  MedicalInsuranceCarrierServiceNum: PatientInsuranceUserInputField,
  MedicalInsuranceGroupNumber: PatientInsuranceUserInputField,
  MedicalInsuranceRelationToPatient: PatientInsuranceUserInputField,
  MedicalInsuranceSubscriberDOB: PatientInsuranceUserInputField,
  MedicalInsuranceSubscriberID: PatientInsuranceUserInputField,
  MedicalInsuranceSubscriberName: PatientInsuranceUserInputField,
}

export function validatePatientInsuranceUserInput(patientInsurance: PatientInsuranceUserInput): PatientInsuranceUserInput {
//   const visionDobError = dobValidation(patientInsurance.VisionInsuranceSubscriberDOB.value);
//   const medicalDobError = dobValidation(patientInsurance.MedicalInsuranceSubscriberDOB.value);
    const data = {
      ...patientInsurance,
      VisionInsuranceCarrier: {
        ...patientInsurance.VisionInsuranceCarrier,
        error: patientInsurance.VisionInsuranceCarrier.enabled != 1 || patientInsurance.VisionInsuranceCarrier.value.length > 0 
        ? ''
        : 'Insurance Carrier *'
      },
      VisionInsuranceOthersName: {
        ...patientInsurance.VisionInsuranceOthersName,
        error:  patientInsurance.VisionInsuranceOthersName.value.trim().length === 0  && (patientInsurance.VisionInsuranceCarrier.value.toLowerCase() !== "other insurance" || patientInsurance.VisionInsuranceCarrier.value.trim() === "")
        ? ''
        : patientInsurance.VisionInsuranceOthersName.value.trim().length > 0 ? "" :  'Other Insurance Name is required.'
      },      
      VisionInsuranceCarrierServiceNum: {
        ...patientInsurance.VisionInsuranceCarrierServiceNum,
        value: patientInsurance.VisionInsuranceCarrierServiceNum.value.replace(/[^0-9]+/g, ''),
        error: (patientInsurance.VisionInsuranceCarrierServiceNum.value.replace(/[^0-9]+/g, '').trim().length > 0 && patientInsurance.VisionInsuranceCarrierServiceNum.value.replace(/[^0-9]+/g, '').length == 10 ||(patientInsurance.VisionInsuranceCarrierServiceNum.enabled != 1)) 
        ? '' : 'Carrier Service Number *'
      },
      VisionInsuranceGroupNumber: {
        ...patientInsurance.VisionInsuranceGroupNumber,
        error: patientInsurance.VisionInsuranceGroupNumber.enabled != 1 || patientInsurance.VisionInsuranceGroupNumber.value.trim().length > 0 
        ? ''
        : 'Group Number *'
      },
      VisionInsuranceRelationToPatient: {
        ...patientInsurance.VisionInsuranceRelationToPatient,
        error: patientInsurance.VisionInsuranceRelationToPatient.enabled != 1 || patientInsurance.VisionInsuranceRelationToPatient.value.length > 0 
        ? ''
        : 'Patient Relation *'
      },
      VisionInsuranceSubscriberDOB: {
        ...patientInsurance.VisionInsuranceSubscriberDOB,
        error: patientInsurance.VisionInsuranceSubscriberDOB.enabled != 1 
        ? ''
        : ''
      },
      VisionInsuranceSubscriberID: {
        ...patientInsurance.VisionInsuranceSubscriberID,
        error: patientInsurance.VisionInsuranceSubscriberID.enabled != 1 || patientInsurance.VisionInsuranceSubscriberID.value.trim().length > 0 
        ? ''
        : 'Subscriber ID *'
      },
      VisionInsuranceSubscriberName: {
        ...patientInsurance.VisionInsuranceSubscriberName,
        error: patientInsurance.VisionInsuranceSubscriberName.enabled != 1 || patientInsurance.VisionInsuranceSubscriberName.value.trim().length > 0 
        ? ''
        : 'Subscriber Name *'
      },
      MedicalInsuranceCarrier: {
        ...patientInsurance.MedicalInsuranceCarrier,
        error: patientInsurance.MedicalInsuranceCarrier.enabled != 1 || patientInsurance.MedicalInsuranceCarrier.value.length > 0 
        ? ''
        : 'Insurance Carrier *'
      },
      MedicalInsuranceOthersName: {
        ...patientInsurance.MedicalInsuranceOthersName,
        error:  patientInsurance.MedicalInsuranceOthersName.value.trim().length === 0  && (patientInsurance.MedicalInsuranceCarrier.value.toLowerCase() !== "other insurance" || patientInsurance.MedicalInsuranceCarrier.value.trim() === "")
        ? ''
        : patientInsurance.MedicalInsuranceOthersName.value.trim().length > 0 ? "" :  'Other Insurance Name is required.'
      },         
      MedicalInsuranceCarrierServiceNum: {
        ...patientInsurance.MedicalInsuranceCarrierServiceNum,
        value: patientInsurance.MedicalInsuranceCarrierServiceNum.value.replace(/[^0-9]+/g, ''),
        error: (patientInsurance.MedicalInsuranceCarrierServiceNum.value.replace(/[^0-9]+/g, '').trim().length > 0 && patientInsurance.MedicalInsuranceCarrierServiceNum.value.replace(/[^0-9]+/g, '').length == 10 ||(patientInsurance.MedicalInsuranceCarrierServiceNum.enabled != 1)) 
        ? '' : 'Carrier Service Number *'
      },
      MedicalInsuranceGroupNumber: {
        ...patientInsurance.MedicalInsuranceGroupNumber,
        error: patientInsurance.MedicalInsuranceGroupNumber.enabled != 1 || patientInsurance.MedicalInsuranceGroupNumber.value.trim().length > 0 
        ? ''
        : 'Group Number *'
      },
      MedicalInsuranceRelationToPatient: {
        ...patientInsurance.MedicalInsuranceRelationToPatient,
        error: patientInsurance.MedicalInsuranceRelationToPatient.enabled != 1 || patientInsurance.MedicalInsuranceRelationToPatient.value.length > 0 
        ? ''
        : 'Patient Relation *'
      },
      MedicalInsuranceSubscriberDOB: {
        ...patientInsurance.MedicalInsuranceSubscriberDOB,
        error: patientInsurance.MedicalInsuranceSubscriberDOB.enabled != 1 
        ? ''
        : ''
      },
      MedicalInsuranceSubscriberID: {
        ...patientInsurance.MedicalInsuranceSubscriberID,
        error: patientInsurance.MedicalInsuranceSubscriberID.enabled != 1 || patientInsurance.MedicalInsuranceSubscriberID.value.trim().length > 0 
        ? ''
        : 'Subscriber ID *'
      },
      MedicalInsuranceSubscriberName: {
        ...patientInsurance.MedicalInsuranceSubscriberName,
        error: patientInsurance.MedicalInsuranceSubscriberName.enabled != 1 || patientInsurance.MedicalInsuranceSubscriberName.value.trim().length > 0 
        ? ''
        : 'Subscriber Name *'
      },
    }
    return data;
  }

export function emptyPatientInsuranceUserInput(): PatientInsuranceUserInput {
    return {
      DisplayMedicalInsurance: 0,
      DisplayVisionInsurance: 0,
      VisionInsuranceCarrier: { value: '', error: '', enabled: 0 },
      VisionInsuranceOthersName :  { value: '', error: '', enabled: 0 },
      VisionInsuranceCarrierServiceNum: { value: '', error: '', enabled: 0 },
      VisionInsuranceGroupNumber: { value: '', error: '', enabled: 0 },
      VisionInsuranceRelationToPatient: { value: '', error: '', enabled: 0 },
      VisionInsuranceSubscriberDOB: { value: '', error: '', enabled: 0 },
      VisionInsuranceSubscriberID: { value: '', error: '', enabled: 0 },
      VisionInsuranceSubscriberName: { value: '', error: '', enabled: 0 },
      MedicalInsuranceCarrier: { value: '', error: '', enabled: 0 },
      MedicalInsuranceOthersName : { value: '', error: '', enabled: 0 },
      MedicalInsuranceCarrierServiceNum: { value: '', error: '', enabled: 0 },
      MedicalInsuranceGroupNumber: { value: '', error: '', enabled: 0 },
      MedicalInsuranceRelationToPatient: { value: '', error: '', enabled: 0 },
      MedicalInsuranceSubscriberDOB: { value: '', error: '', enabled: 0 },
      MedicalInsuranceSubscriberID: { value: '', error: '', enabled: 0 },
      MedicalInsuranceSubscriberName: { value: '', error: '', enabled: 0 },
    }
}

export function mapPatientInsuranceToUserInput(patientInsurance: PatientInsurance): PatientInsuranceUserInput {
    return {
        DisplayVisionInsurance: patientInsurance.Config.v4DVisInsSect ?? 0,
        DisplayMedicalInsurance: patientInsurance.Config.v4DMedInsSect ?? 0,
        VisionInsuranceCarrier: { value: patientInsurance.VisionInsuranceCarrier ?? '', error: '', enabled: patientInsurance.Config.DInsCarrier ?? 0 },
        VisionInsuranceOthersName: { value: patientInsurance.VisionInsuranceOthersName ?? '', error: '', enabled: patientInsurance.Config.DInsCarrier ?? 0 },
        VisionInsuranceCarrierServiceNum: { value: patientInsurance.VisionInsuranceCarrierServiceNum ?? '', error: '', enabled: patientInsurance.Config.DCustSrvNum ?? 0 },
        VisionInsuranceGroupNumber: { value: patientInsurance.VisionInsuranceGroupNumber ?? '', error: '', enabled: patientInsurance.Config.DGroupNumber ?? 0 },
        VisionInsuranceRelationToPatient: { value: patientInsurance.VisionInsuranceRelationToPatient ?? '', error: '', enabled: patientInsurance.Config.DRelToPat ?? 0 },
        VisionInsuranceSubscriberDOB: { value: patientInsurance.VisionInsuranceSubscriberDOB ?? '', error: '', enabled: patientInsurance.Config.DSubDOB ?? 0 },
        VisionInsuranceSubscriberID: { value: patientInsurance.VisionInsuranceSubscriberID ?? '', error: '', enabled: patientInsurance.Config.DSubId ?? 0 },
        VisionInsuranceSubscriberName: { value: patientInsurance.VisionInsuranceSubscriberName ?? '', error: '', enabled: patientInsurance.Config.DSubName ?? 0 },
        MedicalInsuranceCarrier: { value: patientInsurance.MedicalInsuranceCarrier ?? '', error: '', enabled: patientInsurance.Config.v4DMedInsCarrier ?? 0 },
        MedicalInsuranceOthersName: { value: patientInsurance.MedicalInsuranceOthersName ?? '', error: '', enabled: patientInsurance.Config.v4DMedInsCarrier ?? 0 },
        MedicalInsuranceCarrierServiceNum: { value: patientInsurance.MedicalInsuranceCarrierServiceNum ?? '', error: '', enabled: patientInsurance.Config.v4DMedInsCarrierSvcNum ?? 0 },
        MedicalInsuranceGroupNumber: { value: patientInsurance.MedicalInsuranceGroupNumber ?? '', error: '', enabled: patientInsurance.Config.v4DMedInsGroupNum ?? 0 },
        MedicalInsuranceRelationToPatient: { value: patientInsurance.MedicalInsuranceRelationToPatient ?? '', error: '', enabled: patientInsurance.Config.v4DMedInsRel2Pat ?? 0 },
        MedicalInsuranceSubscriberDOB: { value: patientInsurance.MedicalInsuranceSubscriberDOB ?? '', error: '', enabled: patientInsurance.Config.v4DMedInsSubDOB ?? 0 },
        MedicalInsuranceSubscriberID: { value: patientInsurance.MedicalInsuranceSubscriberID ?? '', error: '', enabled: patientInsurance.Config.v4DMedInsSubId ?? 0 },
        MedicalInsuranceSubscriberName: { value: patientInsurance.MedicalInsuranceSubscriberName ?? '', error: '', enabled: patientInsurance.Config.v4DMedInsSubName ?? 0 },
    };
}

export function mapUserInputToPatientInsurance(input: PatientInsuranceUserInput, patientInsurance: PatientInsuranceState, isValid: boolean): PatientInsuranceState {
  const data = {
      ...patientInsurance,
      VisionInsuranceCarrier: input.VisionInsuranceCarrier.value,
      VisionInsuranceOthersName : input.VisionInsuranceOthersName.value,
      VisionInsuranceCarrierServiceNum: input.VisionInsuranceCarrierServiceNum.value,
      VisionInsuranceGroupNumber: input.VisionInsuranceGroupNumber.value,
      VisionInsuranceRelationToPatient: input.VisionInsuranceRelationToPatient.value,
      VisionInsuranceSubscriberDOB: input.VisionInsuranceSubscriberDOB.value,
      VisionInsuranceSubscriberID: input.VisionInsuranceSubscriberID.value,
      VisionInsuranceSubscriberName: input.VisionInsuranceSubscriberName.value,
      MedicalInsuranceCarrier: input.MedicalInsuranceCarrier.value,
      MedicalInsuranceOthersName : input.MedicalInsuranceOthersName.value,
      MedicalInsuranceCarrierServiceNum: input.MedicalInsuranceCarrierServiceNum.value,
      MedicalInsuranceGroupNumber: input.MedicalInsuranceGroupNumber.value,
      MedicalInsuranceRelationToPatient: input.MedicalInsuranceRelationToPatient.value,
      MedicalInsuranceSubscriberDOB: input.MedicalInsuranceSubscriberDOB.value,
      MedicalInsuranceSubscriberID: input.MedicalInsuranceSubscriberID.value,
      MedicalInsuranceSubscriberName: input.MedicalInsuranceSubscriberName.value,
      IsValid: isValid
  };
  return data;
}