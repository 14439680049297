import HashReturnObject from '../models/HashReturnObject';
import { getBaseAPI } from "../environments/Environments";
import { reject } from "q";
import { mapSessionData, SessionModel } from '../models/Session';
import AppointmentStatus from '../models/AppointmentStatus';
import AppointmentDetailsData from '../models/AppointmentDetails';
import { getDateTimeObj } from '../utils/Util';
import { useAppDispatch, useAppSelector } from "../app/hooks";


export default class ConfirmationService {
    static confirmAppointment = (session: SessionModel): Promise<HashReturnObject<AppointmentDetailsData>> => {
        //const state = useAppSelector(state => state)
        return new Promise( async (resolve) => {
            const url = await getBaseAPI() + '/api/v1/Patient/saveAppointment';
            // const appointmentDetailsData = (val: unknown) => mapSessionData(session);
            // appointmentDetailsData.apptDateTime =  getDateTimeObj(session.ApptDateTime).dateTime;
            const payload = JSON.stringify(mapSessionData(session));
            fetch(url, {
                method: 'POST',
                body: payload,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => res.json())
                .then((result) => Promise.resolve(result))
                .then((hashReturnObject: any) => {
                    const reservations: AppointmentDetailsData[] = [];
                    reservations.push(hashReturnObject.data);

                    const responseObject: HashReturnObject<AppointmentDetailsData> = {
                        data: reservations,
                        hash: hashReturnObject.hash,
                        sessionId: hashReturnObject.sessionId
                    }
                    resolve(responseObject);
                })
                .catch((e) => {
                    console.log('request error', e);
                    reject(e);
                });
        });
    }
    static reserveAppointment = (session: SessionModel): Promise<HashReturnObject<AppointmentStatus>> => {
        return new Promise( async (resolve) => {
            const url = await getBaseAPI() + '/api/v1/Patient/updateAppointmentTime';
            const appointmentDetailsData = mapSessionData(session);
            //console.log('request time: ' + session.ApptDateTime);           
            //appointmentDetailsData.apptDateTime =  getDateTimeObj(session.ApptDateTime).dateTime;
            const apptTime =  getDateTimeObj(session.ApptDateTime).dateTime;
            //const payload = JSON.stringify(appointmentDetailsData);
            fetch(url, {
                method: 'POST',
                body: JSON.stringify({
                    "apptDateTime": apptTime,
                    "logKey": session.LogKey,
                    "groupKey": session.GroupKey,
                    "coverKey" : session.CoverKey,
                    "locKey": session.LocKey,
                    "acctKey": session.AcctKey,
                    "addKey": session.AddKey,
                    "taskKey": session.TaskKey,
                    "apptKey": session.ApptKey,
                    "currentPage": 0
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => res.json())
                .then((result) => Promise.resolve(result))
                .then((hashReturnObject: any) => {
                    const reservations: AppointmentStatus[] = [];
                    reservations.push(hashReturnObject.data);

                    const responseObject: HashReturnObject<AppointmentStatus> = {
                        data: reservations,
                        hash: hashReturnObject.hash,
                        sessionId: hashReturnObject.sessionId
                    }
                    resolve(responseObject);
                })
                .catch((e) => {
                    console.log('request error', e);
                    reject(e);
                });
        });
    }
    static cancelAppointment = (logKey: string | null | undefined, apptKey: any): Promise<HashReturnObject<AppointmentStatus>> => {
        console.log('data', logKey)
        console.log('data', apptKey)
        // var arrayAppt = apptKey;
        //     var index = arrayAppt.indexOf(''); 
        //     delete arrayAppt[index];
        return new Promise( async (resolve) => {
            const url = await getBaseAPI() + '/api/v1/Patient/preCancelAppointment';
            fetch(url, {
                method: 'POST',
                body: JSON.stringify({
                    "logKey": logKey,
                    "apptKeysToBeCancelled": apptKey
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => res.json())
                .then((result) => Promise.resolve(result))
                .then((hashReturnObject: any) => {
                    console.log('hashReturnObject', hashReturnObject)
                    const reservations: AppointmentStatus[] = [];
                    reservations.push(hashReturnObject.data);

                    const responseObject: HashReturnObject<AppointmentStatus> = {
                        data: reservations,
                        hash: hashReturnObject.hash,
                        sessionId: hashReturnObject.sessionId
                    }
                    resolve(responseObject);
                })
                .catch((e) => {
                    console.log('request error', e);
                    reject(e);
                });
        });
    }
}