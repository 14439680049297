import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Providers from "../../../models/Provider";

interface NewProviderProps {
    data: Providers | any
}

const NewProvider = (
    { data }: NewProviderProps
) => {
    return <Box
        sx={{
            display: "flex",
            alignItems: "flex-start",
        }}
    >
        <Avatar
            alt={'pimg'}
            src={data?.Photo}
            sx={{ width: { xl: 145, xs: 101 }, height: { xl: 145, xs: 101 } }}
        />
        <Box sx={{ ml: 1.75, alignSelf: "center" }}>
            <Typography variant="h6" fontWeight={"700"} sx={{ fontSize: "16px", lineHeight: "21px" }}>
                {data.Title} {data.FirstName} {data.LastName}
            </Typography>
            <Typography variant="body1" fontWeight={"500"} sx={{ lineHeight: "21px" }}>
                {data.Speciality}
            </Typography>
        </Box>
    </Box>
}
export default NewProvider