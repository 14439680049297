import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import MulipleAppointments, { MultipleAppointmentsState} from "../../models/MultipleAppointments";
import MulipleAppointmentSelected,  { MultipleAppointmentSelectedState} from "../../models/MultipleAppointmentSelected";
import MultipleAppointments from "../../models/MultipleAppointments";
import MultipleAppointmentDetails from "../../models/MultipleAppointments";

const initialState: MultipleAppointmentsState = {
    multipleAppointmentDetails: []
}

export const multipleAppointmentsSlice = createSlice({
    name:'multipleAppointments',
    initialState,
    reducers: {
        updateMultipleAppointments: (state,action)=> {
            try{
                if (action.payload) {
                    state.multipleAppointmentDetails = action.payload
                }
            }
            catch(e){
                console.log(e)
            }
       
        },
        getMultipleAppointments : (
            state,
            action: PayloadAction<MultipleAppointmentDetails[]>
        )=> {
            if (action.payload) {
                state.multipleAppointmentDetails = action.payload
            }
        }
    }
});

export const {updateMultipleAppointments, getMultipleAppointments} = multipleAppointmentsSlice.actions
export default multipleAppointmentsSlice.reducer