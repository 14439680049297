import HashReturnObject from '../models/HashReturnObject';
import { getBaseAPI } from "../environments/Environments";
import { reject } from "q";
import { StateModel } from '../models/StateModel';
import { mapSessionData, SessionModel } from '../models/Session';
import AppointmentDetailsData from '../models/AppointmentDetails';
import Session from '../components/Session';
import MultipleAppointmentDetails, { MultipleAppointmentDetailsData , mapMultipleAppointmentDetailsData} from '../models/MultipleAppointments';


export default class ExistingAppointmentService {
    static existingAppointment = (session: SessionModel): Promise<HashReturnObject<MultipleAppointmentDetails>> => {
        return new Promise( async (resolve) => {
            // console.log(session);
                const url = await getBaseAPI() + '/api/v1/Patient/retrieveScheduledAppointments';
                const appointmentDetailsData = mapSessionData(session);
                const payload = JSON.stringify(appointmentDetailsData);
                fetch(url, {
                    method: 'POST',
                    body: payload,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then((res) => res.json())
                .then((result) => Promise.resolve(result))
                .then((hashReturnObject: HashReturnObject<MultipleAppointmentDetailsData>) => {
                    const multipleAppointment: MultipleAppointmentDetails[] = [];
                    hashReturnObject.data.forEach((data: MultipleAppointmentDetailsData) => {
                        multipleAppointment.push(mapMultipleAppointmentDetailsData(data));
                    });
        
        
                    //for testing **********
                    //if (locations.length > 1) locations = [locations[0]];
                    //for testing **********
                    
                    const responseObject: HashReturnObject<MultipleAppointmentDetails> = {
                        data: multipleAppointment,
                        hash: hashReturnObject.hash,
                        sessionId: hashReturnObject.sessionId
                    }
                    //console.log('MultipleAptt Api::', responseObject)
                    resolve(responseObject);
                })
                .catch((e) => {
                    console.error('request error', e);
                    reject(e);
                });
        });
    }
}