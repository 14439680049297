import { Select, MenuItem, SelectChangeEvent } from "@mui/material";
import "./selectStyle.css";
export interface INormalSelectProps<T> {
  data: ReadonlyArray<T>;
  getOptionLabel: (option: T) => string;
  renderOptions?: (option: T) => React.ReactNode;
  onSelection?: (data: T) => void;
  validateOptionSelected: (option: T, value: T) => boolean;
  selected?: T | null;
  size?: "small" | "medium";
  width?: string | number;
  placeholder?: string;
}

const ColorSelect = <T extends {}>({
  data,
  getOptionLabel,
  renderOptions,
  onSelection,
  validateOptionSelected,
  selected,
  size,
  width,
  placeholder,
}: INormalSelectProps<T>) => {
  const handleSelection = (item: T | any) => {
    onSelection && onSelection(item);
  };
  const handleIndexSelection = (evt: SelectChangeEvent) => {
    const index = Number.parseInt(evt.target.value);
    const newValueUndef = (Number.isNaN(index) || index < 0) ? undefined : data[index];
    const newValue = newValueUndef === undefined ? null : newValueUndef;
    handleSelection(newValue);
  }

  if (JSON.stringify(selected) === "{}" ) {
    selected = null
  }

  const renderSelectValue = (value: string): string => {
    const item: T | undefined = data[Number.parseInt(value)];
    const rendered = item === undefined ? (placeholder ?? "") : getOptionLabel(item);
    return rendered;
  }
  const renderSelectOption = (value: T): React.ReactNode => {
    return (renderOptions ? renderOptions(value) : getOptionLabel(value));
  }

  const getSelectedIndex = (): number => {
    let selectedIndex: number = -1;
    if(selected !== null && selected !== undefined) {
      const sel = selected;
      selectedIndex = data.findIndex(item => validateOptionSelected(item, sel));
    }
    return selectedIndex;
  }
  const selectedIndex = getSelectedIndex().toString();
  return (
    <Select
      value={selectedIndex}
      onChange={handleIndexSelection}
      size={size}
      fullWidth={true}
      displayEmpty={true}
      renderValue={renderSelectValue}
      MenuProps={{
        PaperProps: {
          sx: {
            borderRadius: "0px 0px 25px 25px",
            boxShadow: "0 0 12px 0 rgb(0 0 0 / 25%)",
            backgroundColor: "var(--fpc-blue)",
            color: "white",
            transitionDuration: "0s !important"
          }
        }
      }}
      sx={{
        backgroundColor: "var(--fpc-blue)",
        color: selected === null ? "rgba(255, 255, 255, 0.4)" : "white",
        maxWidth: { width },
        "&.MuiInputBase-root ": {
          borderRadius: "25px",
          boxShadow: "0 0 12px 0 rgba(0, 0, 0, 0.25)"
        },
        "&.MuiInputBase-root:has(div[aria-expanded='true'])": {
          borderBottomRightRadius: "0px",
          borderBottomLeftRadius: "0px",
          borderBottomColor: "transparent !important",
          boxShadow: "0 0 5px 0 rgb(0 0 0 / 25%)",
          borderCollapse: "true"
        },
        "& fieldset": {
          borderColor: "transparent !important",
        }
      }}
      >
      {data.map((item, index) => 
        <MenuItem key={index} value={index}>{renderSelectOption(item)}</MenuItem>
      )}
    </Select>
  );
};

export default ColorSelect;
