import axios from 'axios'
export const Environments =
 {
  version: 0.02,
  production: false,
  useAPI: false,
  apiLocal: 'https://qa-booking-api.4patientcare.app',
  apiProd: 'https://webscheduler-crm.ditacapps.net',
  apiQA: 'https://qa-booking-api.4patientcare.app',
  apiStage: 'https://qa-booking-api.4patientcare.app',
  apiDev: 'https://qa-booking-api.4patientcare.app',
  apiConfigLocal: 'https://qa-wsc-api.4patientcare.app',
  apiConfigProd: 'https://wsc-api.4patientcare.app',
  apiConfigQA: 'https://qa-wsc-api.4patientcare.app',
  apiConfigStage: 'https://qa-wsc-api.4patientcare.app',
  apiConfigDev: 'https://qa-wsc-api.4patientcare.app'
  };
export const getBaseAPI = async (): Promise<string> => {
  let baseConfig = await getBookingApi()
  console.log(baseConfig)
    if (baseConfig == "") {
    let api = Environments.apiLocal;
    const path = window.location.host;
    switch (true) {
      case path.includes('local'):
        api = Environments.apiLocal;
        break;
      case path.includes('qa'):
      case path.includes('mango-ocean'):
        api = Environments.apiQA;
        break;
      case path.includes('stage'):
        api = Environments.apiStage;
        break;
      case path.includes('dev'):
      case path.includes('brave-flower'):
        api = Environments.apiDev;
        break;
      default:
        Environments.production = true;
        api = Environments.apiProd;
    }
    return api;
  } else {
    return baseConfig
  }
};
export const getConfigBaseAPI = async (): Promise<string> => {
  let baseConfig = await getConfigApi();
  console.log(baseConfig)
  if (baseConfig == "") {
    let api = Environments.apiConfigLocal;
    const path = window.location.host;
    switch (true) {
      case path.includes('local'):
        api = Environments.apiConfigLocal;
        break;
      case path.includes('qa'):
        case path.includes('mango-ocean'):
        api = Environments.apiConfigQA;
        break;
      case path.includes('stage'):
        api = Environments.apiConfigStage;
        break;
      case path.includes('dev'):
        case path.includes('brave-flower'):
        api = Environments.apiConfigDev;
        break;
      default:
        Environments.production = true;
        api = Environments.apiConfigProd;
    }
    return api;
  } else {
    return baseConfig;
  }
  
};

export const getBookingApi = async (): Promise<string> => {
  let response = "";
  //let configEndpoint = "http://localhost:7071/api/DynamicSettings?settings=REACT_APP_BOOKING_API"
  let configEndpoint = "/api/DynamicSettings?settings=REACT_APP_BOOKING_API";  
  const path = window.location.host;
  if (path.includes("localhost")) { return response; }
  await axios.post(configEndpoint)
    .then(res => {
      if (res.data.success) {
        response = res.data.message
        console.log("URL from config: " + res.data.message)
      }
    }).catch((ex) => {
      response = ""
    })
  return response;
}

export const getConfigApi = async (): Promise<string> => {
  let response = "";
  let configEndpoint = "/api/DynamicSettings?settings=REACT_APP_CONFIG_API";
  const path = window.location.host;
  if (path.includes("localhost")) { return response; }
  await axios.post(configEndpoint)
    .then(res => {
      if (res.data.success) {
        response = res.data.message
      }
    }).catch((ex) => {
      response = ""
    })
  return response;
}