import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import MulipleAppointmentSelected,  { MultipleAppointmentSelectedState} from "../../models/MultipleAppointmentSelected";
import MultipleAppointmentDetails from "../../models/MultipleAppointments";

const initialState: MultipleAppointmentSelectedState = {
    multipleAppointmentSelected: []
}

export const multipleAppointmentsSlice = createSlice({
    name:'multipleAppointmentSelected',
    initialState,
    reducers: {   
        updateMultipleAppointmentSelected: (state,action)=> {
            try{
                if (action.payload) {
                    state.multipleAppointmentSelected = action.payload
                }
            }
            catch(e){
                console.log(e)
            }
       
        },
    }
});

export const {updateMultipleAppointmentSelected} = multipleAppointmentsSlice.actions
export default multipleAppointmentsSlice.reducer