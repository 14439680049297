import Patient, { mapPatientDataForRetrieve, mapPatientDataForSave, mapPatientDetailsData, PatientDetailsData } from '../models/Patient';
import HashReturnObject from '../models/HashReturnObject';
import { getBaseAPI } from '../environments/Environments';
import { reject } from "q";
import { SessionModel } from '../models/Session';

const PatientService = {
    getPatient: (patient: Patient, session: SessionModel): Promise<HashReturnObject<Patient>> => {
        return new Promise( async (resolve, reject) => {
            const url = await getBaseAPI() + '/api/v1/Patient/getPatient_Info';
            // const url = 'https://localhost:5001/api/v1/Patient/getPatient_Info';
            const patientData = mapPatientDataForRetrieve(patient, session);
            const payload = JSON.stringify(patientData)
            fetch(url, {
                method: 'POST',
                body: payload,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => res.json())
                .then((result) => Promise.resolve(result))
                .then((hashReturnObject: any) => {
                    const patients: Patient[] = [];
                    hashReturnObject.data.forEach((data: any) => {
                        patients.push(mapPatientDetailsData(data));
                    });
                    const responseObject: HashReturnObject<Patient> = {
                        data: patients,
                        hash: hashReturnObject.hash,
                        sessionId: hashReturnObject.sessionId
                    }
                    resolve(responseObject);
                })
                .catch((e) => {
                    console.log('request error', e);
                    reject(e);
                });
        });
    },
    savePatient: (patient: Patient, session: SessionModel): Promise<HashReturnObject<Patient>> => {
        return new Promise( async (resolve) => {
            const url = await getBaseAPI() + '/api/v1/Patient/savePatient_Info';
            const patientData = mapPatientDataForSave(patient, session);
            const payload = JSON.stringify(patientData);
            fetch(url, {
                method: 'POST',
                body: payload,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => res.json())
                .then((result) => Promise.resolve(result))
                .then((hashReturnObject: any) => {
                    const patients: Patient[] = [];
                    patients.push(mapPatientDetailsData(hashReturnObject.data));
                    
                    const responseObject: HashReturnObject<Patient> = {
                        data: patients,
                        hash: hashReturnObject.hash,
                        sessionId: hashReturnObject.sessionId
                    }
                    resolve(responseObject);
                })
                .catch((e) => {
                    console.log('request error', e);
                    reject(e);
                });
        });
    }
}
export const persistPatient = (session: SessionModel, currentPatient:  any): Promise<HashReturnObject<Patient>> => {
    return new Promise((resolve) => {
        PatientService.getPatient(currentPatient, session).then((hashReturnObject: HashReturnObject<Patient>) => {
            // const sessionPatient = (hashReturnObject.data[0] === undefined || hashReturnObject.data[0] === null)
            //     ? PatientService.savePatient(currentPatient, session)
            //     : Promise.resolve(hashReturnObject);
            resolve(Promise.resolve(hashReturnObject));
        });
    });
}
export default PatientService;