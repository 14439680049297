import { CustomQuestionsModel } from '../models/CustomQuestion'
import { getBaseAPI } from "../environments/Environments";
import { reject } from "q";
import { mapSessionData, SessionModel } from '../models/Session';
import { SaveCustomQuestion } from '../models/SaveCustomQuestion';


const stateValue ={
    AcctKey: "22016",
    AddKey: "110025200",
    ApptDateTime:null,
    ApptKey: null,
    ClientIP: "58.69.91.74",
    CoverKey: "2724",
    CreatedOn: "Wed Jun 07 2023 13:56:23 GMT+0800 (Singapore Standard Time)",   
    CurrentPage: 0,
    GroupKey: null,
    LocKey: "4672",
    LogKey: "22952746",
    SkipCustomQuestions: false,
    SkipDemographics: false,
    SkipPatientInsurance: false,
    StandAlone: undefined,
    TaskKey: 128328,
    TrackingID: undefined,
    V4Scenario: null
}
export default class CustomQuestionsService {

  static retrieveCustomQuestions = (session: SessionModel): Promise<CustomQuestionsModel[]> => {
    return new Promise(async (resolve) => {
        const url = await getBaseAPI() + '/api/v1/Patient/getCustomQuestions';
        fetch(url, {
          method: 'POST',
          body: JSON.stringify(mapSessionData(session)),
          headers: {
            'Content-Type': 'application/json'
        }})
          .then((res) => res.json())
          .then((result) => Promise.resolve(result))
          .then((customQuestionsResponse) => {
            const customQuestions: CustomQuestionsModel[] = [];
                customQuestionsResponse.forEach((data: CustomQuestionsModel) => {
                    customQuestions.push(data);
                });
            resolve(customQuestions);
          })
          .catch((e) => {
            console.log('request error', e);
            reject(e);
          });
    });
  };

  static saveCustomQuestion = (customQuestions: SaveCustomQuestion): Promise<SaveCustomQuestion> => {
    return new Promise(async (resolve) => {
         const url = await getBaseAPI() + '/api/v1/Patient/saveCustomQuestionResponse';
        fetch(url, {
          method: 'POST',
          body: JSON.stringify(customQuestions),
          headers: {
            'Content-Type': 'application/json'
        }})
          .then((res) => res.json())
          .then((result) => Promise.resolve(result))
          .then((customQuestionsResponse) => {
            resolve(customQuestionsResponse);
          })
          .catch((e) => {
            console.log('request error', e);
            reject(e);
          });
    });
  };
}