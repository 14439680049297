import React, { useEffect, useRef, useState, useCallback, memo } from 'react'
import { GoogleMap, useJsApiLoader, MarkerF, CircleF, InfoWindowF } from '@react-google-maps/api';
import LocationDetails from '../../models/LocationDetails';
import { useAppSelector } from '../../app/hooks';
import "./SimpleMapStyle.css";

const containerStyle = {
  minWidth: '300px',
  minHeight: '300px',
  maxWidth: '100%', 
  maxHeight: '100%',
};
interface AnyReactComponentProps {
  lat: number;
  lng: number;
  text: string
}
interface SimpleMapProps {
  locationCords: LocationDetails | null
  totalLocations: LocationDetails[]
  onLocationSelect: (data: LocationDetails) => void;
}

const center = {
  lat: 44.5675940,
  lng: -91.6708043
};

function SimpleMap({ locationCords, totalLocations, onLocationSelect }: SimpleMapProps) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDF9ki6ngHupJZsclUBb2aqlYN4DVG74_Q"
  })
  // const coords = locationCords?.Lat && {
  //   lat: parseFloat(locationCords?.Lat),
  //   lng: parseFloat(locationCords?.Long),
  // }
  const [circleVal, setCircleVal] = useState(center)
  const [centerCoords, setCenterCoords] = useState({ lat: 0, lng: 0 })
  const [map, setMap] = React.useState<any>(null)
  const [selected, setSelected] = useState<LocationDetails | null | any>(null)
  const [userZoom, setUserZoom] = useState(8)
  
  const mockLocations = [
    { lat: 30.4704408, lng: -97.7473572 },
    { lat: 44.5675940, lng: -91.6708043 },
    { lat: 30.4704408, lng: -92.0795511 },
    { lat: 37.4951422, lng: -95.8110000 },
    { lat: 42.5675940, lng: -10.8110000 },
    { lat: 43.5675940, lng: -107.8110000 },
    { lat: 44.5985940, lng: -47.8110000 },
    { lat: 45.5675940, lng: -103.8110000 },
    { lat: 46.5675940, lng: -101.8110000 },
    { lat: 54.5675940, lng: -104.8110000 },
    { lat: 64.5675940, lng: -87.8110000 },
    { lat: 28.679079, lng: 77.069710 },
    // { lat: 48.8566, lng: 2.3522 },
    // { lat: 32.7767, lng: 96.7970 },
    // { lat: 21.3099, lng: 157.8581 },
    // { lat: 21.3099, lng: 2.2137 },
    // { lat : , lng: }
  ]

  const onLoad = useCallback((map: any) => {
    setMap(map)
  }, [])

  const onUnmount = useCallback((map: any) => {
    setMap(null)
  }, [])

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      const val = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }
      setCircleVal(val)
      setCenterCoords(val)
      setUserZoom(12)
    })
  }
  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      totalLocations.forEach((location) => {
        bounds.extend({ lat: parseFloat(location.Lat), lng: parseFloat(location.Long) });
      })

      // mock data locations
      // mockLocations.forEach((location) => {
      //   bounds.extend({ lat: location.lat, lng: location.lng });
      // })

      map?.fitBounds(bounds);
      setMap(map)
    }
  }, [map, totalLocations]);

  useEffect(() => {
    getCurrentLocation()

  }, [])

  return isLoaded ? (
    <GoogleMap
      id='google-map-script'
      mapContainerStyle={containerStyle}
      center={centerCoords}
      zoom={userZoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <CircleF
        center={circleVal}
        radius={500}
        options={{
          fillColor: '#4285F4',
          fillOpacity: 0.7,
          strokeColor: '#4285F4',
          strokeWeight: 30,
        }}
      />
      {
        totalLocations.map((location, idx) => {
          const coord = {
            lat: parseFloat(location.Lat),
            lng: parseFloat(location.Long)
          }
          return<div > <MarkerF
            key={`${location.Lat + location.Long}`}
            position={coord}
            onMouseOver={() => setSelected(location)}
          />
          </div>
        })
      }
      {/* mock data locations */}
      {/* {
        mockLocations.map((coord, idx)=> {
          return <MarkerF 
            key={idx}
            position={coord}
            // onMouseOver={()=> setSelected(coord)}
          />
        })
      } */}
      {
         selected && <InfoWindowF

          position={{ lat: parseFloat(selected.Lat), lng: parseFloat(selected.Long) }}
          onCloseClick={() => setSelected(null)}
        >
          {/* <div>{selected.Name} </div> */}
          <div  onClick={() => onLocationSelect(selected)}>{(selected.Name)}</div>
        </InfoWindowF>
      }
      <button
        onClick={getCurrentLocation}
        style={{
          display: 'inline-block',
          position: 'absolute',
          right: '10px',
          bottom: '60%',
          fontSize: '33px',
          cursor: 'pointer',
          backgroundColor: '#fff',
          border: 'none',
        }}> &#x2609;
      </button>
      </GoogleMap>
  ) : <>Google Maps</>
}

export default SimpleMap
