import React, { useState, useEffect } from "react";
import { Container, Divider  } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import AppointmentSelectionTile from "../../appointment/AppointmentSelectionTile";
import { useAppSelector } from "../../../app/hooks";
import Providers from "../../../models/Provider";
import AppointmentType from "../../../models/AppointmentType";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import AccountBoxSharpIcon from "@mui/icons-material/AccountBoxSharp";
import NewAppointmentSelectionTile from "../../appointment/NewAppointmentSelectionTile";
import NewProvider from "./NewProvider";
import "./NewProviderWithAppointmentStyle.css";
import CustomMessagesService from "../../../services/CustomMessagesService";
import { useTheme } from "@mui/material/styles";

interface IProviderWithAppointmentProps {
    data: Providers;
    onAppointmentClick: (providerId: string, date: number) => void;
    totalProviderCount: number;
    currentProviderIndex: number;
    appointmentType: AppointmentType | null;
    dates: any[]
    selectLength: number;
}

const NewProviderWithAppointment = ({
    data,
    dates,
    onAppointmentClick,
    totalProviderCount,
    currentProviderIndex,
    appointmentType,
    selectLength,
}: IProviderWithAppointmentProps) => {
    const availabilities = useAppSelector(st => st.availability.availabilities)
    const onClickNext = () => { };
    const onClickPrev = () => { };
    const [viewAll, setViewAll] = useState(false)
    const [customMessage, setCustomMessage] = useState("");
    const state = useAppSelector(st => st)
    const theme = useTheme();
    const [longMessage, setLongMessage] = useState(false);

	useEffect(()  => {

        var test = state
		const getCustomMessages = async () => {
			let customMessage = "";
			if (state.provider && state.provider !== null) {
				const response = await CustomMessagesService.retrieveCustomMessages({
					...state.session,
					AcctKey: data.AcctKey,
				});

				customMessage = response.messages
 					.filter((x) => x.v4UnderPic?.trim())
					.map((x) => x.v4UnderPic?.trim())
					.join("<br />");
 
        setCustomMessage(customMessage);
				if (customMessage.length > 150) 
                {
                  setLongMessage(true);
                }
			}
			else
            setCustomMessage(customMessage);
		};  
        getCustomMessages();    
      }, []);    

    var pageNum = selectLength > 0 ? selectLength / 20 : 0
 /*    var d = new Date()

    d.setDate(d.getDate() + (pageNum * 20))
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0); 
    var day = d.getDay()
    // Get the first day of the week with the current date
    var diff = d.getDate() - day + (day == 0 ? -6:0);  */
    let sdate =  new Date(dates[pageNum]);
    const strtDate = sdate.getTime()
    // Display only first 20 days of the calendar
    let newArr : number[] = []
    for (let i = 0; i < 21; i++) {     
       newArr.push(sdate.getTime())
       sdate.setDate(sdate.getDate() + 1); 
    }

   // const strtDate = availabilities?.dates?.FIRST_AVAILABLE[selectLength]
    //const endDate = availabilities?.dates?.FIRST_AVAILABLE[selectLength+20] ? availabilities?.dates?.FIRST_AVAILABLE[selectLength+20] : availabilities?.dates?.FIRST_AVAILABLE[availabilities?.dates?.FIRST_AVAILABLE.length -1]
    //const newArr =viewAll ? dates : dates.filter(dt => strtDate <= dt && endDate >= dt)
     return (
        <Grid item xs={12}>        
            <Grid container>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ marginBottom: "18px" }}>
                    {/* Provider details */}
                    <NewProvider data={data} />
                    {customMessage !== "" && <div style={{paddingRight : "10px" , paddingTop : "10px"}}>
                    <Divider
                        sx={{
                        opacity: 1,
                        borderColor: theme.palette.primary.light,
                        }}
                    />                    
                    <div style={{paddingLeft : "20px", marginTop : "10px", height : longMessage ? "100px" : "", overflow : longMessage ? "hidden" : "visible",
                                 }} dangerouslySetInnerHTML={{__html:  customMessage}}/>

                     {customMessage.length > 150 && <div style={{paddingTop : "20px" , cursor : "pointer"}}>  <button style={{ cursor : "pointer"}}  onClick={() => setLongMessage(!longMessage)} id="textButton">
                                                        {longMessage ? "Show more" : "Show less"}
                                                    </button>
                                                    </div>
                     
                     
                    //  <div style={{cursor : "pointer"}} onClick={() => setLongMessage(!longMessage)}>{longMessage ? "Show more" : "Show less"}</div> 
                     }            
                    </div>}
                </Grid>  	
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    {
                        newArr.length ? <Grid container justifyContent={"space-between"}>
                        {
                            newArr.map((date, index) => {
                                return <Grid className="appointment-list" item key={`appointment_tile_:${index}_id_${date}`} width={"14%"}  sx={{ mr: { lg: 0, xl: 0, sm: 0, xs:0.13 } }}>
                                    <Box className="appointment-box" sx={{ mr: { lg: 0.25, xl: 2 }, mb: { xs: 1, sm: 1, md: 1, lg: 0.25, xl: 2 } }}>
                                        <NewAppointmentSelectionTile
                                            appointmentDate={date}
                                            disabled={false}
                                            onAppointmentClick={onAppointmentClick}
                                            providerId={data.AcctKey}
                                            data={data}
                                        />
                                    </Box>
                                </Grid>
                            })
                        }
                    </Grid> : 
                     <Grid item textAlign={'center'}>
                        <Box marginTop='10%'>
                        <Typography flexGrow={3} fontSize={"22px"} lineHeight={"26px"}>No Appointments Available in this week.</Typography>
                        </Box>
                        </Grid>
                    }
                    
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            mt: 4,
                            mb: 1,
                        }}
                        >
                        <Box flexGrow={1}></Box>
                        <Link
                            sx={{
                                ml: "auto",
                                color: !appointmentType ? "#999" : "primary.main",
                                fontSize: "16px",
                                lineHeight: "21px",
                                mr: { xl: 2 }
                            }}
                            onClick= {()=> onAppointmentClick(data.AcctKey, strtDate)}
                            component={"button"}
                            >
                            {viewAll ? 'Hide' : 'View'} all availability   
                        </Link>
                        <Box></Box>
                    </Box>
                </Grid>
                {totalProviderCount - 1 === currentProviderIndex ? null : (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box
                            sx={{
                                mt: 5,
                                mb: 2,
                            }}
                        >
                            <hr />
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default NewProviderWithAppointment;
