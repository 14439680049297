import { getDateFromInputDateFormatUTC, getInputDateFormat, dobValidation, genDerValidation } from "../utils/Util";
import Demographics from "./Demographics";
import UserInputField from "./UserInputField";

export interface DemographicUserInputField extends UserInputField {
    enabled: number
}

export default interface DemographicsUserInput {
    FirstName: DemographicUserInputField,
    LastName: DemographicUserInputField,
    DOB: DemographicUserInputField,
    Email: DemographicUserInputField,
    Home: DemographicUserInputField,
    Cel: DemographicUserInputField,
    Address1: DemographicUserInputField,
    Address2: DemographicUserInputField,
    City: DemographicUserInputField,
    State: DemographicUserInputField,
    Country: DemographicUserInputField,
    Zip: DemographicUserInputField,
    ReferredBy1: DemographicUserInputField,
    ReferredBy2: DemographicUserInputField,
    ReferredBy3: DemographicUserInputField,
    ReferredBy4: DemographicUserInputField,
    ReferredBy5: DemographicUserInputField,
    Gender: DemographicUserInputField,
    L4DSSN: DemographicUserInputField,
}

export function validateDemographicsUserInput(demographics: DemographicsUserInput): DemographicsUserInput {
    const dobError = dobValidation(demographics.DOB.value);
    return {
      ...demographics,
      FirstName: {
        ...demographics.FirstName,
        error: demographics.FirstName.enabled !== 1 || demographics.FirstName.value.length > 0 
        ? (demographics.FirstName.value.trim().indexOf(' ') > 0 ? 'Spaces are not allowed in First Name' : '') 
        : 'First Name *'
      },
      LastName: {
        ...demographics.LastName,
        error: demographics.LastName.enabled !== 1 || demographics.LastName.value.length > 0 
        ? (demographics.LastName.value.trim().indexOf(' ') > 0 ? 'Spaces are not allowed in Last Name' : '') 
        : 'Last Name *'
      },
      Address1: {
        ...demographics.Address1,
        error: demographics.Address1.enabled !== 1 || demographics.Address1.value.length > 0 
        ? '' 
        : 'Address1 *'
      },
      Address2: {
        ...demographics.Address2,
        error: demographics.Address2.enabled !== 1 || demographics.Address2.value.length > 0 
        ? ''
        : 'Address2 *'
      },
      City: {
        ...demographics.City,
        error: demographics.City.enabled !== 1 || demographics.City.value.length > 0 
        ? '' 
        : 'City *'
      },
      State: {
        ...demographics.State,
        error: demographics.State.enabled !== 1 || demographics.State.value.length > 0 
        ? '' 
        : 'State *'
      },
      Country: {
        ...demographics.Country,
        error: demographics.Country.enabled !== 1 || demographics.Country.value.length > 0 
        ? '' 
        : 'Country *'
      },
      Zip: {
        ...demographics.Zip,
        error: demographics.Zip.enabled !== 1 || demographics.Zip.value.length > 0 
        ? (demographics.Zip.value.trim().indexOf(' ') > 0 ? 'Spaces are not allowed in Zip' : '') 
        : 'Zip *'
      },
      Gender:{
        ...demographics.Gender,
        error: demographics.Gender.enabled !== 1 || demographics.Gender.value.length > 0 
        ? ''
        : 'Gender *'
      },
      ReferredBy1: {
        ...demographics.ReferredBy1,
        error: demographics.ReferredBy1.enabled !== 1 || demographics.ReferredBy1.value.length > 0 
        ? ''
        : 'Referred By *'
      },
      L4DSSN: {
        ...demographics.L4DSSN,
        error: demographics.L4DSSN.enabled !== 1 || demographics.L4DSSN.value.length > 0 
        ? demographics.L4DSSN.value.length === 0 ? '' : (demographics.L4DSSN.value.length < 4 ? 'Enter Last 4 of SSN' : '') 
        : 'Last 4 of SSN *'
      },
/*       ReferredBy2: {
        ...demographics.ReferredBy2,
        error: demographics.ReferredBy2.enabled !== 1 || demographics.ReferredBy2.value.length > 0 
        ? '' 
        : 'ReferredBy2 is required.'
      },
      ReferredBy3: {
        ...demographics.ReferredBy3,
        error: demographics.ReferredBy3.enabled !== 1 || demographics.ReferredBy3.value.length > 0 
        ? '' 
        : 'ReferredBy3 is required.'
      },
      ReferredBy4: {
        ...demographics.ReferredBy4,
        error: demographics.ReferredBy4.enabled !== 1 || demographics.ReferredBy4.value.length > 0 
        ? '' 
        : 'ReferredBy4 is required.'
      },
      ReferredBy5: {
        ...demographics.ReferredBy5,
        error: demographics.ReferredBy5.enabled !== 1 || demographics.ReferredBy5.value.length > 0 
        ? '' 
        : 'ReferredBy5 is required.'
      }, */
      DOB: {
        ...demographics.DOB,
        error: dobError
      },
      Email: {
        ...demographics.Email,
        error: demographics.Email.enabled !== 1 || demographics.Email.value.length > 0
        ? (demographics.Email.value.trim().indexOf(' ') > 0 ? 'Spaces are not allowed in email address' : '')
        : 'Email *'
      },
      Home: {
        ...demographics.Home,
        value: demographics.Home.value.replace(/[^0-9]+/g, ''),
        error: (demographics.Home.value.replace(/[^0-9]+/g, '').length == 10 && (!demographics.Home.value.startsWith('1') && !demographics.Home.value.startsWith('0'))) ? '' : ((demographics.Home.value.startsWith('1') || demographics.Home.value.startsWith('0')) && demographics.Home.value.replace(/[^0-9]+/g, '').length <= 10)  ?  'Please enter a valid phone number.' : ((!demographics.Home.value.startsWith('1') || !demographics.Home.value.startsWith('0')) && demographics.Home.value.replace(/[^0-9]+/g, '').length > 0) ? 'Please enter a valid phone number.' : 'Home Phone *'
      },
      Cel: {
        ...demographics.Cel,
        value: demographics.Cel.value.replace(/[^0-9]+/g, ''),
        error: (demographics.Cel.value.replace(/[^0-9]+/g, '').length == 10 && (!demographics.Cel.value.startsWith('1') && !demographics.Cel.value.startsWith('0'))) ? '' : ((demographics.Cel.value.startsWith('1') || demographics.Cel.value.startsWith('0')) && demographics.Cel.value.replace(/[^0-9]+/g, '').length <= 10)  ?  'Please enter a valid phone number.' : ((!demographics.Cel.value.startsWith('1') || !demographics.Cel.value.startsWith('0')) && demographics.Cel.value.replace(/[^0-9]+/g, '').length > 0) ? 'Please enter a valid phone number.' : 'Cell Phone *'
      },  
    }
  }

export function emptyDemographicsUserInput(): DemographicsUserInput {
    return {
        FirstName: { value: '', error: '', enabled: 1 },
        LastName: { value: '', error: '', enabled: 1 },
        DOB: { value: '', error: '', enabled: 0 },
        Email: { value: '', error: '', enabled: 0 },
        Home: { value: '', error: '', enabled: 0 },
        Cel: { value: '', error: '', enabled: 0 },
        Address1: { value: '', error: '', enabled: 0 },
        Address2: { value: '', error: '', enabled: 0 },
        City: { value: '', error: '', enabled: 0 },
        State: { value: '', error: '', enabled: 0 },
        Country: { value: '', error: '', enabled: 0 },
        Zip: { value: '', error: '', enabled: 0 },
        Gender: { value: '', error: '', enabled: 0 },
        ReferredBy1: { value: '', error: '', enabled: 0 },
        ReferredBy2: { value: '', error: '', enabled: 0 },
        ReferredBy3: { value: '', error: '', enabled: 0 },
        ReferredBy4: { value: '', error: '', enabled: 0 },
        ReferredBy5: { value: '', error: '', enabled: 0 },
        L4DSSN: { value: '', error: '', enabled: 0 },
    }
}

export function mapDemographicsToUserInput(demographics: Demographics): DemographicsUserInput {
  const genderValue = genDerValidation(demographics.Gender as any);


    return {
        FirstName: { value: demographics.FirstName ?? '', error: '', enabled: 1 },
        LastName: { value: demographics.LastName ?? '', error: '', enabled: 1 },
        DOB: { value: getInputDateFormat(demographics.DOB), error: '', enabled: demographics.Config.DDOB },
        Email: { value: demographics.Email ?? '', error: '', enabled: demographics.Config.DEmail },
        Home: { value: demographics.Home ?? '', error: '', enabled: demographics.Config.DHomePhone },
        Cel: { value: demographics.Cel ?? '', error: '', enabled: demographics.Config.DCellPhone },
        Address1: { value: demographics.Address1 ?? '', error: '', enabled: demographics.Config.DAddress1 },
        Address2: { value: demographics.Address2 ?? '', error: '', enabled: demographics.Config.DAddress2 },
        City: { value: demographics.City ?? '', error: '', enabled: demographics.Config.DCity },
        State: { value: demographics.State ?? '', error: '', enabled: demographics.Config.DState },
        Country: { value: demographics.Country ?? '', error: '', enabled: demographics.Config.DCountry },
        Zip: { value: demographics.Zip ?? '', error: '', enabled: demographics.Config.DZip },
        ReferredBy1: { value: demographics.ReferredBy1 ?? '', error: '', enabled: demographics.Config.DReferredBy1 },
        ReferredBy2: { value: demographics.ReferredBy2 ?? '', error: '', enabled: demographics.Config.DReferredBy2 },
        ReferredBy3: { value: demographics.ReferredBy3 ?? '', error: '', enabled: demographics.Config.DReferredBy3 },
        ReferredBy4: { value: demographics.ReferredBy4 ?? '', error: '', enabled: demographics.Config.DReferredBy4 },
        ReferredBy5: { value: demographics.ReferredBy5 ?? '', error: '', enabled: demographics.Config.DReferredBy5 },
        Gender: { value: genderValue ?? '', error: '', enabled: demographics.Config.DGender },
        L4DSSN: { value: demographics.L4DSSN ?? '', error: '', enabled: demographics.Config.DL4DSSN },
    };
}

export function mapUserInputToDemographics(input: DemographicsUserInput, demographics: Demographics, isValid: boolean): Demographics {
    return {
        ...demographics,
        FirstName: input.FirstName.value,
        LastName: input.LastName.value,
        DOB: input.DOB.value ? getDateFromInputDateFormatUTC(input.DOB.value) : null,
        Email: input.Email.value,
        Home: input.Home.value,
        Cel: input.Cel.value,
        Address1: input.Address1.value,
        Address2: input.Address2.value,
        City: input.City.value,
        State: input.State.value,
        Country: input.Country.value,
        Zip: input.Zip.value,
        ReferredBy1: input.ReferredBy1.value,
        ReferredBy2: input.ReferredBy2.value,
        ReferredBy3: input.ReferredBy3.value,
        ReferredBy4: input.ReferredBy4.value,
        ReferredBy5: input.ReferredBy5.value,
        L4DSSN: input.L4DSSN.value,
        IsValid: isValid
    };
}