import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Demographics, { DemographicsState } from "../../models/Demographics";
import PatientInsurance, {PatientInsuranceState} from "../../models/PatientInsurance";

const initialState: PatientInsuranceState = {
    patientInsurance: {} as PatientInsurance,
}
export const patientInsuranceSlice = createSlice({
    name:'patientInsurance',
    initialState,
    reducers: {
        updateNewPatientInsurance: (state,action)=> {
            try{
                if (action.payload) {
                    state.patientInsurance = action.payload
                }
            }
            catch(e){
                console.log(e)
            }
       
        },


    }
})

export const {updateNewPatientInsurance} = patientInsuranceSlice.actions
export default patientInsuranceSlice.reducer