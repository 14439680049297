import React, { useState,useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Provider from "../provider/ProviderWithAppointment/Provider";
import { IProviderWithAppointment, SelectedAppointmentId } from "../../models";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Container, Grid } from "@mui/material";
import NewProvider from "../provider/ProviderWithAppointment/NewProvider"
import Providers from "../../models/Provider";
import { saveSession } from '../../services/SessionService'
import { stat } from "fs";
import AvailabilityGrouping from "../../models/AvailabilityGrouping";
import { Availability } from "../../models/Availability";
import { getAvailability } from "../../features/availability/availabilitySlice";
import { setSessionState } from '../../features/session/sessionSlice'
import { getFormattedDate } from "../provider/Header/Header";
import { useNavigate } from "react-router-dom";
import "./appointmentDrawerStyle.css";
import ConfirmationService from '../../services/ConfirmationService';
import { NavigatePage, PageName, getProperURL } from "../../utils/Util";
import { clearApptConfirmed } from "../hooks/useApptConfirmed";
import { AppendLabel, Event } from '../../utils/GA';

const BackwardButton = ({
  selectStart = 0,
  totalLength = 0,
  onClickPrev,
  backward=false,
}: {
  onClickPrev?: () => void;
  selectStart?: number,
  totalLength?: number,
   backward?: boolean
}): JSX.Element => {
  return (
    <IconButton
      aria-label="navigate calendar prev"
      sx={{
        strokeWidth: 1.5,
         stroke: backward ? "gray": "black",
      }}
       disabled={backward}
      onClick={onClickPrev}
    >
      <NavigateBeforeIcon />
    </IconButton>
  );
};

const ForwardButton = ({
  onClickNext,
  selectStart =0,
  totalLength =0,
  forward=false
}: {
  onClickNext?: () => void;
  selectStart?: number,
  totalLength?: number,
  forward?: boolean,
}): JSX.Element => {
  return (
    <IconButton
      aria-label="navigate calendar next"
      sx={{
        strokeWidth: 1.5,
         stroke: forward ? "gray": "black",
      }}
      onClick={onClickNext}
      disabled={forward}
    >
      <NavigateNextIcon />
    </IconButton>
  );
};

const CalendarNavigation = ({
  onClickNext,
  onClickPrev,
  startDate,
  endDate,
  selectStart,
  totalLength,
  forwardButton,
  backwardButton
}: {
  onClickNext?: () => void;
  onClickPrev?: () => void;
  startDate: string;
  endDate:string,
  selectStart: number,
  totalLength: number,
  forwardButton:boolean,
  backwardButton:boolean
}): JSX.Element => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      borderTop={"0.5px solid black !important"}
      borderBottom={"0.5px solid black !important"}
    >
      <Box sx={{ marginRight: "5%", marginLeft: "35%" }}>
        <Typography
          variant="h6"
          fontWeight={"500"}
          sx={{
            fontSize: { xs: "16px", xl: "20px" },
          }}
        >
          <>{startDate} - {endDate}</>
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: "auto",
          marginRight: "16px",
        }}
      >
        <BackwardButton onClickPrev={onClickPrev}  selectStart={selectStart} totalLength={totalLength} backward={backwardButton}/>
        <ForwardButton onClickNext={onClickNext}  selectStart={selectStart} totalLength={totalLength} forward={forwardButton}/>
      </Box>
    </Box>
  );
};

const CalendarNavigationMobile = ({
  onClickNext,
  onClickPrev,
  startDate,
  endDate,
  selectStart,
  totalLength
}: {
  onClickNext?: () => void;
  onClickPrev?: () => void;
  startDate: string
  endDate: string,
  selectStart: number,
  totalLength: number
}): JSX.Element => {
  return (
    <Box
    display={"flex"}
    alignItems={"center"}
    borderTop={"0.5px solid black !important"}
    borderBottom={"0.5px solid black !important"}
    sx={{ justifyContent: "center"}}
  >
    <Box sx={{ }}>
      <Typography
        variant="h6"
        fontWeight={"bold"}
        sx={{
          fontSize: { xs: "16px", xl: "20px" },
        }}
      >
        <>{startDate} - {endDate}</>
      </Typography>
    </Box>
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
      }}
    >
      <BackwardButton onClickPrev={onClickPrev}  selectStart={selectStart} totalLength={totalLength}/>
      <ForwardButton onClickNext={onClickNext}  selectStart={selectStart} totalLength={totalLength}/>
    </Box>
  </Box>
  );
};


const AppointmentDetails = ({ selectedDay, availabilities }: { selectedDay: SelectedAppointmentId, availabilities: AvailabilityGrouping }): JSX.Element => {
  const newDate = new Date(selectedDay.date)
  const data =availabilities[selectedDay.date] ? availabilities[selectedDay.date][selectedDay.providerId] : null
  return (
    <Box className="activity-box" sx={{ width: "100%" }}>
      <Typography
        sx={{
          fontSize: { xs: "16px", xl: "20px" },
          fontWeight: 600,
        }}
      >
        {newDate.toLocaleString("en-US", {
          weekday: "short",
          month: "short",
          day: "numeric",
        })}
        <Box component={"span"} fontWeight="400">
          {` (${data ? data.length : 'No'} appointments available)`}
        </Box>
      </Typography>
      <SlotTiles slots={data} />
    </Box>
  );
};

const AppointmentDetailsMobile = ({ selectedDay, availabilities }: { selectedDay: SelectedAppointmentId, availabilities: AvailabilityGrouping }): JSX.Element => {
  const newDate = new Date(selectedDay.date)
  const data =availabilities[selectedDay.date] ? availabilities[selectedDay.date][selectedDay.providerId] : null
  return (
    <Box className="activity-box" sx={{ width: "100%" }}>
      <Typography
        sx={{
          fontSize: { xs: "16px", xl: "20px" },
          fontWeight: 600,
        }}
      >
        {newDate.toLocaleString("en-US", {
          weekday: "short",
          month: "short",
          day: "numeric",
        })}
        <Box component={"span"} fontWeight="400">
          {` (${data ? data.length : 'No'} appointments available)`}
        </Box>
      </Typography>
      <SlotTilesMobile slots={data} />
    </Box>
  );
};

const SlotTilesMobile = ({ slots }: { slots: Availability[] | null }) => {
  const state = useAppSelector(st => st)
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const navigateTime =()=>{
    clearApptConfirmed(); //Clear any stale session history of having confirmed an appointment
    NavigatePage(dispatch,"next",state,navigate,PageName.SelectAppointmentTime)
    //const url =  getProperURL('/SelectAppointmentTime', state.embed)
    //navigate(url)
   }
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", marginTop: "16px" }}>
      {/* {Array(10)
        .fill(1)
        .map((d, index) => (
          <SlotTile
            id={index}
            time={new Date(date.getTime() + index * 15 * 60 * 1000)}
            onClick={console.log}
            key={`slot_id${index}`}
          />
        ))} */}
      {
        slots?.map((slot, index) => {
         return (
            <SlotTileMobile
              id={index}
              time= {slot.AppointmentDateTime}
              onClick={()=> {                
                console.log('Selected Appointment::', slot)
                //const acctKey = state.session.AcctKey ?? availability.AcctKey;
                const updateSession = {...state.session, AcctKey: slot?.AcctKey}
                saveSession(updateSession).then(() => {
                  dispatch(setSessionState(updateSession))
                })                                    
                dispatch(getAvailability(slot))
                ConfirmationService.reserveAppointment({ ...state.session, ApptDateTime: slot?.AppointmentDateTime, AcctKey: slot?.AcctKey })
                .then((response) => {
                    // dispatch(SessionContextAction.UpdateSession(SessionContextActions.SESSION_LOGKEY, { LogKey: response.sessionId }));
                    // dispatch(updateHashStateAction({ Hash: response.hash }));
                   
                    if(Boolean(response.data[0].status) == false)
                    {
                        // alert('Oops! The time you initially selected is no longer available. Please choose an available appointment.');
                    }
                    else
                    {
                      navigateTime();
                    }
                });
              }}
              key={`slot_id${index}`}
            />
          )
        })
      }
    </Box>
  );
};

// const date = new Date("2023-03-08 10:00");
const SlotTiles = ({ slots }: { slots: Availability[] | null }) => {
  const state = useAppSelector(st => st)
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const navigateTime =()=>{
    clearApptConfirmed(); //Clear any stale session history of having confirmed an appointment

    NavigatePage(dispatch, "next", state, navigate, PageName.SelectAppointmentTime)
    //const url =  getProperURL('/SelectAppointmentTime', state.embed)
    //navigate(url)
   }
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", marginTop: "16px" }}>
      {/* {Array(10)
        .fill(1)
        .map((d, index) => (
          <SlotTile
            id={index}
            time={new Date(date.getTime() + index * 15 * 60 * 1000)}
            onClick={console.log}
            key={`slot_id${index}`}
          />
        ))} */}
      {
        slots?.map((slot, index) => {
         return (
            <SlotTile
              id={index}
              time= {slot.AppointmentDateTime}
              onClick={()=> {
                // GA-05            
                Event(
                  false,
                  'select_time_availability_screen',
                  AppendLabel(state.session.CoverKey, state.session.LocKey?.toString(), state.session.AcctKey)
              )
                //const acctKey = state.session.AcctKey ?? availability.AcctKey;
                const updateSession = {...state.session, AcctKey: slot?.AcctKey}
                saveSession(updateSession).then(() => {
                  dispatch(setSessionState(updateSession))
                })                                    
                dispatch(getAvailability(slot))
                ConfirmationService.reserveAppointment({ ...state.session, ApptDateTime: slot?.AppointmentDateTime, AcctKey: slot?.AcctKey })
                .then((response) => {
                    // dispatch(SessionContextAction.UpdateSession(SessionContextActions.SESSION_LOGKEY, { LogKey: response.sessionId }));
                    // dispatch(updateHashStateAction({ Hash: response.hash }));
                   
                    if(Boolean(response.data[0].status) == false)
                    {
                        // alert('Oops! The time you initially selected is no longer available. Please choose an available appointment.');
                    }
                    else
                    {
                      navigateTime();
                    }
                });
               
                
              }}
              key={`slot_id${index}`}
            />
          )
        })
      }
    </Box>
  );
};

const SlotTile = ({
  time,
  onClick,
}: {
  id: number;
  time: any;
  onClick: () => void;
}) => {
  const TimeConvert =(date: any) => { 
    let hours = date.getHours();
  let minutes = date.getMinutes();
  let ap = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes.toString().padStart(2, '0')
  let mergeTime = hours + ':' + minutes + ' ' + ap;
  return mergeTime;
  }
  TimeConvert(time)
  return (
    <Button
      size="medium"
      onClick={onClick}
     // variant="contained"
      sx={{
        backgroundColor : "var(--fpc-blue)",
        color : "white",
        marginRight: "9px",
        marginBottom: "14px",
        width: "140px",
        height: "50px",
        borderRadius: "8px",
        padding: "14px 8.3px 14px 8.3px",
        fontSize: "16px",
        fontWeight: "600",
        "&:hover": {
          backgroundColor: "#6699CC",
          color: "white",
          borderRadius: "8px",
          borderWidth: "3px",
        },        
      }}
    >
      {/* {time.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })} */}
     {TimeConvert(time)}
    </Button>
  );
};

const SlotTileMobile = ({
  time,
  onClick,
}: {
  id: number;
  time: any;
  onClick: () => void;
}) => {
  const TimeConvert =(date: any) => { 
    let hours = date.getHours();
  let minutes = date.getMinutes();
  let ap = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes.toString().padStart(2, '0')
  let mergeTime = hours + ':' + minutes + ' ' + ap;
  return mergeTime;
  }
  TimeConvert(time)
  return (
    <Button
      size="medium"
      onClick={onClick}
      sx={{
        backgroundColor : "var(--fpc-blue)",
        color : "white",
        marginRight: "9px",
        marginBottom: "14px",
        width: "140px",
        height: "50px",
        borderRadius: "8px",
        padding: "14px 8.3px 14px 8.3px",
        fontSize: "16px",
        fontWeight: "600",
        "&:hover": {
          backgroundColor: "#6699CC",
          color: "white",
          borderRadius: "8px",
          borderWidth: "3px",
        },        
      }}
    >
      {/* {time.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })} */}
     {TimeConvert(time)}
    </Button>
  );
};

const ProviderCustomMessage = ({
  message,
}: {
  message?: string;
}): JSX.Element => {
  return (
    <Box
      sx={{
        marginTop: 1,
        bgcolor: "common.black",
        textAlign: "center",
        height: "51px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontSize: { xs: "16px", xl: "16px" },
          color: "primary.contrastText",
        }}
      >
        {/* Holidays may affect appointments schedule */}
        {message}
      </Typography>
    </Box>
  );
};
const ProviderCustomMessageMobile = ({
  message,
}: {
  message?: string;
}): JSX.Element => {
  return (
    <Box
      sx={{
        marginTop: "0px !important",
        bgcolor: "common.black",
        textAlign: "center",
        height: "51px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontSize: { xs: "16px", xl: "16px" },
          color: "primary.contrastText",
        }}
      >
        Holidays may affect appointments schedule
        {/* {message} */}
      </Typography>
    </Box>
  );
};

const DrawerContent = ({
  data,
  onToggle,
  onClickNext,
  onClickPrev,
  selectedAppointment,
  selectLength =0,
}: {
  onToggle?: () => void;
  onClickNext?: () => void;
  onClickPrev?: () => void;
  data: Providers | null;
  selectedAppointment: SelectedAppointmentId,
  selectLength?: number,
}): JSX.Element => {
  const state = useAppSelector((state) => state);
  const availabilities = state.availability.availabilities
  const availabilitiesDate = availabilities.dates[selectedAppointment.providerId]
  let dateIndex = availabilitiesDate.findIndex(val => val == selectedAppointment.date)
  dateIndex = dateIndex === -1 ? 0 : dateIndex
  const [selectStart, setselectStart] = useState(dateIndex)
  const [startDate, setStartDate] = useState(availabilitiesDate[selectStart])
  const [endDate, setEndDate] = useState(availabilitiesDate[selectStart+6] ? availabilitiesDate[selectStart+6] : availabilitiesDate[availabilitiesDate.length -1])
  const [arrayDates, setArrayDates] = useState<any>([])
    const[isforward,setIsForward] = useState(false);
  const[isbackward,setIsBackward] = useState(false);

  //const startDate = availabilitiesDate[selectStart]
  //const endDate = availabilitiesDate[selectStart+6] ? availabilitiesDate[selectStart+6] : availabilitiesDate[availabilitiesDate.length -1]
  const totalLength = state.availability.availabilities?.dates[selectedAppointment.providerId].length 


  const onClickForwardGA= () =>{
    // GA-03
       Event(
         false,
         'click_examtype_next_availability',
         AppendLabel(state.session.CoverKey, state.session.LocKey?.toString(), state.session.AcctKey)
     )
   }
  
   const onClickBackwardGA= () =>{
  // GA-04
       Event(
         false,
         'click_examtype_previous_availability',
         AppendLabel(state.session.CoverKey, state.session.LocKey?.toString(), state.session.AcctKey)
       )
     }

  const onClickForword= () =>{
    onClickForwardGA();
    const len = selectStart + 7
    if(totalLength > len){
      setselectStart(  len )
      var lastDate = arrayDates[arrayDates.length-1]
      let sdate =  new Date(lastDate);  
      var sdt = new Date(lastDate);
      sdt.setDate(sdt.getDate() + 1); 
      setStartDate(sdt.getTime())

      let newArr = Array()
      for (let i = 0; i < 7; i++) {     
        sdate.setDate(sdate.getDate() + 1); 
        newArr.push(sdate.getTime())
       }    
       setArrayDates(newArr)    
       setEndDate(sdate.getTime())  
     // onClickNext && onClickNext()
    }else {
      setselectStart(totalLength-1)
           setIsForward(true)
      setIsBackward(false)
    }
  }

  const onClickBackWord = () => {
    onClickBackwardGA();
    let len = selectStart - 7
  //  len = len < 0 ? 0 : len
    if(len >= 0) {
    //  onClickPrev && onClickPrev()
      setselectStart(len)
      var firstDate = arrayDates[0]
      let sdate =  new Date(firstDate); 
      sdate.setDate(sdate.getDate() - 8);  
  
      var sdt = new Date(firstDate);
      sdt.setDate(sdt.getDate() - 7); 
      setStartDate(sdt.getTime())
  
      let newArr = Array()
      for (let i = 0; i < 7; i++) {     
        sdate.setDate(sdate.getDate() + 1); 
        newArr.push(sdate.getTime())
       }    
       setArrayDates(newArr)    
       setEndDate(sdate.getTime())        
    } else {
      setselectStart(0)
          setIsForward(false)
      setIsBackward(true)
    }
  }
    
  const dateDiffInDays = (a : Date, b : Date) => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
     var result = Math.floor((utc2 - utc1) / _MS_PER_DAY)
    return result;
  }

	useEffect(()  => {
    //var sDate = availabilitiesDate[selectStart]
    let d =  new Date(availabilitiesDate[selectStart]);
    d.setDate(d.getDate())
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0); 
    var day = d.getDay()
    // Get the first day of the week with the current date
    var diff = d.getDate() - day + (day == 0 ? -6:0); 
    let sdate =  new Date(d.setDate(diff)); 
    var weekStart = sdate 
    setStartDate(sdate.getTime())
    let newArr = Array()
    for (let i = 0; i < 7; i++) {     
      newArr.push(sdate.getTime())
      sdate.setDate(sdate.getDate() + 1); 
   }    
    setEndDate(newArr[newArr.length - 1])
    setArrayDates(newArr)

    // Get the beginning date
    var dateNow = new Date()
    dateNow.setHours(0);
    dateNow.setMinutes(0);
    dateNow.setSeconds(0);
    dateNow.setMilliseconds(0); 
    var day = dateNow.getDay()
    // Get the first day of the week with the current date
    var diff = dateNow.getDate() - day + (day == 0 ? -6:0); 
    let beginningDate =  new Date(dateNow.setDate(diff));
    var numdays = dateDiffInDays(beginningDate, weekStart)

    setselectStart(numdays-7)
  }, []);      
 
  const from: Date = new Date("2023-03-08 10:00");
  return (
    <Box
      sx={{
        width: "682px",
        m: 1,
      }}
      role="presentation"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box>
          {data && <NewProvider data={data} />}
        </Box>
        <DrawerCloseButton onToggle={onToggle} />
      </Box>
      <CalendarNavigation onClickNext={onClickForword} onClickPrev={onClickBackWord} selectStart={selectStart} totalLength={totalLength} startDate={getFormattedDate(startDate)} endDate={getFormattedDate(endDate)} forwardButton={isforward} backwardButton={isbackward} />
      <Box sx={{ overflow: "auto" }}>
        {        
           arrayDates.map((date : any, idx : number)=> {
           // let sdate =  new Date(date);  
           // sdate.setDate(sdate.getDate() + idx); 
           // const dt = sdate.getTime() //availabilitiesDate[selectStart+idx]
            const obj = {date: date, providerId:selectedAppointment.providerId }
            if (!date) {
              return ''
            }
            return <AppointmentDetails selectedDay={obj} availabilities={availabilities} key={idx+Math.random()}  />
          })
        }
      </Box>
    </Box>
  );
};

const MobileDrawerContent = ({
  data,
  onToggle,
  onClickNext,
  onClickPrev,
  selectedAppointment,
  selectLength =0,
}: {
  onToggle?: () => void;
  onClickNext?: () => void;
  onClickPrev?: () => void;
  data: Providers | null;
  selectedAppointment: SelectedAppointmentId ,
  selectLength?: number,
}): JSX.Element => {
  const from: Date = new Date("2023-03-08 10:00");
  // const data = useAppSelector((state) => state.provider);
  console.log("MobileDrawerContent", data);
  const gridSpacing = 2;
  const state = useAppSelector((state) => state);
  const availabilities = state.availability.availabilities
  const availabilitiesDate = availabilities.dates[selectedAppointment.providerId]
  let dateIndex = availabilitiesDate.findIndex(val => val == selectedAppointment.date)
  dateIndex = dateIndex === -1 ? 0 : dateIndex
  const [selectStart, setselectStart] = useState(dateIndex)
  const [startDate, setStartDate] = useState(availabilitiesDate[selectStart])
  const [endDate, setEndDate] = useState(availabilitiesDate[selectStart+6] ? availabilitiesDate[selectStart+6] : availabilitiesDate[availabilitiesDate.length -1])
  const [arrayDates, setArrayDates] = useState<any>([])

  //const startDate = availabilitiesDate[selectStart]
  //const endDate = availabilitiesDate[selectStart+6] ? availabilitiesDate[selectStart+6] : availabilitiesDate[availabilitiesDate.length -1]
  const totalLength = state.availability.availabilities?.dates[selectedAppointment.providerId].length 
  
   const onClickForwardGA= () =>{
      // GA-03
      Event(
        false,
        'click_examtype_next_availability',
        AppendLabel(state.session.CoverKey, state.session.LocKey?.toString(), state.session.AcctKey)
     )
   }
  
   const onClickBackwardGA= () =>{
      // GA-04
      Event(
        false,
        'click_examtype_previous_availability',
        AppendLabel(state.session.CoverKey, state.session.LocKey?.toString(), state.session.AcctKey)
       )
     }

    const onClickForword= () =>{
      onClickForwardGA();
      const len = selectStart + 7
    if(totalLength > len){
      setselectStart(  len )
      var lastDate = arrayDates[arrayDates.length-1]
      let sdate =  new Date(lastDate);  
      var sdt = new Date(lastDate);
      sdt.setDate(sdt.getDate() + 1); 
      setStartDate(sdt.getTime())

      let newArr = Array()
      for (let i = 0; i < 7; i++) {     
        sdate.setDate(sdate.getDate() + 1); 
        newArr.push(sdate.getTime())
       }    
       setArrayDates(newArr)    
       setEndDate(sdate.getTime()) 
    }else {
      setselectStart(totalLength-1)
    }
  }

  const onClickBackWord = () => {
    onClickBackwardGA();
    const len = selectStart - 7
    if(len > 0) {
      setselectStart(len)
      var firstDate = arrayDates[0]
      let sdate =  new Date(firstDate); 
      sdate.setDate(sdate.getDate() - 8);  
  
      var sdt = new Date(firstDate);
      sdt.setDate(sdt.getDate() - 7); 
      setStartDate(sdt.getTime())
  
      let newArr = Array()
      for (let i = 0; i < 7; i++) {     
        sdate.setDate(sdate.getDate() + 1); 
        newArr.push(sdate.getTime())
       }    
       setArrayDates(newArr)    
       setEndDate(sdate.getTime())
    } else {
      setselectStart(0)
    } 
  }

  const dateDiffInDays = (a : Date, b : Date) => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    var result = Math.floor((utc2 - utc1) / _MS_PER_DAY)

    return result;
  }

	useEffect(()  => {
    //var sDate = availabilitiesDate[selectStart]
    let d =  new Date(availabilitiesDate[selectStart]);
    d.setDate(d.getDate())
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0); 
    var day = d.getDay()
    // Get the first day of the week with the current date
    var diff = d.getDate() - day + (day == 0 ? -6:0); 
    let sdate =  new Date(d.setDate(diff)); 
    var weekStart = sdate 
    setStartDate(sdate.getTime())
    let newArr = Array()
    for (let i = 0; i < 7; i++) {     
      newArr.push(sdate.getTime())
      sdate.setDate(sdate.getDate() + 1); 
   }    
    setEndDate(newArr[newArr.length - 1])
    setArrayDates(newArr)

    // Get the beginning date
    var dateNow = new Date()
    dateNow.setHours(0);
    dateNow.setMinutes(0);
    dateNow.setSeconds(0);
    dateNow.setMilliseconds(0); 
    var day = dateNow.getDay()
    // Get the first day of the week with the current date
    var diff = dateNow.getDate() - day + (day == 0 ? -6:0); 
    let beginningDate =  new Date(dateNow.setDate(diff));
    var numdays = dateDiffInDays(beginningDate, weekStart)

    setselectStart(numdays-7)
  }, []);      
 
  return (
    <Box
      sx={{
        maxWidth: "682px",
        m: 1,
      }}
      role="presentation"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box>
          {data && <NewProvider data={data} />}
        </Box>
        <DrawerCloseButton onToggle={onToggle} />
      </Box>
      <CalendarNavigationMobile
       onClickNext={onClickForword}
       onClickPrev={onClickBackWord} 
       selectStart={selectStart} 
       totalLength={totalLength} 
       startDate={getFormattedDate(startDate)} 
       endDate={getFormattedDate(endDate)} 
       />
      <Box sx={{ overflow: "auto" }}>
        {   
           arrayDates.map((date : any, idx : number)=> {
           // let sdate =  new Date(date);  
           // sdate.setDate(sdate.getDate() + idx); 
           // const dt = sdate.getTime() //availabilitiesDate[selectStart+idx]
          const obj = {date: date, providerId:selectedAppointment.providerId }
            if (!date) {
              return ''
            }
            return <AppointmentDetails selectedDay={obj} availabilities={availabilities} key={idx+Math.random()}  />
          })
        }
      </Box>
    </Box>
    // <Container maxWidth={"sm"}>
    //   <Grid container spacing={0}>
    //     <Grid item xs={12}>
    //       <Grid container spacing={0}>
    //         {/* <Grid item lg={12} md={12} sm={12} xs={12}>
    //           <ProviderCustomMessageMobile
    //             message={data?.provider.providerCustomMessage}
    //           />
    //         </Grid> */}
    //         <Grid item lg={12} md={12} sm={12} xs={12}>
    //         <Box>
    //           {data && <NewProvider data={data} />}
    //         </Box>
    //     <DrawerCloseButton onToggle={onToggle} />
    //         </Grid>
    //         <Grid item lg={12} md={12} sm={12} xs={12}>
    //           <CalendarNavigationMobile
    //             onClickNext={onClickForword}
    //             onClickPrev={onClickBackWord}
    //             selectStart={selectStart} 
    //             totalLength={totalLength} 
    //             startDate={getFormattedDate(startDate)} 
    //             endDate={getFormattedDate(endDate)}
    //           />
    //         </Grid>
    //         <Grid item lg={12} md={12} sm={12} xs={12}>
    //           <Box sx={{ overflow: "auto", flexWrap: "wrap", display: "flex" }}>
    //             {Array(7)
    //               .fill(1)
    //               .map((d, index) => {
    //                 const newDate = new Date(
    //                   new Date(from).getTime() + index * 86400000
    //                 );
    //                 if (newDate.getDay() > 0 && newDate.getDay() < 6)
    //                   return (
    //                     <AppointmentDetailsMobile
    //                       date={newDate}
    //                       key={`appt_id_${index}`}
    //                     />
    //                   );
    //               })}
    //           </Box>
    //         </Grid>
    //       </Grid>
    //     </Grid>
    //   </Grid>
    // </Container>
  );
};

const DrawerCloseButton = ({
  onToggle,
}: {
  onToggle?: () => void;
}): JSX.Element => {
  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
      <IconButton onClick={onToggle}>
        <CloseOutlinedIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};

export interface IAppointmentWindowProps {
  open?: boolean;
  onToggle?: () => void;
  onClickNext?: () => void;
  onClickPrev?: () => void;
  selectedAppointment?: SelectedAppointmentId | any
  selectLength?: number
}

const AppointmentWindow = ({
  open,
  onToggle,
  onClickNext,
  onClickPrev,
  selectedAppointment,
  selectLength
}: IAppointmentWindowProps) => {
  const selectedProvide = useAppSelector((state) => state.provider)
  const state = useAppSelector((state) => state);
  if (!selectedAppointment?.date) {
    return <></>
    // return <>No data</>
  }
  const selectedProvider: any = state.providers.providersDistinct.find(x => x.AcctKey == selectedAppointment?.providerId)
  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={() => onToggle}
      PaperProps={{ square: false }}
    >
    <MobileDrawerContent
      onToggle={onToggle}
      onClickNext={onClickNext}
      onClickPrev={onClickPrev}
      data={selectedProvider}
      selectedAppointment={selectedAppointment}
      selectLength={selectLength}
    />
    </Drawer>
  );
};

interface IAppointmentDrawerProps extends IAppointmentWindowProps { }

const AppointmentDrawer = ({
  open,
  onToggle,
  onClickNext,
  onClickPrev,
  selectedAppointment,
  selectLength,
}: IAppointmentDrawerProps) => {
  const selectedProvide = useAppSelector((state) => state.provider)
  const state = useAppSelector((state) => state);
  if (!selectedAppointment?.date) {
    return <></>
    // return <>No data</>
  }
  const selectedProvider: any = state.providers.providersDistinct.find(x => x.AcctKey == selectedAppointment?.providerId)
  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={() => onToggle}
      PaperProps={{ square: false }}
      sx={{}}
    >
      <DrawerContent
        onToggle={onToggle}
        onClickNext={onClickNext}
        onClickPrev={onClickPrev}
        data={selectedProvider}
        selectedAppointment={selectedAppointment}
        selectLength={selectLength}
      />
    </Drawer>
  );
};
export { AppointmentDrawer };
export default AppointmentWindow;
