import React, { lazy, ReactNode } from "react";
import { RouteObject } from "react-router";
import AppointmentWindow, {
  IAppointmentWindowProps,
} from "../components/appointment/appointmentDrawer";
import { SelectAppointmentTime } from "../components/appointment/SelectAppointmentTime";
import { PatientDemographics } from "../components/demographics/PatientDemographics";
// project imports
import MainLayout from "../layouts/main";
import LandingPage from "../pages/LandingPage";
import VisionInsurance from "../components/appointment/VisionInsurance";
import MultipleAppointments from "../components/appointment/MultipleAppointments";
import AppointmentVisitDetails from "../components/appointment/AppointmentVisitDetails";
import CustomQuestion from "../components/customQuestion.tsx/CustomQuestion";
import AppointmentConfirmation from "../components/appointment/AppointmentConfirmation";
import  AppointmentValidation  from "../components/appointment/AppointmentValidation";
import AppointmentNotAvailable from "../components/appointment/AppointmentNotAvailable"
import IframeLanding from "../pages/IframeLanding";

const MainRoutes: RouteObject = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <LandingPage newLocations={[]} />,
    },
    {
      path: "/iframe.html",
      element: <IframeLanding newLocations={[]} />,
    },
    {
      path: "/appointmentwindow",
      element: <AppointmentWindow />,
    },
    {
      path: "SelectAppointmentTime",
      element: <SelectAppointmentTime />
    },
    {
      path: "PatientDemographics",
      element: <PatientDemographics />
    },
    {
      path: "/visioninsurance",
      element: <VisionInsurance /> 
    },
    {
      path: "/multipleAppointment",
      element: <MultipleAppointments/>
    },
    {
      path: "/appointmentvisitdetails",
      element: <AppointmentVisitDetails/>
    },
    {
      path: "/appointmentConfirmation",
      element: <AppointmentConfirmation/>
    },
    {
      path: "/CustomQuestion",
      element: <CustomQuestion/>
    }  , 
    {
      path: "/AppointmentNotAvailable",
      element: <AppointmentNotAvailable/>
    }  
  ],
};

export default MainRoutes;
