import { getBaseAPI, getConfigBaseAPI } from "../environments/Environments";
import { SessionModel, mapSessionData } from "../models/Session";
import { reject } from 'q'
import axios from "axios";
import { EcpConfigurationAction, EcpPatientConfiguration } from "../models/EcpPatientConfiguration";
import { setEcpConfiguration } from "../features/embed/embedSlice";
import { setSessionState } from "../features/session/sessionSlice";
import { initGTM } from "../utils/GTM";
import { initInternalGA } from "../utils/GA";
import { GetCoverKeyInformation } from "./InitServices";

export const SessionService = (
	logKey: string,
	coverKey: string,
	source: string,
	state: { counter?: any; provider?: any; session: any; appointmentData?: any; hash?: any; embed?: any; },
	dispatch : any
): Promise<SessionModel> => {
	const queryString = source !== '' ? `coverKey=${coverKey}&source=${source}` : `coverKey=${coverKey}`;
    function merge(embed: any, hash: any, session: any): SessionModel {
        const answer: any = {};
        for (const key in session) {
            if (session[key]) {
                answer[key] = session[key];
            } else if (hash[key]) {
                answer[key] = hash[key];
            } else if (embed[key]) {
                answer[key] = embed[key];
            }
        }
        return answer;
    }	
	
	const endsWithNumber = (str: string) => {
		const x = parseInt(str.slice(-1));
		return isNaN(x) ? false : true;
	};

	const validateGTM = (id: string) => {
		const regexp = /(GTM|UA|YT|MO)-/i;
		return regexp.test(id);
	};


	return new Promise(async (resolve, reject) => {

		// Generate Session
		let sessionModel: SessionModel = {
			LogKey: null,
			CoverKey: null,
			LocKey: null,
			GroupKey: null,
			AcctKey: null,
			AddKey: null,
			TaskKey: null,
			ApptKey: null,
			ClientIP: null,
			CreatedOn: String(new Date()),
			ApptDateTime: null,
			CurrentPage: 0,
			V4Scenario: null,	
			TrackingID: null,		
		}
		let url = await getBaseAPI() + "/api/v1/Patient/generateSession?" + queryString;
		await axios.get(url, {timeout : Number(`${process.env.REACT_APP_REQUEST_TIMEOUT }`) })
		.then(res => {
		  if (res.data) {
			var session = res.data
			 sessionModel = {
				LogKey: session.sessionID,
				CoverKey: session.coverKey,
				LocKey: null,
				GroupKey: null,
				AcctKey: null,
				AddKey: null,
				TaskKey: null,
				ApptKey: null,
				ClientIP: session.clientIP,
				CreatedOn: String(new Date()),
				ApptDateTime: null,
				CurrentPage: 0,
				V4Scenario: null,
				TrackingID:null,
			};
			console.log('Session APi::', sessionModel)
			//resolve(sessionModel);			
			console.log("URL from config: " + res.data.message)
		  }
		}).catch((ex) => {
			console.log("request error", ex); 
			reject(ex);
		})


		// Cover information
		const url_string = window.location.href;
		const urlParam = new URL(url_string);

		//var defaultLogo = `${process.env.REACT_APP_DEFAULT_LOGO as string}`;
/* 		var coverName = "";
		var mobileLogo =   "";
		var desktopLogo =  ""; */
		var locKey : any = urlParam.searchParams.has("LocKey") ? urlParam.searchParams.get("LocKey") : 0
		//if (locKey > 0)
		  const	[coverName, mobileLogo, desktopLogo] = await GetCoverKeyInformation(locKey, coverKey)

		// ECP Configuration
			const ecpObj: EcpConfigurationAction = {
				CoverKey: urlParam.searchParams.has("CoverKey")
					? urlParam.searchParams.get("CoverKey")
					: coverKey,
				LocKey: urlParam.searchParams.has("LocKey")
					? urlParam.searchParams.get("LocKey")
					: "",
				GroupKey: urlParam.searchParams.has("GroupKey")
					? urlParam.searchParams.get("GroupKey")
					: "",
				AcctKey: urlParam.searchParams.has("AcctKey")
					? urlParam.searchParams.get("AcctKey")
					: "",
				TaskKey: urlParam.searchParams.has("TaskKey")
					? urlParam.searchParams.get("TaskKey")
					: "",
				Path: urlParam.searchParams.has("Path")
					? urlParam.searchParams.get("Path")
					: "AppointmentType",
				Stylings: urlParam.searchParams.has("Stylings")
					? urlParam.searchParams.get("Stylings")
					: "",                            
				SkipDemographics:
					state.embed.SkipDemographics != undefined &&
						state.embed.SkipDemographics != null
						? state.embed.SkipDemographics
						: false,
				SkipPatientInsurance:
					state.embed.SkipPatientInsurance != undefined &&
						state.embed.SkipPatientInsurance != null
						? state.embed.SkipPatientInsurance
						: false,
				SkipCustomQuestions:
					state.embed.SkipCustomQuestions != undefined &&
						state.embed.SkipCustomQuestions != null
						? state.embed.SkipCustomQuestions
						: false,
				SkipPatientInformation:
					state.embed.SkipPatientInformation != undefined &&
						state.embed.SkipPatientInformation != null
						? state.embed.SkipPatientInformation
						: false,						
				TrackingID: urlParam.searchParams.has("TrackingID")
						? urlParam.searchParams.get("TrackingID")
						: "0",         
				Embed: urlParam.searchParams.has("Embed")
						? urlParam.searchParams.get("Embed")
						: "false",                                                       
			}    

			dispatch(setEcpConfiguration(ecpObj))		

            // Check other Parameters 
			var updatedSession = {...sessionModel}
			if (ecpObj.LocKey !== "")
				updatedSession = {...updatedSession, LocKey : Number(ecpObj.LocKey)}
			if (ecpObj.AcctKey !== "")
				updatedSession = {...updatedSession, AcctKey : ecpObj.AcctKey}
			if (ecpObj.TaskKey !== "")
				updatedSession = {...updatedSession, TaskKey : ecpObj.TaskKey}
			if (ecpObj.TrackingID !== "")
				updatedSession = {...updatedSession, TrackingID : ecpObj.TrackingID}                                   
				
			updatedSession = {...updatedSession,  OrgName : coverName , MobileLogo : mobileLogo , DesktopLogo : desktopLogo} 
			// Update Session State
			dispatch(setSessionState(updatedSession))	
			// Tracking Service

			if (ecpObj.TrackingID && ecpObj.TrackingID !== "0")
			{
				const baseUrl = await getBaseAPI()
				url = baseUrl + '/api/v1/Tracking/GetTracking';
				const bodyRequest = {
				...updatedSession,
				LogKey: updatedSession.LogKey === null ? 0 : updatedSession.LogKey,
				trackingID: updatedSession.TrackingID === null ? '0' : updatedSession.TrackingID
				}


				delete bodyRequest.TrackingID
				var json = JSON.stringify(bodyRequest)

				await axios.post(url,bodyRequest, {timeout : Number(`${process.env.REACT_APP_REQUEST_TIMEOUT }`)})
				.then(res => {
				var response = res.data
				if (response)
				{
					//  Do the initialization here
					const { headerScript } = response;

					const extractedInstance = headerScript.substring(
						headerScript.indexOf("GTM-"), 
						headerScript.lastIndexOf("');</script>")
					);
					if (extractedInstance!=='')
					{
						initGTM(extractedInstance)
						// alert(extractedInstance + ' init gtm')
					}			
				}		
				})
				.catch((e) => {
				console.log("request error", e);
				reject(e);
				});		

				initInternalGA("");
	    }
			
		resolve(updatedSession);
	})
}
export const saveSession = (
	session: SessionModel
): Promise<{ SessionId: string; Hash: string }> => {
	return new Promise(async (resolve, reject) => {
		const url = await getBaseAPI() + "/api/v1/Patient/saveSession";
		fetch(url, {
			method: "POST",
			body: JSON.stringify(mapSessionData(session)),
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((res) => res.json())
			.then((result) => Promise.resolve(result))
			.then((session) => {
				setTimeout(() => {
					resolve({
						SessionId: String(session.sessionId),
						Hash: String(session.hash),
					});
				}, 500);
			})
			.catch((e) => {
				console.log("request error", e);
				reject(e);
			});
	});
}

export const getCoverInformation = (
	coverKey: string | null | undefined,
	locKey: string | null | undefined
): Promise<{ coverKey: string; orgName: string; status : number, DesktopLogo : string , MobileLogo : string } | any> => {
	return new Promise(async (resolve, reject) => {
		const url = await getConfigBaseAPI() + "/api/v1/ECPDashboard/GetCoverProviderInfo?coverKey=" + coverKey + "&locKey=" + locKey;
		
		await axios.get(url, {timeout : Number(`${process.env.REACT_APP_REQUEST_TIMEOUT }`)})
		.then(res => {
			var response = res.data
			if (response)
			{
				resolve({
					coverKey: response.coverKey,
					orgName: response.orgName,
					status : response.status,
					DesktopLogo :  response.desktopLogo,
					MobileLogo : response.mobileLogo
				});	
		   }		
		})
		.catch((e) => {
			console.log("request error", e);
			reject(e);
		});

	});
}
