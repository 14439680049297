export default interface Providers {
    AcctKey: string,
    LocKey: string
    GroupKey: string | null,
    AcctType: string | undefined | null,
    Status:  string | undefined | null,
    FirstName: string | undefined | null,
    LastName: string | undefined | null,
    MI: string | undefined | null,
    Title: string | undefined | null,
    FrontLine: string | undefined | null,
    FrontLineExt: string | undefined | null,
    Email: string | undefined | null,
    Fax: string | undefined | null,
    Speciality : string | undefined | null,
    Degree: string | undefined | null,
    Photo: string | undefined | null,
}
export interface NewProviderState {
    provider:  Providers,
    providers: Providers[],
    providersDistinct:Providers[]
    providersWithAppointment:Providers[]
}

export interface ProviderData {
    acctKey: string,
    locKey: string,
    groupKey: string | null,
    acctType: string | undefined | null,
    status:  string | undefined | null,
    firstName: string | undefined | null,
    lastName: string | undefined | null,
    mi: string | undefined | null,
    title: string | undefined | null,
    frontLine: string | undefined | null,
    frontLineExt: string | undefined | null,
    email: string | undefined | null,
    fax: string | undefined | null,
    speciality: string | undefined | null,
    degree: string | undefined | null,
    photo: string | undefined | null,
}

export function mapProviderData(data: ProviderData): Providers {
    return {
        AcctKey : data.acctKey,
        LocKey: data.locKey,
        GroupKey: data.groupKey,
        AcctType : data.acctType,
        Status:  data.status,
        FirstName  : data.firstName ?? '',
        LastName : data.lastName ?? '',
        MI : data.mi ?? '',
        Title : data.title ?? '',
        FrontLine : data.frontLine ?? '',
        FrontLineExt : data.frontLineExt ?? '',
        Email : data.email ?? '',
        Fax : data.fax ?? '',
        Speciality : data.speciality ?? '',
        Degree : data.degree ?? '',
        Photo : data.photo ?? ''
    }
}

export const createEmptyProivder : Providers = {
    AcctKey : '',
    LocKey: '',
    GroupKey: null,
    AcctType : '4',
    Status:  '0',
    FirstName  : '',
    LastName : '',
    MI : '',
    Title : '',
    FrontLine : '',
    FrontLineExt : '',
    Email : '',
    Fax : '',
    Speciality : '',
    Degree : '',
    Photo : '',
};