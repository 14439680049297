import React, { useContext,useCallback, useEffect, useState } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Box, useMediaQuery,Typography,  Autocomplete, TextField, AutocompleteRenderInputParams, InputProps, InputAdornment  } from "@mui/material";
import Header from "../components/provider/Header";
import { AppContext, AppContextType } from "../App";
import {
  IProviderWithAppointment,
  IAppointmentType,
  SelectedAppointmentId,
} from "../models";
import CustomWidthTooltip from "../components/tooltips/CustomTooltip";
import { onSelectProvider } from "../features/provider/providerSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { useNavigate } from "react-router-dom";
import AppointmentWindow, { AppointmentDrawer } from "../components/appointment/appointmentDrawer";
import { onAppointmentTypesChange, setSessionState } from "../features/session/sessionSlice";
import AppointmentType from "../models/AppointmentType";
import { setAppointmentType } from "../features/appointmentData/appointmentSlice";
import { setHashforState } from "../features/hasher/hasherSlice";
import LocationDetails from "../models/LocationDetails";
import Provider from "../models/Provider";
import NewProviderWithAppointment from "../components/provider/ProviderWithAppointment/NewProviderWithAppointment";
import { clearProviderData, getAvailabililties, getlocationsback } from "../features/availability/availabilitySlice";
import SimpleMap from "../components/map/SimpleMap";
import { AvailabilityService } from "../services/AvailabilityService";
import { saveSession } from '../services/SessionService'
import Spinner from "../components/spinner/Spinner";
import { SessionService } from "../services/SessionService";
import { NavigatePage, PageName, arraySortExt } from "../utils/Util";
import { ColorAutocomplete } from "../components/autocomplete";
//import Select from "../components/select/Select";
import NewLocation from "../components/location/NewLocation";

import InitServices, { GetCoverKeyInformation, UpdateEmbededState } from "../services/InitServices";
import AppointmentDetails from "../models/AppointmentDetails";
import { AppendLabel, Event, PageView } from '../utils/GA';
import ColorSelect from "../components/select/ColorSelect";
import Providers from "../models/Provider";

import { setProviderWithAppointment } from "../features/provider/newProviderSlice";


interface ILandingPageProps {
  newLocations: LocationDetails[]
}

const LandingPage = ({newLocations}: ILandingPageProps) => {
  const navigate = useNavigate();
  const [pageLoading, setPageLoading] = useState(false);
  const gridSpacing = 2;
  const { formData, setFormData } = useContext(AppContext) as AppContextType
  const [selectedAppointment, setSelectedAppointment] = useState<SelectedAppointmentId |null>(null)
  const dispatch = useAppDispatch();
  const state = useAppSelector(stat => stat)
    useState<IAppointmentType | null>(null);
  const [providerWithAppointmentsData, setproviderWithAppointmentsData] =
    useState<IProviderWithAppointment[]>([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const [selectedNewLocation, setSelectedNewLocation] = useState<LocationDetails | null>(null)
  const [hasSchedules, setHasSchedules] = useState(true);
  
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>([]);
  const [isDisableButton,setIsDisableButton]=useState(false);

  const [datePaginationList, setDatePaginationList] = useState<any>([]);
  
  const [selectLength, setSelectLength] = useState(0);
  const totalLength = state.availability.availabilities?.dates?.FIRST_AVAILABLE?.length || 0


  const initFormData = {
    ...formData,
    firstName: '',
    lastName: '',
    pNumber: '',
    email: '',
    day: '',
    month: '',
    year: '',
    address1: '',
    address2: '',
    city:'',
    state:'',
    zip: '',
    country:'',
    mobilePhone:'',
    referredBy:'',
    displayVisionInsurance: '',
    displayMedicalInsurance: '',
    visionInsuranceCarrier: '',
    visionInsuranceOthersName: '',  
    visionInsuranceCarrierServiceNum: '',
    visionInsuranceGroupNumber: '',
    visionInsuranceRelationToPatient: '',
    visionInsuranceSubscriberDOB: '',
    visionInsuranceSubscriberID: '',
    visionInsuranceSubscriberName: '',
    visionDay: '',
    visionMonth: '',
    visionYear: '',
    medicalInsuranceCarrier: '',
    medicalInsuranceOthersName: '', 
    medicalInsuranceCarrierServiceNum: '',
    medicalInsuranceGroupNumber: '',
    medicalInsuranceRelationToPatient: '',
    medicalInsuranceSubscriberDOB: '',
    medicalInsuranceSubscriberID: '',
    medicalInsuranceSubscriberName: '',
    medicalDay: '',
    medicalMonth: '',
    medicalYear: '',
  }  

// -------------------- Hooks

  useEffect(()  => {
     // dispatch(setSessionState({...state.session,CurrentPage :  PageName.LandingPage}))
      if (state.appointmentData.appointmentTypesDistinct.length === 0)
      {
        //initData()
        setPageLoading(true)
        const fetchData = async () => {
          const data =  await  InitServices(state.session, dispatch, state)
          .then((response: boolean) => {
            var ret = response
            setIsDataLoaded(response)
            setPageLoading(false)
          })      
        }  
        fetchData()     
      }
      else if (state.session.LastPage)
      {
        setPageLoading(true)
        formData.year = "" 
        setFormData(initFormData);    

        //let InitState : any = {...state.session, AcctKey: '0'}
        //var sState = state
        //var prevStateSession = state.session
        dispatch(setSessionState(null))
        SessionService(
          "",
          String(state?.session?.CoverKey ?? ""),
          "",
          state,
          dispatch
        ).then((session) => {
          dispatch(setSessionState(session))        
          dispatch(clearProviderData())  
          let updatedState: any = state   
          updatedState.session = {...state.session, LogKey: session.LogKey, AcctKey: null, AddKey : null }
          var locKey : any = state.session.LocKey 
          AvailabilityService(updatedState, locKey).then((res)=> {
            dispatch(getAvailabililties(res.data[0]))
            dispatch(setHashforState(res.hash))

            generatePagination(res.data[0])

            setPageLoading(false)
            // reset LastPage
            const updateSession = {...state.session, LastPage : false, locKey : null, AcctKey: null, CurrentPage :  PageName.LandingPage}
            dispatch(setSessionState(updateSession))
      
        }).catch((ex) =>
        {
          setPageLoading(false)
        })      
      })

      }
      else
      {

        if (state.providers.providersDistinct.length > 0 && !pageLoading) 
        {
            setHasSchedules(true)
            generatePagination(state.availability.availabilities)
        }
        setPageLoading(false)     
      }

      PageView(
          `pageview_landing_page_screen_${AppendLabel(state.session.CoverKey, state.session.LocKey?.toString(), state.session.AcctKey)}`
      )

    }, []);
    

   useEffect(() => {
         dispatch(setProviderWithAppointment([])) 
          var data = state.availability.availabilities
          // Sort by date, Provider, Remove provider that has no schedule
          if (data.dates && datePaginationList.length > 0)
          {
            var tmpProvider : {firstDate : number ,provider :  Providers[]}[] = []
           

            var dateKeys = Object.keys(data.dates);
            dateKeys.forEach((key) => {
              var providerSchedule = data.dates[key]
              var pageNum = selectLength > 0 ? selectLength / 20 : 0
              let sdate =  new Date(datePaginationList[pageNum]);
              // Display only first 20 days of the calendar
              var foundTime = false
              for (let i = 0; i < 21; i++) {  
                var epochtime : any =  sdate.getTime()
                
                // Check if date found in the data array
                // Sample Format of data
                // 1694016000000 : <-- Epoch Time
                //  {13511 : [{Array of Provider Schedules }]},
                //  {944 : [{Array of Provider Schedules }]},
                var dte = data[epochtime]
                // If date was found  
                if (dte)
                {
                  // Check if the Provider} is part of that date
                  if (dte[key])
                  {
                     // This means that the Provider has a schedule with the current date (epochtime)
                     // We only get the first date of the Provider
                     foundTime = true
                     break
                  }
                }
      
                // Increment date until it reaches a maximum of 20 days
                sdate.setDate(sdate.getDate() + 1); 
              }
      
              if (foundTime)
              {
                var selectedProvider =  state.providers.providersDistinct.filter(p => Number(p.AcctKey) === Number(key))
                if (selectedProvider.length > 0)
                {
                    var object : any  = {firstDate : epochtime ,provider :  selectedProvider[0]}
                    tmpProvider.push(object)
                }
              }
            })

            var tmpProviderFinalList :  Providers[] = []
            if (tmpProvider.length > 0)
            {
              const keyValueArray  = Object.entries( tmpProvider);
              var a = keyValueArray[0][1].firstDate
              // Sort for the first date found
              keyValueArray.sort((a: any, b: any) => Number(a[1].firstDate) - Number(b[1].firstDate));
              
              // Get all Provider sorted by first date found
              keyValueArray.forEach((key : any) => {
                var prov = key[1].provider
                tmpProviderFinalList.push(key[1].provider)
              })
           
            }
        
            dispatch(setProviderWithAppointment(tmpProviderFinalList)) 
        }
  }, [state.availability.availabilities.dates, selectLength, datePaginationList]);   


  useEffect(() => {

    if (isDataLoaded)
    {
    var isLocKeySuccess = false
    if (state.embed.LocKey && state.appointmentData.appointmentTypesLocation.length > 0)
    {
      var location = state.appointmentData.appointmentTypesLocation
      var filterData = location.filter(p => Number(p.LocKey) === Number(state.embed.LocKey))
      if (filterData.length > 0)
      {

        dispatch( getlocationsback(filterData[0]))   
        setSelectedNewLocation(filterData[0])  
        isLocKeySuccess = true 
      }   
    }

    var isTaskKeySuccess = false
    if (state.embed.TaskKey)
    {
        // Get the name of the Appointment not the Key
        var appointmentName = ""
        var appointmentList = state.appointmentData.appointmentTypes.filter(p => p.TaskKey === Number(state.embed.TaskKey))
        if (appointmentList.length > 0)
        {
            appointmentName = appointmentList[0].Name
        
            var filterApptData = state.appointmentData.appointmentTypesDistinct.filter(p => p.Name === appointmentName)
            dispatch(setAppointmentType(filterApptData[0]))
            isTaskKeySuccess = true 
        }  
 
    } 
    
    
     if (isLocKeySuccess && isTaskKeySuccess)
     {
      setPageLoading(true)
      setHasSchedules(false) 
      const logKey:any = state.session?.LocKey
      AvailabilityService(state, logKey).then((res)=> {
        if (res.data[0].providers.length > 0) 
           setHasSchedules(true) 
        dispatch(getAvailabililties(res.data[0]))
        dispatch(setHashforState(res.hash))

        generatePagination(res.data[0])
        setPageLoading(false)
      }).catch(() => {
        setPageLoading(false)
      })
     }
     }
    
   }, [isDataLoaded]);


useEffect(() => {

  if (state.embed.AcctKey !== selectedAppointment?.providerId && selectedAppointment)
  {
    var sessionWithAcctKey = {...state.session, AcctKey : selectedAppointment?.providerId}
    UpdateEmbededState(sessionWithAcctKey,dispatch)
  }
},[selectedAppointment])

// ----------------- Hooks


  const generatePagination = (data : any) =>
  {
    if (data.providers.length > 0) 
    {
      var dateKeys = Object.keys(data);
      var firstTimeStamp = dateKeys[2]
      var lastTimeStamp = dateKeys[dateKeys.length - 1];
      var startDate = new Date(Number(firstTimeStamp));
      var endDate = new Date(Number(lastTimeStamp));

      // get the first day of the week      
      var firstDayofWeek = startDate.getDate() - startDate.getDay() 
      startDate.setDate(firstDayofWeek);
      
      var dateList: any =  []
      while (startDate < endDate)
      {
          var currentDate = new Date(startDate)
          dateList.push(currentDate)
          startDate.setDate(startDate.getDate()  + 21);   
      }
      setDatePaginationList(dateList)
      setSelectLength(0)
    }

  }

  const onNewLocationSelection = async (data: LocationDetails | null)=> {
    
    setHasSchedules(false)
    setPageLoading(true)
    setSelectedNewLocation(data)
    dispatch( getlocationsback(data))
    const updateSession = {...state.session, LocKey: data?.LocKey}

    saveSession(updateSession).then(() => {
      dispatch(setSessionState(updateSession))
    })


    if (data) {
      const locKey:any =data?.LocKey
      var availState = state
          availState.session = {...updateSession, AcctKey : null}
      AvailabilityService(availState, locKey).then( async (res)=> {
        if (res.data[0].providers.length > 0) 
           setHasSchedules(true) 
        dispatch(getAvailabililties(res.data[0]))
        dispatch(setHashforState(res.hash))

          var coverName = "";
          var mobileLogo =   "";
          var desktopLogo =  "";         
          [coverName, mobileLogo, desktopLogo] = await GetCoverKeyInformation(locKey, state.session.CoverKey)  
          var currentSession = {...state.session, LocKey : locKey,   OrgName : coverName , MobileLogo : mobileLogo , DesktopLogo : desktopLogo}   
          dispatch(setSessionState(currentSession))

        generatePagination(res.data[0])
        setPageLoading(false)
      }).catch(() => {
        setPageLoading(false)
      })

/*       if (locKey > 0 && state.session.LocKey !== locKey)
      {
        var coverName = "";
		    var mobileLogo =   "";
		    var desktopLogo =  "";         
        [coverName, mobileLogo, desktopLogo] = await GetCoverKeyInformation(locKey, state.session.CoverKey)  
        var currentSession = {...state.session,  OrgName : coverName , MobileLogo : mobileLogo , DesktopLogo : desktopLogo}   
        dispatch(setSessionState(currentSession))
      } */

    }

            // GA-02
            Event(
              false,
              'click_provider_location_dropdown',
              AppendLabel(state.session.CoverKey, state.session.LocKey?.toString(), state.session.AcctKey)
          )
          
  }


  const onCloseAppointment = () => {
    setOpen(false);
  };


  const onAppointmentTypeChange = async (data : AppointmentType) => {
    // setAppointmentTypes(data);   
    const updateSession = {...state.session, TaskKey: data?.TaskKey?.toString()}
    saveSession(updateSession).then(() => {
      dispatch(setSessionState(updateSession))
    })        
    dispatch(setAppointmentType(data))
    dispatch(onAppointmentTypesChange(data)) // throwing 500 DB error
    dispatch(clearProviderData())
    if (!state.embed.LocKey)
    {
       dispatch( getlocationsback(null))
       setSelectedNewLocation(null)
    }
    else
    {
      setPageLoading(true)
      setHasSchedules(false) 
      var locKey : any = state.session.LocKey 
      var availState = state
          availState.session = {...updateSession, AcctKey : null}
      AvailabilityService(availState, locKey).then((res)=> {
          if (res.data[0].providers.length > 0) 
             setHasSchedules(true) 
          dispatch(getAvailabililties(res.data[0]))
          dispatch(setHashforState(res.hash))

          generatePagination(res.data[0])
          setPageLoading(false)      
          // reset LastPage
          const updateSession = {...state.session, LastPage : false}
          dispatch(setSessionState(updateSession))
          
      }).catch((ex) =>
      {
        setPageLoading(false)
      }) 
    }
      // GA-01
      Event(
        false,
        "click_examtype_dropdown_examtype_screen",
        AppendLabel(state.session.CoverKey, state.session.LocKey?.toString(), state.session.AcctKey)
    )
  };

  // const onIdle = (m: google.maps.Map) => {
  //   setZoom(m.getZoom()!);
  //   setCenter(m.getCenter()!.toJSON());
  // };
  const onAppointmentClick = useCallback(
    //  old function
    (id: number, providerId: string) => {
      const data =
        providerWithAppointmentsData.find(
          (x) => x.provider.id === providerId
        ) ?? null;
      dispatch(onSelectProvider(data));
      // setSelectedProvider(
      //   providerWithAppointmentsData.find(
      //     (x) => x.provider.id === providerId
      //   ) ?? null
      // );
      setOpen(true);
      if (isSmallScreen) {
        NavigatePage(dispatch, "next", state, navigate, PageName.AppointmentWindow)
        //navigate("/appointmentwindow");
      }
    },
    [ dispatch, isSmallScreen, navigate]
  );
  const newOnAppointmentClick = useCallback(
    (providerId:string, date: number) => {
      setOpen(true)
      setSelectedAppointment({ providerId, date })
    },
    [state.appointmentData.appointmentTypesDistinct, dispatch, navigate, isSmallScreen,]
  )

  const onClickNext = () => {
    const len = selectLength + 20
    if (totalLength > len) {
      setSelectLength(len)
    }
    if(totalLength < len){
      setIsDisableButton(true);
    }
  };
  const onClickPrev = () => {
    if (selectLength) {
      setSelectLength(selectLength - 20)
      setIsDisableButton(false);
    }

  };

  const renderAppointment = (data: AppointmentType) => {
    return (
      <>
        {data.Name}
      </>
    );
  };
  
  const getAppointmentInputProps = (
    params: AutocompleteRenderInputParams
  ): InputProps => {
    if (!state.availability.getInitialLocation) return params.InputProps;
    const obj = {
      ...params.InputProps,
      startAdornment: (
        <InputAdornment position="start">
        </InputAdornment>
      ),
    };
    return obj;
  };

  const validateAppointmentSelection = (
    option: AppointmentType,
    value: AppointmentType
  ) => {
    return (
      option.TaskKey === value.TaskKey &&
      option.Name === value.Name
    );
  };

  return (
    <>  
    <Container maxWidth={false}>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item flexGrow={1}></Grid>        
            <Grid
              item
              xs={12}
              sm={6}
              md={8}
              lg={8}
              xl={8}
              display={{ xs: "none", sm: "none", md: "none", lg: "block" }}
            >
              <Header
                providerCount={0}
                calendarInfo={``}
                onClickPrev={onClickPrev}
                onClickNext={onClickNext}
                selectLength={selectLength}
                totalLength={totalLength}
                dates={datePaginationList}
                isDisableButton={isDisableButton}
              />              
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={gridSpacing}>
            <Grid
              item
              xs={12}
              sm={5}
              md={8}
              lg={8}
              xl={8}
              display={{ xs: "block", sm: "block", md: "block", lg: "none" }}
            >
              <Header
                providerCount={0}
                calendarInfo={``}
                onClickPrev={onClickPrev}
                onClickNext={onClickNext}
                selectLength={selectLength}
                totalLength={totalLength}
                dates={datePaginationList}
                isDisableButton={isDisableButton}
              />
            </Grid>
            <Grid item xs={12} sm={7} md={4} lg={3} xl={3}>
{/*                  <Practice
                    newAppointmentTypes={state.appointmentData.appointmentTypesDistinct}
                    newLocations={state.appointmentData.appointmentTypesLocation}
                    apoointmentTypes={appointmentData}
                    practiceName={locationName}
                    locations={locationData}
                    onAppointmentTypeChange={onAppointmentTypeChange}
                    selectedAppointmentType={state.appointmentData.appointmentType}
                    loadingPages={availabilityLoading}
                    onSelectedNewLocationData={selectedNewLocation}
                  />  */}            
                <Grid className="grid-flex">     
                  <Grid container spacing={gridSpacing} direction={"column"} className="grid-width"> 
                  <Grid item xs={12} md={12} sm={12} lg={12} sx={{ paddingTop: "0px !important" }} 
                    className="content-width">
                    <Typography
                      variant="h6"
                      fontWeight={"700"}
                      sx={{ 
                        // fontSize: "22px", 
                        lineHeight: "25px", 
                        textAlign: "center",
                        marginTop: "15px"
                      }}
                      className="cover-title"
                    >
                      {state.session.OrgName}
                    </Typography>
                  </Grid>                             
                    {
                    <> 
                      <Grid item xs={12} md={12} sm={12} lg={12} 
                        className="content-width">
                        <Typography
                          variant="subtitle1"
                          fontWeight={"500"}
                          sx={{ 
                            fontSize: "18px", 
                            lineHeight: "24px" , 
                            textAlign: "center" 
                          }}
                        >
                          Appointment Type 
                        </Typography>        
                      </Grid>        
                      <Grid item xs={12} md={12} sm={12} lg={12}
                        sx={{ 
                          paddingBottom: "21px", 
                          borderBottom: "1px solid #999999", 
                          paddingLeft: "0 !important", 
                          marginLeft: "16px" 
                        }}
                        className="content-width"
                      >                          
                        <ColorSelect
                          data={state.appointmentData.appointmentTypesDistinct}
                          getOptionLabel={(item) => item.Name}
                          renderOptions={renderAppointment}
                          selected={state.appointmentData.appointmentType ? state.appointmentData.appointmentType : null }
                          onSelection={onAppointmentTypeChange}
                          size={"small"}
                          placeholder={"Select Appointment"}
                          validateOptionSelected={validateAppointmentSelection}
                        />
                    </Grid>
                  </> } 
                  <Grid item xs={12} md={12} sm={12} lg={12} 
                    className="content-width"
                  >
                   <NewLocation 
                      locations={state.appointmentData.appointmentTypesLocation}
                      selectedLocation= {selectedNewLocation}
                      onLocationSelection = {onNewLocationSelection}      
                    />                        
                  </Grid>   
                  {state.appointmentData.appointmentType?.Name && 
                    <Grid item xs={12} md={12} sm={12} lg={12} 
                      display={{ xs: "none", sm: "none", md: "none", lg: "block" }}
                      className="content-width map-container"
                    >
                      <SimpleMap 
                          locationCords={selectedNewLocation}
                          totalLocations={state.appointmentData.appointmentTypesLocation}
                          onLocationSelect={onNewLocationSelection}
                          />
                    </Grid>
                   }                     
                 </Grid>               
               </Grid> 


            </Grid>

            { 
              hasSchedules && errorMessage.length === 0 ?  
              <Grid item xs={12} sm={6} md={8} lg={9} xl={9}>
              <Grid item xs={12}>
                  <Box sx={{ ml: { xs: 0, sm: 0, md: 0, lg: 5, xl: 20 } }}>
                    <Grid container spacing={gridSpacing}>
                      {
                        state.providers.providersWithAppointment.map((provider: Provider, index) => {
                          if (!state.availability.availabilities?.providers?.includes(provider.AcctKey)) {
                            return
                          }
                          const availableDates = state.availability.availabilities?.dates[provider.AcctKey]
                          return (
                            <Grid item xs={12} key={index}>
                              <NewProviderWithAppointment
                                data={provider}
                                dates={datePaginationList}
                                onAppointmentClick={newOnAppointmentClick}
                                key={`appointment_header_:${index}_provider_${Math.random()}`}
                                totalProviderCount={
                                  state.providers.providersDistinct.length
                                }
                                selectLength={selectLength}
                                currentProviderIndex={index}
                                appointmentType={state.appointmentData.appointmentType}
                              />
                            </Grid>
                          )
                        })
                      }
                    </Grid>
                  </Box>
              </Grid>
            </Grid>
             : 
             <>
                {!pageLoading && state.availability.getInitialLocation && errorMessage.length === 0 &&
                  <Grid item xs={12} sm={6} md={8} lg={9} xl={9}>
                     <div style={{ textAlign : "center"}}>  
                     <Typography variant="h4"
                        fontWeight={"500"}
                        sx={{
                            fontSize: "18px",
                            fontWeight: "600",
                        }} >Please call the office to schedule an appointment : {state.availability.getInitialLocation.PhoneNumberFormated} 
                        </Typography>
                      </div>
                  </Grid>}
                                  
              </> 
            }    
            
            {state.appointmentData.appointmentType?.Name &&
              <Grid item xs={12} sm={7} md={4} lg={3} xl={3} display={{ xs: "none", sm: "block", md: "block", lg: "none" }}>
                <SimpleMap
                  locationCords={selectedNewLocation}
                  totalLocations={state.appointmentData.appointmentTypesLocation}
                  onLocationSelect={onNewLocationSelection}
                />
              </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
      {!isSmallScreen && !pageLoading 
                      && !state.appointmentData.appointmentType?.AcctKey 
                      && (
        <Box sx={{ position: "fixed", top: "28%", left: "70%" }}>
          <CustomWidthTooltip
            message="Please choose your appointment type."
            showTooltip
          />
        </Box>
      )}
      {!isSmallScreen && !pageLoading 
                      && state.appointmentData.appointmentType?.AcctKey 
                      && !state.availability.getInitialLocation
                      &&  (
        <Box sx={{ position: "fixed", top: "28%", left: "70%" }}>
          <CustomWidthTooltip
            message="Please choose your Location."
            showTooltip
          />
        </Box>
      )}
      {!isSmallScreen && (
        <AppointmentDrawer
          open={open}
          onToggle={onCloseAppointment}
          onClickPrev={onClickPrev}
          onClickNext={onClickNext}
          selectedAppointment={selectedAppointment}
          selectLength={selectLength}
        />
      )}
      {isSmallScreen && (
        <AppointmentWindow
          open={open}
          onToggle={onCloseAppointment}
          onClickPrev={onClickPrev}
          onClickNext={onClickNext}
          selectedAppointment={selectedAppointment}
          selectLength={selectLength}
        />
      )}
        {pageLoading === true &&
      <>
          <div className="spinner">
              <Spinner />
          </div>

      </>}
    </Container>
    </>
  );
};

export default LandingPage;
