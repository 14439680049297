// import { useMemo } from "react";
// @mui
import { CssBaseline } from "@mui/material";
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
  responsiveFontSizes,
} from "@mui/material/styles";

//
// import palette from "./palette";
// import shadows from "./shadows";
// import typography from "./typography";
import GlobalStyles from "./globalStyles";
// import customShadows from "./customShadows";

interface IThemeProviderProps {
  children?: React.ReactNode;
}

const ThemeProvider = ({ children }: IThemeProviderProps) => {
  /*   const themeOptions = useMemo(
    () => ({
      shape: { borderRadius: 6 },
      // typography,
      // shadows: shadows(),
      // customShadows: customShadows(),
    }),
    []
  ); */

  const defaultTheme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1024,
        xl: 1536
      }
    }
  });
  const theme = responsiveFontSizes(defaultTheme);
  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeProvider;
