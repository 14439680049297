import { createSlice, PayloadAction} from '@reduxjs/toolkit'
import { SessionModel } from '../../models/Session'

export const initialState:SessionModel ={
    LogKey:null,
    CoverKey:null,
    LocKey:null,
    GroupKey:null,
    AcctKey:null,
    AddKey:null,
    TaskKey:null,
    ApptKey:null,
    ClientIP: '',
    CreatedOn:'',
    CurrentPage:0,
    ApptDateTime:null,
    SkipDemographics:false,
    StandAlone: undefined,
    SkipPatientInsurance:false,
    SkipCustomQuestions :true,
    V4Scenario:1,
    TrackingID: '0',
    LastPage : false,
    EmailAddress : null
  }

export const sessionProvider = createSlice({
    name:'session',
    initialState,
    reducers:{
        setSessionState : (
            state,
            action: PayloadAction<SessionModel | null>
          ) => {
            
            if (action.payload) {
              state.ClientIP = action.payload.ClientIP;
              state.LogKey = action.payload.LogKey;
              state.CreatedOn = action.payload.CreatedOn;
              state.CoverKey = action.payload.CoverKey ? action.payload.CoverKey : state.CoverKey;
              state.LocKey = action.payload.LocKey ? action.payload.LocKey : state.LocKey;
              state.TaskKey = action.payload.TaskKey ? action.payload.TaskKey : state.TaskKey;
              state.AcctKey = action.payload.AcctKey ? action.payload.AcctKey : state.AcctKey;
              state.AddKey= action.payload.AddKey ? action.payload.AddKey : state.AddKey;
              state.OrgName= action.payload.OrgName ? action.payload.OrgName : state.OrgName;
              state.DesktopLogo= action.payload.DesktopLogo ? action.payload.DesktopLogo : state.DesktopLogo;
              state.MobileLogo= action.payload.MobileLogo ? action.payload.MobileLogo : state.MobileLogo;
              state.LastPage= action.payload.LastPage ? action.payload.LastPage : state.LastPage;
              state.EmailAddress = action.payload.EmailAddress ? action.payload.EmailAddress : state.EmailAddress;
            } else state = initialState;
          },
          onAppointmentTypesChange: (state,action)=> {
            if (action.payload) {
                console.log(JSON.stringify(state),'4343')
                // state.AcctKey=action.payload.AcctKey
                state.LocKey=action.payload.LocKey
                state.TaskKey = action.payload.TaskKey
            }
          },
          initState: (state)=> {
                state=initialState
          },
          updateCurrentPage : (state,action)=> {
            if (action.payload) {
                state.CurrentPage = Number(action.payload.CurrentPage) > 0 ? action.payload.CurrentPage : state.CurrentPage;
            }
          },        
    }
})
export const { setSessionState, onAppointmentTypesChange, initState, updateCurrentPage} = sessionProvider.actions
export default sessionProvider.reducer