import { getBaseAPI, getConfigBaseAPI } from "../environments/Environments";
import AppointmentType, { AppointmentTypeData, mapAppointmentTypeData } from "../models/AppointmentType";
import HashReturnObject from "../models/HashReturnObject";
import { mapServiceProviderData, mapSessionData, SessionModel } from "../models/Session";
import axios from "axios";
import { setHashforState } from "../features/hasher/hasherSlice";

import Provider, { ProviderData, mapProviderData } from '../models/Provider';
import { getProvidersData } from "../features/provider/newProviderSlice";

import LocationDetails, { LocationDetailsData, mapLocationData } from "../models/LocationDetails";
import { getAppointmentData, getLocationsData, setAppointmentType } from "../features/appointmentData/appointmentSlice";
import { EcpConfigurationAction, EcpPatientConfiguration } from "../models/EcpPatientConfiguration";
import { setEcpConfiguration } from "../features/embed/embedSlice";



const  InitServices = async (session: SessionModel, dispatch : any, state : any)  => {
       //const dispatch = useAppDispatch();
        var url = await getBaseAPI() + '/api/v1/Patient/getProvider';
        //Provider
        var providerState = {...state.session, LocKey : null, TaskKey : null}
		await axios.post(url, mapServiceProviderData(providerState), {timeout : Number(`${process.env.REACT_APP_REQUEST_TIMEOUT }`)})
		.then(res => {
			var response = res.data

            const providers: Provider[] = [];
            response.data.forEach((provider: ProviderData) => {
                providers.push(mapProviderData(provider));
            });            

            const responseObject: HashReturnObject<Provider> = {
                data: providers,
                hash: response.hash,
                sessionId: response.sessionId
            }

            dispatch(getProvidersData(responseObject))
            //dispatch(setHashforState(responseObject.hash))
		})
		.catch((e) => {
			console.log("request error", e);
            return(false);
		});     

        // Appointment
        url = await getBaseAPI() + '/api/v1/Patient/getAppointmentType';
        var test= mapServiceProviderData(session)
        var appointmentState = {...state.session, LocKey : null}
		await axios.post(url, mapSessionData(appointmentState), {timeout : Number(`${process.env.REACT_APP_REQUEST_TIMEOUT }`)})
		.then(res => {
			var response = res.data

            const appointmentTypes: AppointmentType[] = [];
            response.data.forEach((data: AppointmentTypeData) => {
                appointmentTypes.push(mapAppointmentTypeData(data));
            });
            const responseObject: HashReturnObject<AppointmentType> = {
                data: appointmentTypes,
                hash: response.hash,
                sessionId: response.sessionId
            }

            dispatch(getAppointmentData(responseObject))
            //dispatch(setHashforState(responseObject.hash))
		})
		.catch((e) => {
			console.log("request error", e);
			return(false);
		}); 

        // Location
        var locationState  = {...state.session}
        locationState = {...state.session, TaskKey : null , LocKey : null}
        url = await getBaseAPI() + '/api/v1/Patient/getLocation';
		await axios.post(url, mapServiceProviderData(locationState), {timeout : Number(`${process.env.REACT_APP_REQUEST_TIMEOUT }`)})
		.then(res => {
			var response = res.data

            const locations: LocationDetails[] = [];
            response.data.forEach((data: LocationDetailsData) => {
                locations.push(mapLocationData(data));
            });
            
            const responseObject: HashReturnObject<LocationDetails> = {
                data: locations,
                hash: response.hash,
                sessionId: response.sessionId
            }            

            dispatch(getLocationsData(responseObject.data))
            dispatch(setHashforState(responseObject.hash))
		})
		.catch((e) => {
			console.log("request error", e);
            return(false);
		});     
        
  


        return(true)     
}

export const UpdateEmbededState = async (session: SessionModel, dispatch : any) =>
{
		// ECP Configuration
        var url = await getBaseAPI() + '/api/v1/Patient/getECPPatientConfiguration';
        const sessionData = mapSessionData(session);
        await axios.post(url, sessionData, {timeout : Number(`${process.env.REACT_APP_REQUEST_TIMEOUT }`)})
        .then((res) => {
            var ecpPatientConfiguration = res.data
            const ecpPatientConfigurationModel: EcpPatientConfiguration = {
                IsPatientEnabled: ecpPatientConfiguration.isPatientEnabled,
                IsInsuranceEnabled: ecpPatientConfiguration.isInsuranceEnabled,
                IsDemographicsEnabled: ecpPatientConfiguration.isDemographicsEnabled,
                IsCutomQEnabled: ecpPatientConfiguration.isCutomQEnabled
            }
			const ecpObj: EcpConfigurationAction = {
				CoverKey: null,
				LocKey: null,
				GroupKey: null,
				AcctKey: null,
				TaskKey: null,
				Path: null,                            
				SkipDemographics: ecpPatientConfiguration.isDemographicsEnabled,
				SkipPatientInsurance: ecpPatientConfiguration.isInsuranceEnabled,
				SkipCustomQuestions: ecpPatientConfiguration.isCutomQEnabled,
                SkipPatientInformation : ecpPatientConfiguration.isPatientEnabled,
				TrackingID: null,    
                Stylings : null,     
				Embed: null,                                                       
			}    

			dispatch(setEcpConfiguration(ecpObj))	  
        })
		.catch((e) => {
			console.log("request error", e);
            return(false);
		});                    
}

export const GetCoverKeyInformation = async (locKey : any, coverKey : any) =>
{
    const url_string = window.location.href;

    //var defaultLogo = `${process.env.REACT_APP_DEFAULT_LOGO as string}`;
    var defaultLogo = "";
    var coverName = "";
    var mobileLogo =   "";
    var desktopLogo =  "";    
    var url = await getConfigBaseAPI() + "/api/v1/ECPDashboard/GetCoverProviderInfo?coverKey=" + coverKey + "&locKey=" + locKey;
		
    await axios.get(url, {timeout : Number(`${process.env.REACT_APP_REQUEST_TIMEOUT }`)})
    .then(res => {
        var response = res.data
        if (response)
        {
            coverName = response.orgName
            mobileLogo =  response.mobileLogo ? response.mobileLogo : defaultLogo
            desktopLogo = response.desktopLogo  ? response.desktopLogo : defaultLogo
    }		
    })
    .catch((e) => {
        console.log("request error", e);
    });	

    return [coverName, mobileLogo, desktopLogo] as const;
}


export default  InitServices;