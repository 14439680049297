import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, Grid, Button, TextField } from "@mui/material";
import Spinner from '../../components/spinner/Spinner';
import './patientDemographicsStyle.css';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useNavigate } from "react-router-dom";
import DemographicsService from '../../services/DemographicsService';
import { updateProvider } from "../../features/demographics/demographicsSlice";
import Demographics from "../../models/Demographics";
import DemographicsUserInput, { emptyDemographicsUserInput, mapDemographicsToUserInput, mapUserInputToDemographics, validateDemographicsUserInput } from "../../models/DemographicsUserInput";
import { getProperURL, genDerValidation, PageName, NavigatePage } from "../../utils/Util";
import useApptConfirmNav from "../hooks/useApptConfirmNav";
import { AppendLabel, Event , PageView} from '../../utils/GA';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { setSessionState } from "../../features/session/sessionSlice";
          
          


const phoneValidation = /^[6-9]\d{9}$/
const mobileStart = /^1/;
export const PatientDemographics = () => {
    const [demographics, setDemographics] = useState<DemographicsUserInput>(emptyDemographicsUserInput());
    const [isReady, setIsReady] = useState<boolean>(false);
    const [loading, setLoading] = useState(true);
    const [isDemographicsValid, setIsDemographicsValid] = useState<boolean>(false);
    const [readOnlyAddress1, setReadOnlyAddress1] = useState<boolean>(false);
    const [readOnlyAddress2, setReadOnlyAddress2] = useState<boolean>(false);
    const [readOnlyCity, setReadOnlyCity] = useState<boolean>(false);
    const [readOnlyState, setReadOnlyState] = useState<boolean>(false);
    const [readOnlyZip, setReadOnlyZip] = useState<boolean>(false);
    const [readOnlyCountry, setReadOnlyCountry] = useState<boolean>(false);
    const [readOnlyCel, setReadOnlyCel] = useState<boolean>(false);
    const [newDemographics, setNewDemographics] = useState<Demographics>({} as any)
    const[genderValue,setGenderValue]= useState("");
    const state = useAppSelector(state => state)
    const patientFirstName = state.patient.FirstName;
    const selectTime = state.availability.availability
    const dDate = new Date(selectTime.AppointmentDate).toDateString()
    const dTime = (selectTime.AppointmentDateTime)
    const [capName, setCapName] = useState("");
    const [locationName, setLocationName] = useState("");
    const[selectderGender,setSelectedGender] =useState("");
    const appointmentType = state.appointmentData.appointmentType.Name
    const providerFirstname = state.providers.provider.FirstName
    const ProviderLastName = state.providers.provider.LastName
    const providerDegree = state.providers.provider.Degree
    const patientLastName = state.patient.LastName;
    const patientEmail = state.patient.Email;
    const navigate = useNavigate();
    const TimeConvert = (date: any) => {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ap = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes.toString().padStart(2, '0')
        let mergeTime = hours + ':' + minutes + ' ' + ap;
        return mergeTime;
    }
    TimeConvert(dTime)

    //If appointment confirmation is complete, force user back to the confirmation screen
    useApptConfirmNav();

    let disAbledInput = 'disabled-input';

    const stateAmerica = ["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida",
        "Georgia",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming"]

    const dispatch = useAppDispatch();

    useEffect(() => {
        PageView(
            `pageview_patient_demographics_screen_${AppendLabel(state.session.CoverKey, state.session.LocKey?.toString(), state.session.AcctKey)}`
        )
    },[])

    useEffect(() => {
        const provider = state.providers.providers.find((x) => x.AcctKey == state.availability?.availability.AcctKey);
        DemographicsService.getDemographics({ ...state, session: { ...state.session, AcctKey: provider?.AcctKey } })
            .then((res) => {
                dispatch(updateProvider(res.data[0]));
                setLoading(false)
                setNewDemographics(res.data[0]);
                const mapped = mapDemographicsToUserInput(res.data[0]);

                // Check email if blank from the DB. If true then fill it from with email from the Patient Information Page
                if (mapped.Email.value.trim().length === 0)
                    mapped.Email.value = state.session.EmailAddress ?? ""

                setDemographics(validateDemographicsUserInput(mapped));
                setIsReady(true);
                const genderVal = genDerValidation(Number(res.data[0].Gender));
                setGenderValue(String(genderVal));
                if (mapped.Address1.enabled !== -1 && mapped.Address1.value.trim().length !== 0) setReadOnlyAddress1(true);
                if (mapped.Address2.enabled !== -1 && mapped.Address2.value.trim().length !== 0) setReadOnlyAddress2(true);
                if (mapped.City.enabled !== -1 && mapped.City.value.trim().length !== 0) setReadOnlyCity(true);
                if (mapped.State.enabled !== -1 && mapped.State.value.trim().length !== 0) setReadOnlyState(true);
                if (mapped.Zip.enabled !== -1 && mapped.Zip.value.trim().length !== 0) setReadOnlyZip(true);
                if (mapped.Country.enabled !== -1 && mapped.Country.value.trim().length !== 0) setReadOnlyCountry(true);
                if (mapped.Cel.enabled !== -1 && mapped.Cel.value.trim().length !== 0) setReadOnlyCel(true);
                //dispatch(setSessionState({...state.session,CurrentPage :  PageName.PatientDemographics}))
            }).catch((e) => {
                console.log('request error', e);
            });
        capitalize(patientFirstName);
        // alert(demographics.ReferredBy1.enabled === -1)
   


    }, [state.session.AddKey])

    useEffect(() => {
        PageView(
            `pageview_patient_demographics_screen_${AppendLabel(state.session.CoverKey, state.session.LocKey?.toString(), state.session.AcctKey)}`
        )
    }, [])

    useEffect(() => {
        const validatedDemographics = validateDemographicsUserInput(demographics);
        const isValid = state.demographics != null &&
            (!validatedDemographics.FirstName.error && !validatedDemographics.LastName.error &&
                !validatedDemographics.Home.error && !validatedDemographics.Email.error && !validatedDemographics.DOB.error
                && !validatedDemographics.Cel.error && !validatedDemographics.Address1.error && !validatedDemographics.Address2.error
                && !validatedDemographics.City.error && !validatedDemographics.State.error && !validatedDemographics.Country.error
                && !validatedDemographics.Zip.error && !validatedDemographics.ReferredBy1.error && !validatedDemographics.ReferredBy2.error
                && !validatedDemographics.ReferredBy3.error && !validatedDemographics.ReferredBy4.error && !validatedDemographics.ReferredBy5.error
                && !validatedDemographics.L4DSSN.error);
        setIsDemographicsValid(isValid);
/*         let skipDemographics = demographics.Address1.enabled === -1 &&
            demographics.Address2.enabled === -1 &&
            demographics.City.enabled === -1 &&
            demographics.Zip.enabled === -1 &&
            demographics.ReferredBy1.enabled === -1 &&
            demographics.State.enabled === -1 &&
            demographics.L4DSSN.enabled === -1;
        if (skipDemographics) {
            Navigate('/visioninsurance');
        } */
    }, [
        demographics.FirstName, demographics.LastName, demographics.DOB, demographics.Email, demographics.Home, demographics.Cel,
        demographics.Address1, demographics.Address2, demographics.State, demographics.Zip, demographics.City, demographics.Country,
        demographics.ReferredBy1, demographics.ReferredBy2, demographics.ReferredBy3, demographics.ReferredBy4, demographics.ReferredBy5,demographics.L4DSSN
    ]);

    const updateDemographics = (currentDemographics: any, key: string, value: string): any => {
        return {
            ...currentDemographics,
            [key]: {
                ...currentDemographics[key],
                value: value
            }
        };
    }

    useEffect(() => {
        if (state.availability.getInitialLocation)
            setLocationName(state.availability.getInitialLocation.Name) 
    }, [state.availability.getInitialLocation]);

    const onChangeSetForm = (e: any, key: string) => {
        if (key === "Cel") {
            let onlyDigits = e.target.value.replace(/\D/g, "");
            if(e.target.value.toString().startsWith("1") || e.target.value.toString().startsWith("0")){
                onlyDigits = "";
             
            }
            const updatedDemographics = updateDemographics(demographics, "Cel", onlyDigits);
            setDemographics(validateDemographicsUserInput(updatedDemographics));
        }
        else if(key === "Address1"){
            const onlyNonSpecialChar = e.target.value.replace(/[^\w\s]/gi, "");
            const updatedDemographics = updateDemographics(demographics, "Address1", onlyNonSpecialChar);
            setDemographics(validateDemographicsUserInput(updatedDemographics));
        }
        else if(key === "Address2"){
            const onlyNonSpecialChar = e.target.value.replace(/[^\w\s]/gi, "");
            const updatedDemographics = updateDemographics(demographics, "Address2", onlyNonSpecialChar);
            setDemographics(validateDemographicsUserInput(updatedDemographics));
        }
        else if(key === "City"){
            const onlyNonSpecialChar = e.target.value.replace(/[^\w\s]/gi, "");
            const updatedDemographics = updateDemographics(demographics, "City", onlyNonSpecialChar);
            setDemographics(validateDemographicsUserInput(updatedDemographics));
        }
        else if(key === "Zip"){
            let onlyDigits = e.target.value.replace(/\D/g, "");
            const updatedDemographics = updateDemographics(demographics, "Zip", onlyDigits);
            setDemographics(validateDemographicsUserInput(updatedDemographics));
        }
        else if (key === "L4DSSN") {
            let onlyDigits = e.target.value.replace(/\D/g, "");
            const updatedDemographics = updateDemographics(demographics, "L4DSSN", onlyDigits);
            setDemographics(validateDemographicsUserInput(updatedDemographics));
        }
        else{
            const updatedDemographics = updateDemographics(demographics, key, e.target.value);
            setDemographics(validateDemographicsUserInput(updatedDemographics));
        }
    }

    const onChangeState = (e: any, value: any) => {
        const updatedDemographics = updateDemographics(demographics, "State", e.target.value);
        setDemographics(validateDemographicsUserInput(updatedDemographics));
    }

    const capitalize = (data: any) => {
        
        let capitalizeName = data.charAt(0).toUpperCase() + data.slice(1);
        setCapName(capitalizeName);
    }

    const hideAddressDetails =
        demographics.Address1.enabled === -1 &&
        demographics.Address2.enabled === -1 &&
        demographics.City.enabled === -1 &&
        demographics.State.enabled === -1 &&
        demographics.Zip.enabled === -1 &&
        demographics.Country.enabled === -1;

    const hideContactDetails =
        demographics.Email.enabled === -1 &&
        demographics.Home.enabled === -1 &&
        demographics.Cel.enabled === -1 &&
        demographics.DOB.enabled === -1;

    const hideRefferals =
        demographics.ReferredBy1.enabled === -1 &&
        demographics.ReferredBy2.enabled === -1 &&
        demographics.ReferredBy3.enabled === -1 &&
        demographics.ReferredBy4.enabled === -1 &&
        demographics.ReferredBy5.enabled === -1;

    const renderFirstName = () => {
        return (
            demographics.FirstName.enabled != -1 &&
            <>
                <label className="field-label">First Name</label>
                <div className="error">
                    <>  {demographics.FirstName.error} </>
                    <input placeholder={demographics.FirstName.enabled == 1 ? "FirstName *" : "FirstName "} onChange={(e) => onChangeSetForm(e, 'FirstName')} value={demographics.FirstName.value} className="disabled-input" type="text" maxLength={80} readOnly={true} />
                </div>
            </>
        )

    }

    const renderLastName = () => {
        return (
            demographics.LastName.enabled != -1 &&
            <>
                <label className="field-label">Last Name</label>
                <div className="error">
                    <>  {demographics.LastName.error} </>
                    <input placeholder={demographics.LastName.enabled == 1 ? "LastName *" : "LastName "} onChange={(e) => onChangeSetForm(e, 'LastName')} value={demographics.LastName.value} className="disabled-input" type="text" maxLength={80} readOnly={true} />
                </div>
            </>
        )
    }

    const renderAddress1 = () => {
        if (readOnlyAddress1) {
            return (

                demographics.Address1.enabled != -1 &&
                <>
                    <label className="field-label">Address1</label>
                    <div className="error">
                        <>  {demographics.Address1.error} </>
                        <input placeholder={demographics.Address1.enabled == 1 ? "Address 1 *" : "Address 1 "} onChange={(e) => onChangeSetForm(e, 'Address1')} className={'disabled-input'} value={demographics.Address1.value} type="text" maxLength={50} readOnly={true} />
                    </div>
                </>

            )

        }
        if (demographics.Address1.enabled !== -1) {
            {
                return (
                    demographics.Address1.enabled != -1 &&
                    <>
                        <label className="field-label">Address1</label>
                        <div className="error">
                            <>  {demographics.Address1.error} </>
                            <input placeholder={demographics.Address1.enabled == 1 ? "Address 1 *" : "Address 1 "} onChange={(e) => onChangeSetForm(e, 'Address1')} value={demographics.Address1.value} type="text" maxLength={50} />
                        </div>
                    </>
                )
            }
        }
    }

    const renderAddress2 = () => {
        if (readOnlyAddress2) {
            {
                return (
                    demographics.Address2.enabled != -1 &&
                    <>
                        <label className="field-label">Address2</label>
                        <div className="error">
                            <>  {demographics.Address2.error} </>
                            <input placeholder={demographics.Address2.enabled == 1 ? "Address 1 *" : "Address 1 "} onChange={(e) => onChangeSetForm(e, 'Address1')} className={'disabled-input'} value={demographics.Address2.value} type="text" maxLength={50} readOnly={true} />
                        </div>
                    </>
                )

            }
        }
        if (demographics.Address2.enabled !== -1) {
            {
                return (
                    demographics.Address2.enabled != -1 &&
                    <>
                        <label className="field-label">Address2</label>
                        <div className="error">
                            <>  {demographics.Address2.error} </>
                            <input placeholder={demographics.Address2.enabled == 1 ? "Address 2 *" : "Address 2 "} onChange={(e) => onChangeSetForm(e, 'Address2')} value={demographics.Address2.value} type="text" maxLength={50} />
                        </div>
                    </>
                )

            }
        }

    }

    const renderCity = () => {
        if (readOnlyCity) {
            {
                return (
                    demographics.City.enabled != -1 &&
                    <>
                        <label className="field-label">City</label>
                        <div className="error">
                            <>  {demographics.City.error} </>
                            <input placeholder={demographics.City.enabled == 1 ? "City*" : "City"} onChange={(e) => onChangeSetForm(e, 'City')} value={demographics.City.value} className={'disabled-input'} type="text" maxLength={25} readOnly={true} />
                        </div>
                    </>
                )

            }
        }
        if (demographics.City.enabled !== -1) {
            {
                return (
                    demographics.City.enabled != -1 &&
                    <>
                        <label className="field-label">City</label>
                        <div className="error">
                            <>  {demographics.City.error} </>
                            <input placeholder={demographics.City.enabled == 1 ? "City*" : "City"} onChange={(e) => onChangeSetForm(e, 'City')} value={demographics.City.value} type="text" maxLength={25} />
                        </div>
                    </>
                )

            }
        }
    }

    const renderState = () => {
        if (readOnlyState) {
            {
                return (
                    demographics.State.enabled != -1 &&
                    <>
                        <label className="field-label">State</label>
                        <div className="error">
                            <>  {demographics.State.error} </>
                            <input placeholder={demographics.State.enabled == 1 ? "State*" : "State"} onChange={(e) => onChangeSetForm(e, 'State')} className={'disabled-input'} value={demographics.State.value} type="text" maxLength={80} readOnly={true} />
                        </div>
                    </>
                )

            }
        }
        if (demographics.State.enabled !== -1) {
            return (
                <>
                    <label className="field-label">State</label>
                    <div className="error state-details">
                        <div className="error">
                            <>  {demographics.State.error} </>
                        </div>
                        <select name="languages" onChange={(e) => onChangeState(e, 'State')} id="lang" style={{ paddingBottom: "14px", paddingTop: "14px", fontWeight: "400", width: '100%', borderRadius: '10px' }}  >

                            <option>Please choose one option</option>
                            {stateAmerica.map((option, index) => {
                                return <option key={index} >
                                    {option}
                                </option>
                            })}
                        </select>
                    </div>
                </>
            )
        }
    }

    const renderZip = () => {
        if (readOnlyZip) {
            {
                return (
                    demographics.Zip.enabled != -1 &&
                    <>
                        <label className="field-label">Zip</label>
                        <div className="error">
                            <>  {demographics.Zip.error} </>
                            <input placeholder={demographics.Zip.enabled == 1 ? "Zip*" : "Zip"} onChange={(e) => onChangeSetForm(e, 'Zip')} value={demographics.Zip.value} className={'disabled-input'} type="text" maxLength={12} readOnly={true} />
                        </div>
                    </>
                )

            }
        }
        if (demographics.Zip.enabled !== -1) {
            {
                return (
                    demographics.Zip.enabled != -1 &&
                    <>
                        <label className="field-label">Zip</label>
                        <div className="error">
                            <>  {demographics.Zip.error} </>
                            <input placeholder={demographics.Zip.enabled == 1 ? "Zip*" : "Zip"} onChange={(e) => onChangeSetForm(e, 'Zip')} value={demographics.Zip.value} type="text" maxLength={12} />
                        </div>
                    </>
                )
            }
        }
    }

    const renderCountry = () => {
        if (readOnlyCountry) {
            {
                return (
                    demographics.Country.enabled != -1 &&
                    <>
                        <label className="field-label">Country</label>
                        <div className="error">
                            <>  {demographics.Country.error} </>
                            <input placeholder={demographics.Country.enabled == 1 ? "Country*" : "Country"} onChange={(e) => onChangeSetForm(e, 'Country')} className={'disabled-input'} value={demographics.Country.value} type="text" maxLength={25} readOnly={true} />
                        </div>
                    </>
                )

            }
        }
        if (demographics.Country.enabled !== -1) {
            {
                return (
                    demographics.Country.enabled != -1 &&
                    <>
                        <label className="field-label">Country</label>
                        <div className="error">
                            <>  {demographics.Country.error} </>
                            <input placeholder={demographics.Country.enabled == 1 ? "Country*" : "Country"} onChange={(e) => onChangeSetForm(e, 'Country')} value={demographics.Country.value} type="text" maxLength={25} />
                        </div>
                    </>
                )

            }
        }
    }

    const renderCel = () => {
        if (readOnlyCel) {
            {
                return (
                    demographics.Cel.enabled != -1 &&
                    <>
                        <label className="field-label">Mobile Phone</label>
                        <div className="error">
                            <>  {demographics.Cel.error} </>
                            <input placeholder={demographics.Cel.enabled == 1 ? "Cel*" : "Cel"} onChange={(e) => onChangeSetForm(e, 'Cel')} className={'disabled-input'} value={demographics.Cel.value} type="text" maxLength={10} readOnly={true} />
                        </div>
                    </>
                )

            }
        }
        if (demographics.Cel.enabled !== -1) {
            {
                return (
                    demographics.Country.enabled != -1 &&
                    <>
                        <label className="field-label">Mobile Phone</label>
                        <div className="error">
                            <>  {demographics.Cel.error} </>
                            <input placeholder={demographics.Country.enabled == 1 ? "Cel*" : "Cel"} onChange={(e) => onChangeSetForm(e, 'Cel')} value={demographics.Cel.value} type="text" maxLength={10} />
                        </div>
                    </>
                )

            }
        }
    }

    const renderHome = () => {
        return (
            demographics.Home.enabled != -1 &&
            <>
                <label className="field-label">Phone (home)</label>
                <div className="error">
                    <>  {demographics.Home.error} </>
                    <input placeholder={demographics.Home.enabled == 1 ? "Phone(home)*" : "Phone(home)"} onChange={(e) => onChangeSetForm(e, 'Home')} className="disabled-input" value={demographics.Home.value} type="text" maxLength={80} readOnly={true} />
                </div>
            </>
        )
    }

    const renderEmail = () => {
        return (
            demographics.Email.enabled != -1 &&
            <>
                <label className="field-label">Email</label>
                <div className="error">
                    <>  {demographics.Email.error} </>
                    <input placeholder={demographics.Email.enabled == 1 ? "Email*" : "Email"} onChange={(e) => onChangeSetForm(e, 'Email')} className="disabled-input" value={demographics.Email.value} type="text" maxLength={80} readOnly={true} />
                </div>
            </>
        )
    }

    const renderSSN = () => {
        return (
            demographics.L4DSSN.enabled > 0 &&
            <>
                <label className="field-label">Last 4 of SSN</label>
                <div className="error">
                    <>  {demographics.L4DSSN.error} </>
                    <input placeholder={demographics.L4DSSN.enabled == 1 ? "Last 4 Digits of Social Security Number*" : "Last 4 Digits of Social Security Number"} onChange={(e) => onChangeSetForm(e, 'L4DSSN')} value={demographics.L4DSSN.value} type="text" maxLength={4} />
                </div>
            </>
        )
    }

    const renderReferredBy = () => {
        return (
            demographics.ReferredBy1.enabled != -1 &&
            <>
                <label className="field-label">Referred By</label>
                <div className="error">
                    <>  {demographics.ReferredBy1.error} </>
                    <input placeholder={demographics.ReferredBy1.enabled == 1 ? "Referred By*" : "Referred By"} onChange={(e) => onChangeSetForm(e, 'ReferredBy1')} value={demographics.ReferredBy1.value} type="text" maxLength={80} />
                </div>
            </>
        )
    }

    const renderGender = () => {
        return(
            demographics.Gender.enabled != -1 &&
            <>
                <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
                <div className="error">
                    <>  {demographics.Gender.error} </>
                </div>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={genderValue}
                    onChange={handleGender}
                >
                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                    <FormControlLabel value="other" control={<Radio />} label="Prefer not to answer" />
                </RadioGroup>
            </>
        )
    } 

    const handleGender =(event:any) =>{
        setGenderValue(event.target.value)
        const updatedDemographics = updateDemographics(demographics, "Gender", "1");
        setDemographics(validateDemographicsUserInput(updatedDemographics));
    }

    const checkGender = () => {
        if(genderValue === "male"){
            return  "0"
        }
        else if(genderValue === "female"){
            return  "1"
        }
        else if(genderValue === "other"){
            return  "2"
        }
        else{
            return null
        }
    }

    const handleClick = (data: any) => { 
        setLoading(true)
        setIsDemographicsValid(false);
        const valGender = checkGender();
        let newVal = {
            ...newDemographics,
            FirstName: demographics.FirstName.value,
            Address1: demographics.Address1.value,
            Address2: demographics.Address2.value,
            City: demographics.City.value,
            State: demographics.State.value,
            Zip: demographics.Zip.value,
            Home: demographics.Home.value,
            Cel:demographics.Cel.value,
            Email: demographics.Email.value,
            Country: demographics.Country.value,
            ReferredBy1: demographics.ReferredBy1.value,
            Gender: valGender,
            L4DSSN: demographics.L4DSSN.value,
            
        }
        if (state.demographics) {
            //GA-07
            Event(
                false,
                'click_continue_patient_demo_screen',
                AppendLabel(state.session.CoverKey, state.session.LocKey?.toString(), state.session.AcctKey)
            )
            dispatch(updateProvider(newVal));
            const provider = state.providers.providers.find((x) => x.AcctKey == state.availability?.availability.AcctKey);
            DemographicsService.saveDemographics(newVal,{ ...state.session, AcctKey: provider?.AcctKey })
                .then(() => {
                    setLoading(false)
                    NavigatePage(dispatch, "next", state, navigate, PageName.PatientInsurance)
                   // const url =  getProperURL('/visioninsurance', state.embed)
                   // Navigate(url)                      
                    //Navigate('/visioninsurance') 
                })
                .catch((e) => {
                    setLoading(false)
                    console.error(e);
                });
        }
    }

    return (
        <div className="p-2">
            <Box
                display={"flex"}
                alignItems={"center"}
                className="header-partical"
            >
                <Box flexGrow={1}>
                    <Typography variant="h6"
                        fontWeight={"500"}
                        sx={{
                            fontSize: "16px",
                            fontWeight: "600",
                        }} >
                        {locationName}
                    </Typography>
                </Box>
                <Box sx={{}}>
                    <Typography
                        variant="h6"
                        fontWeight={"500"}
                        sx={{
                            fontSize: "16px",
                            fontWeight: "600",
                        }}
                    >
                        <div>{dDate} - {TimeConvert(dTime)}</div>
                     </Typography>
                </Box>
            </Box>
            <Box borderBottom={"0.5px solid #979797 !important"} className="Box-p">
                <div className="Typogrphy-pb title-name"><b>{appointmentType}</b><span className="patient-name-title"> with <b>{providerFirstname} {ProviderLastName},{providerDegree}</b></span></div>
            </Box>
            <Box borderBottom={"0.5px solid #979797 !important"} className="Box-p">
                <div className="Typogrphy-pb title-name">Patient:<b>{" " + capName + " " + patientLastName}</b>  <span className="patient-name-title"> &nbsp;&nbsp;&nbsp;Email: <b>{patientEmail}</b></span></div>
            </Box>
            <form className="form-div demographic-form-container">
                <Box borderBottom={"0.5px solid #979797 !important"} className="Box-p patient-details-wrapper">
                    <Typography className="Typogrphy-p">
                        <Typography
                            variant="h5"
                            fontWeight={"500"}
                            sx={{
                                fontSize: "20px",
                                fontWeight: "600",
                                lineHeight: "27.24px",
                                margin: "0px 0px",
                            }}
                            className="main-heading-title"
                        >
                            Please enter additional patient information below:
                        </Typography>
                        <br />
                        <div className="form-control">
                            {
                                renderFirstName()
                            }
                        </div>
                        <div className="form-control">
                            {
                                renderLastName()
                            }
                        </div>
                        <div className="form-control">
                            {
                                renderEmail()
                            }
                        </div>
                        <div className="form-control">
                            {
                                renderSSN()
                            }
                        </div>
                        <div className="form-control">
                            {
                               renderGender()
                            }
                        </div>
                        
                        <div className="form-control">
                            {!hideAddressDetails &&
                                <>
                                    {
                                        renderAddress1()
                                    }
                                </>
                            }
                        </div>
                        <div className="form-control">
                            {!hideAddressDetails &&
                                <>
                                    {
                                        renderAddress2()
                                    }
                                </>
                            }
                        </div>
                        <div className="parent-control">
                            <div className="form-control form-city ">
                                {!hideAddressDetails &&
                                    <>
                                        {
                                            renderCity()
                                        }
                                    </>
                                }
                            </div>
                            <div className="form-control form-state">
                                {renderState()}
                            </div>
                            <div className="form-control form-zip">
                                {!hideAddressDetails &&
                                    <>
                                        {
                                            renderZip()
                                        }
                                    </>
                                }
                            </div>
                            <div className="form-control form-country">
                                {!hideAddressDetails &&
                                    <>
                                        {
                                            renderCountry()
                                        }
                                    </>
                                }
                            </div>
                        </div>
                        <div className="form-control parent-control">
                            {!hideContactDetails &&
                                <>
                                    <div className="form-control p-width">
                                        {

                                            renderHome()
                                        }
                                    </div>
                                </>
                            }
                            {!hideContactDetails &&
                                <>
                                <div className="form-control p-width">
                                    {
                                        renderCel()
                                    }
                                </div>
                                </>
                            }
                        </div>
                    <div className="form-control">
                        {!hideRefferals &&
                            <>
                                {renderReferredBy()}
                            </>
                        }
                    </div>
                </Typography>
            </Box>
            <Box>
                <Grid className="text-continue">
                    <span>We always ensure <b> your data is secure</b> and <b>never share </b> your personal information with any third parties</span>
                    { 
                        <Button variant="contained" style={{backgroundColor : "var(--fpc-blue)"}}  disabled={!isDemographicsValid} onClick={handleClick} >Continue</Button>
                    }
                </Grid>
            </Box>
        </form>
            {loading === true &&
                <>
                    <div className="spinner">
                        <Spinner />
                    </div>

                </>}
        </div >
    )
}

