import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, Button, Typography, Autocomplete, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import InsuranceTypeService from '../../services/InsuranceTypeService';
import InsuranceType from '../../models/InsuranceType';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { AppContext, AppContextType } from "../../App";
import PatientInsuranceService from '../../services/PatientInsuranceService';
import { updateNewPatientInsurance } from "../../features/patientInsurance/patientInsuranceSlice";
import { createSamplePatientInsurance } from "../../models/PatientInsurance";
import PatientInsurance from "../../models/PatientInsurance";
import PatientInsuranceUserInput, { emptyPatientInsuranceUserInput, mapPatientInsuranceToUserInput, mapUserInputToPatientInsurance, validatePatientInsuranceUserInput } from '../../models/PatientInsuranceUserInput';
import Spinner from '../../components/spinner/Spinner';
import { NavigatePage, PageName, getProperURL } from "../../utils/Util";
import useApptConfirmNav from "../hooks/useApptConfirmNav";
import { AppendLabel, PageView, Event } from '../../utils/GA';
import {setSessionState } from "../../features/session/sessionSlice";


function VisionInsurance() {
    const [insuranceTypes, setInsuranceTypes] = useState<InsuranceType[]>([]);
    const [isReady, setIsReady] = useState<boolean>(false);
    const [hideVisionInsurance, setHideVisionInsurance] = useState<boolean>(false);
    const [hideMedicalInsurance, setHideMedicalInsurance] = useState<boolean>(false);
    const [patientInsurance, setPatientInsurance] = useState<PatientInsuranceUserInput>(emptyPatientInsuranceUserInput());
    const [hideVisionOtherInsurance, setHideVisionOtherInsurance] = useState<boolean>(true);
    const [hideMedicalOtherInsurance, setHideMedicalOtherInsurance] = useState<boolean>(true);
    const [visionDOB, setVisionDOB] = useState<string | null | undefined>("");
    const [medicalDOB, setMedicalDOB] = useState<string | null | undefined>("");
    const state = useAppSelector(state => state)
    const selectTime = state.availability.availability
    const providerName = state.appointmentData.appointmentType.Name
    const providerFirstname = state.providers.provider.FirstName
    const ProviderLastName = state.providers.provider.LastName
    const providerDegree = state.providers.provider.Degree
    const [locationName, setLocationName] = useState("");
    const dDate = new Date(selectTime.AppointmentDate).toDateString()
    const dTime = (selectTime.AppointmentDateTime)
    const patientFirstName = state.patient.FirstName;
    const patientLastName = state.patient.LastName;
    const patientEmail = state.patient.Email;
    const [capName, setCapName] = useState("");
    const [valueVision, setValueVision] = useState("");
    const [isInsuranceValid, setInsuranceValid] = useState(false);
    const [loading, setLoading] = useState(true);
    const [visionRelation, setVisionRelation] = useState('');
    const [medicalRelation, setMedicalRelation] = useState('');
    const [valMonth, setValMonth] = useState<string | undefined>("");
    const [valDay, setValDay] = useState<string | undefined>();
    const [valYear, setValYear] = useState<string | undefined>();
    const [medMonth, setMedMonth] = useState<string | undefined>("");
    const [medDay, setMedDay] = useState<string | undefined>();
    const [medYear, setMedYear] = useState<string | undefined>();
    const [newVissionInsurance, setNewVissionInsurance] = useState<PatientInsurance>({} as any);
    const [errorVisionMonth, setErrorVisionMonth] = useState("");
    const [errorVisionDay, setErrorVisionDay] = useState("");
    const [errorVisionYear, setErrorVisionYear] = useState("");
    const [errorMedicalMonth, setErrorMedicalMonth] = useState("");
    const [errorMedicalDay, setErrorMedicalDay] = useState("");
    const [errorMedicalYear, setErrorMedicalYear] = useState("");
    const [reqVisionMonth, setReqVisionMonth] = useState("")
    const [reqVisionYear, setReqVisionYear] = useState("")
    const [reqVisionDay, setReqVisionDay] = useState("")
    const [reqMedicalMonth, setReqMedicalMonth] = useState("")
    const [reqMedicalYear, setReqMedicalYear] = useState("")
    const [reqMedicalDay, setReqMedicalDay] = useState("")
    const dispatch = useAppDispatch();
    // let dobInput = new Date();
    // let currentDate = new Date()
    const Today = new Date();
    // let dobInput = new Date(valYear, validatemonth - 1, validateday);
    let currentDate = new Date()
    const navigate = useNavigate()

    //If appointment confirmation is complete, force user back to the confirmation screen
    useApptConfirmNav();

    useEffect(() => {
        PageView(
            `pageview_vision_insurance_screen_${AppendLabel(state.session.CoverKey, state.session.LocKey?.toString(), state.session.AcctKey)}`
        )
    },[])


    useEffect(() => {
        InsuranceTypeService.getInsuranceType(state.session)
            .then((response: InsuranceType[]) => {
                setInsuranceTypes(response);
                //dispatch(setSessionState({...state.session,CurrentPage :  PageName.PatientInsurance}))
            });
        capitalize(patientFirstName);
    }, []);

    useEffect(() => {
        setLoading(true)
        PatientInsuranceService.getPatientInsurance({ ...state })
            .then((response) => {
                const patientInsuranceData = response.data[0];
                setLoading(false)
                const updateSession = { ...state.session, AddKey: response.data[0].AddKey?.toString() }
                setNewVissionInsurance(patientInsuranceData);
                dispatch(updateNewPatientInsurance(updateSession));
                const Dob = state.demographics.demographics.DOB;
                const month = Dob?.toLocaleString('default', { month: '2-digit' });
                const day = Dob?.toLocaleString('default', { day: '2-digit' });
                const year = Dob?.toLocaleString('default', { year: 'numeric' });
                setValMonth(month);
                setValDay(day);
                setValYear(year);
                setMedMonth(month)
                setMedDay(day);
                setMedYear(year);
                // let dobInput = new Date(year?, month? - 1, day);




                setVisionDOB(patientInsuranceData.VisionInsuranceSubscriberDOB)
                // if(mDob.getFullYear() < Today.getFullYear() - 100){
                //     patientInsuranceData.MedicalInsuranceSubscriberDOB = DobString;
                // }
                setMedicalDOB(patientInsuranceData.MedicalInsuranceSubscriberDOB)

                if (patientInsuranceData.VisionInsuranceCarrier?.toLowerCase() === "no insurance") {
                    // Initialize the Form
                    setHideVisionInsurance(true)
                    patientInsurance.VisionInsuranceSubscriberID.value = ""
                    patientInsurance.VisionInsuranceSubscriberName.value="";
                    patientInsurance.VisionInsuranceOthersName.value="";
                    patientInsurance.VisionInsuranceCarrierServiceNum.value ="";
                    patientInsurance.VisionInsuranceGroupNumber.value="";

                }
                else if (patientInsuranceData.VisionInsuranceCarrier?.toLowerCase() === "other insurance") {
                    setHideVisionOtherInsurance(false)
                }


                if (patientInsuranceData.MedicalInsuranceCarrier?.toLowerCase() === "no insurance") {
                    // Initialize the Form
                    setHideMedicalInsurance(true)
                    patientInsurance.MedicalInsuranceSubscriberID.value = ""
                    patientInsurance.MedicalInsuranceSubscriberName.value="";
                    patientInsurance.MedicalInsuranceOthersName.value="";
                    patientInsurance.MedicalInsuranceCarrierServiceNum.value ="";
                    patientInsurance.MedicalInsuranceGroupNumber.value="";

                }
                if (patientInsuranceData.MedicalInsuranceCarrier?.toLowerCase() === "other insurance") {
                    setHideMedicalOtherInsurance(false)
                }

                const patientInsuranceUpdate = mapPatientInsuranceToUserInput(patientInsuranceData);
                setPatientInsurance(validatePatientInsuranceUserInput(patientInsuranceUpdate));
                setIsReady(true);

            });
    }, []);
    const CheckDOB = () => {
        if (patientInsurance.VisionInsuranceSubscriberDOB.enabled === 1) {
            if (valMonth === "") {
                setReqVisionMonth("DOB*")
            }
            if (valYear === "") {
                setReqVisionYear("DOB*")
            }
            if (valDay === "") {
                setReqVisionDay("DOB*")
            }
        }
        else {
            setReqVisionMonth("")
            setReqVisionYear("")
            setReqVisionDay("")
        }
        if (patientInsurance.MedicalInsuranceSubscriberDOB.enabled === 1) {
            if(medMonth === "")
            {
                setReqMedicalMonth("DOB*")
            }
            if(medYear === ""){
                setReqMedicalYear("DOB*")
            }
            if(medDay === ""){
                setReqMedicalDay("DOB*")
            }
        }
        else {
            setReqMedicalMonth("")
            setReqMedicalYear("")
            setReqMedicalDay("")
        }

    }
    useEffect(() => {
        let year = valYear as any;
        let month =valMonth as any;
        let days = valDay as any
        let dobInput = new Date(year, month - 1, days);
        let currentDate = new Date()

        const statePatientInsurance = state.patientInsurance ?? createSamplePatientInsurance;
        const validatedPatientInsurance = validatePatientInsuranceUserInput(patientInsurance);
        const isValidVision = (state.patientInsurance != null &&
            (!validatedPatientInsurance.VisionInsuranceCarrier.error &&
                !validatedPatientInsurance.VisionInsuranceOthersName.error &&
                !validatedPatientInsurance.VisionInsuranceCarrierServiceNum.error &&
                !validatedPatientInsurance.VisionInsuranceGroupNumber.error &&
                !validatedPatientInsurance.VisionInsuranceRelationToPatient.error &&
                !validatedPatientInsurance.VisionInsuranceSubscriberDOB.error &&
                !validatedPatientInsurance.VisionInsuranceSubscriberID.error
                // !validatedPatientInsurance.VisionInsuranceSubscriberName.error
            )) || hideVisionInsurance;

        const isValidMedical = (state.patientInsurance != null &&
            (
                !validatedPatientInsurance.MedicalInsuranceCarrier.error &&
                !validatedPatientInsurance.MedicalInsuranceOthersName.error &&
                !validatedPatientInsurance.MedicalInsuranceCarrierServiceNum.error &&
                !validatedPatientInsurance.MedicalInsuranceGroupNumber.error &&
                !validatedPatientInsurance.MedicalInsuranceRelationToPatient.error &&
                !validatedPatientInsurance.MedicalInsuranceSubscriberDOB.error &&
                !validatedPatientInsurance.MedicalInsuranceSubscriberID.error
                // !validatedPatientInsurance.MedicalInsuranceSubscriberName.error
            )) || hideMedicalInsurance;

            const isValidDOB = (valDay !== "" && valMonth !== "" && valYear !== "" && medDay !== "" && medMonth !== "" && medYear !== "");
            const isValidDOBError =(!errorMedicalDay && !errorMedicalMonth && !errorMedicalYear && !errorVisionDay && !errorVisionDay && !errorVisionYear);

        const isValid = isValidVision && isValidMedical && isValidDOB && isValidDOBError;
    
        CheckDOB()
        setInsuranceValid(isValid);
        const patientInsuranceData = mapUserInputToPatientInsurance(patientInsurance, statePatientInsurance, isInsuranceValid);
      

    }, [
        patientInsurance.VisionInsuranceCarrier.value,
        patientInsurance.VisionInsuranceOthersName.value,
        patientInsurance.VisionInsuranceCarrierServiceNum.value,
        patientInsurance.VisionInsuranceGroupNumber.value,
        patientInsurance.VisionInsuranceRelationToPatient.value,
        patientInsurance.VisionInsuranceSubscriberDOB.value,
        patientInsurance.VisionInsuranceSubscriberID.value,
        patientInsurance.VisionInsuranceSubscriberName.value,
        patientInsurance.MedicalInsuranceCarrier.value,
        patientInsurance.MedicalInsuranceOthersName,
        patientInsurance.MedicalInsuranceCarrierServiceNum.value,
        patientInsurance.MedicalInsuranceGroupNumber.value,
        patientInsurance.MedicalInsuranceRelationToPatient.value,
        patientInsurance.MedicalInsuranceSubscriberDOB.value,
        patientInsurance.MedicalInsuranceSubscriberID.value,
        patientInsurance.MedicalInsuranceSubscriberName.value,
        valMonth, valYear, valDay,medMonth,medYear,medDay
    ]);
    useEffect(() => {
        if (state.availability.getInitialLocation)
            setLocationName(state.availability.getInitialLocation.Name)
    }, [state.availability.getInitialLocation]);

    const updatePatientInsurance = (patientInsurance: any, key: string, value: string): any => {
        return {
            ...patientInsurance,
            [key]: {
                ...patientInsurance[key],
                value: value
            }
        };
    }
    const onChangeSetForm = (e: any, key: string) => {


        if (key === "VisionInsuranceCarrierServiceNum") {
            let onlyDigits = e.target.value.replace(/\D/g, "");
            if (e.target.value.toString().startsWith("1") || e.target.value.toString().startsWith("0")) {
                onlyDigits = "";

            }
            const patientInsuranceUpdate = updatePatientInsurance(patientInsurance, "VisionInsuranceCarrierServiceNum", onlyDigits);
            setPatientInsurance(validatePatientInsuranceUserInput(patientInsuranceUpdate));


        }
        else if (key === "VisionInsuranceGroupNumber") {
            const onlyNonSpecialChar = e.target.value.replace(/[^\w\s]/gi, "");
            patientInsurance.VisionInsuranceGroupNumber.value = onlyNonSpecialChar;
            const patientInsuranceUpdate = updatePatientInsurance(patientInsurance, "VisionInsuranceGroupNumber", onlyNonSpecialChar);
            setPatientInsurance(validatePatientInsuranceUserInput(patientInsuranceUpdate));
        }
        else if (key === "VisionInsuranceSubscriberID") {
            const onlyNonSpecialChar = e.target.value.replace(/[^\w\s]/gi, "");
            patientInsurance.VisionInsuranceSubscriberID.value = onlyNonSpecialChar;
            const patientInsuranceUpdate = updatePatientInsurance(patientInsurance, "VisionInsuranceSubscriberID", onlyNonSpecialChar);
            setPatientInsurance(validatePatientInsuranceUserInput(patientInsuranceUpdate));
        }
        else if (key === "VisionInsuranceOthersName") {
            const onlyNonSpecialChar = e.target.value.replace(/[^\w\s]/gi, "");
            patientInsurance.VisionInsuranceOthersName.value = onlyNonSpecialChar;
            const patientInsuranceUpdate = updatePatientInsurance(patientInsurance, "VisionInsuranceOthersName", onlyNonSpecialChar);
            setPatientInsurance(validatePatientInsuranceUserInput(patientInsuranceUpdate));
        }


        else if (key === "MedicalInsuranceGroupNumber") {
            const onlyNonSpecialChar = e.target.value.replace(/[^\w\s]/gi, "");
            patientInsurance.MedicalInsuranceGroupNumber.value = onlyNonSpecialChar;
            const patientInsuranceUpdate = updatePatientInsurance(patientInsurance, "MedicalInsuranceGroupNumber", onlyNonSpecialChar);
            setPatientInsurance(validatePatientInsuranceUserInput(patientInsuranceUpdate));
        }
        else if (key === "MedicalInsuranceSubscriberID") {
            const onlyNonSpecialChar = e.target.value.replace(/[^\w\s]/gi, "");
            patientInsurance.MedicalInsuranceSubscriberID.value = onlyNonSpecialChar;
            const patientInsuranceUpdate = updatePatientInsurance(patientInsurance, "MedicalInsuranceSubscriberID", onlyNonSpecialChar);
            setPatientInsurance(validatePatientInsuranceUserInput(patientInsuranceUpdate));
        }
        else if (key === "MedicalInsuranceCarrierServiceNum") {
            let onlyNonSpecialChar = e.target.value.replace(/[^\w\s]/gi, "");
            if (e.target.value.toString().startsWith("1") || e.target.value.toString().startsWith("0")) {
                onlyNonSpecialChar = "";

            }
            patientInsurance.MedicalInsuranceCarrierServiceNum.value = onlyNonSpecialChar;
            const patientInsuranceUpdate = updatePatientInsurance(patientInsurance, "MedicalInsuranceCarrierServiceNum", onlyNonSpecialChar);
            setPatientInsurance(validatePatientInsuranceUserInput(patientInsuranceUpdate));
        }
        else if (key === "MedicalInsuranceOthersName") {
            const onlyNonSpecialChar = e.target.value.replace(/[^\w\s]/gi, "");
            patientInsurance.MedicalInsuranceOthersName.value = onlyNonSpecialChar;
            const patientInsuranceUpdate = updatePatientInsurance(patientInsurance, "MedicalInsuranceOthersName", onlyNonSpecialChar);
            setPatientInsurance(validatePatientInsuranceUserInput(patientInsuranceUpdate));
        }

        else {
            const patientInsuranceUpdate = updatePatientInsurance(patientInsurance, key, e.target.value);
            setPatientInsurance(validatePatientInsuranceUserInput(patientInsuranceUpdate));
        }


    }
    const insuranceTypeOptions = insuranceTypes.map((insuranceType) => {
        return {
            label: insuranceType?.InsName?.toString() ?? '',
            value: insuranceType?.InsName?.toString() ?? ''
        };
    });

    const relationToPatient = ['Self', 'Spouse', 'Parent', 'Other'];
    const relationToPatientOptions = relationToPatient.map((relation) => {
        return {
            label: relation.toString() ?? '',
            value: relation.toString() ?? ''
        };
    });
    const TimeConvert = (date: any) => {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ap = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes.toString().padStart(2, '0')
        let mergeTime = hours + ':' + minutes + ' ' + ap;
        return mergeTime;
    }
    TimeConvert(dTime);

    const onChangVisionForm = (e: any, value: any) => {
        // setValueVision(value.value.toString());
        if (value.value.toString().toLowerCase() === "no insurance") {
            // Initialize the Form
            setHideVisionInsurance(true)
            let patientInsuranceUpdates = updatePatientInsurance(patientInsurance, 'VisionInsuranceSubscriberID', '');
            patientInsuranceUpdates = updatePatientInsurance(patientInsuranceUpdates, 'VisionInsuranceOthersName', '');
            patientInsuranceUpdates = updatePatientInsurance(patientInsuranceUpdates, 'VisionInsuranceSubscriberName', '');
            patientInsuranceUpdates = updatePatientInsurance(patientInsuranceUpdates, 'VisionInsuranceCarrierServiceNum', '');
            //   patientInsuranceUpdates = updatePatientInsurance(patientInsuranceUpdates, 'VisionInsuranceSubscriberDOB', visionDOB);     
            patientInsuranceUpdates = updatePatientInsurance(patientInsuranceUpdates, 'VisionInsuranceGroupNumber', '');
            patientInsuranceUpdates = updatePatientInsurance(patientInsuranceUpdates, value.toString().toLowerCase(), value.value.toString());
            // patientInsuranceUpdates = updatePatientInsurance(patientInsurance, "VisionInsuranceCarrier", value.value.toString());
            setPatientInsurance(patientInsuranceUpdates);
            setHideVisionOtherInsurance(true)
            const patientInsuranceUpdate = updatePatientInsurance(patientInsurance, "VisionInsuranceCarrier", value.value.toString());
            setPatientInsurance(validatePatientInsuranceUserInput(patientInsuranceUpdate));
        }
        else if (value.value.toString().toLowerCase() !== "no insurance") {
            if (value.value.toString().toLowerCase() === "other insurance")
                setHideVisionOtherInsurance(false)
            else
                setHideVisionOtherInsurance(true)

            setHideVisionInsurance(false)
            const patientInsuranceUpdate = updatePatientInsurance(patientInsurance, "VisionInsuranceCarrier", value.value.toString());
            setPatientInsurance(validatePatientInsuranceUserInput(patientInsuranceUpdate));
        }

    }
    const onChangMedicalForm = (e: any, value: any) => {
        if (value.value.toString().toLowerCase() === "no insurance") {
            setHideMedicalInsurance(true)
            let patientInsuranceUpdates = updatePatientInsurance(patientInsurance, 'MedicalInsuranceSubscriberID', '');
            patientInsuranceUpdates = updatePatientInsurance(patientInsuranceUpdates, 'MedicalInsuranceOthersName', '');
            patientInsuranceUpdates = updatePatientInsurance(patientInsuranceUpdates, 'MedicalInsuranceSubscriberName', '');
            patientInsuranceUpdates = updatePatientInsurance(patientInsuranceUpdates, 'MedicalInsuranceCarrierServiceNum', '');
            //   patientInsuranceUpdates = updatePatientInsurance(patientInsuranceUpdates, 'MedicalInsuranceSubscriberDOB', medicalDOB);     
            patientInsuranceUpdates = updatePatientInsurance(patientInsuranceUpdates, 'MedicalInsuranceGroupNumber', '');
            patientInsuranceUpdates = updatePatientInsurance(patientInsuranceUpdates, value.value.toString(), e.target.value);
            setPatientInsurance(patientInsuranceUpdates);
            setHideMedicalOtherInsurance(true)
            const patientInsuranceUpdate = updatePatientInsurance(patientInsurance, "MedicalInsuranceCarrier", value.value.toString());
            setPatientInsurance(validatePatientInsuranceUserInput(patientInsuranceUpdate));
        }
        else if (value.value.toString().toLowerCase() !== "no insurance") {
            if (value.value.toString().toLowerCase() === "other insurance")
                setHideMedicalOtherInsurance(false)
            else
                setHideMedicalOtherInsurance(true)

            setHideMedicalInsurance(false)
            const patientInsuranceUpdate = updatePatientInsurance(patientInsurance, "MedicalInsuranceCarrier", value.value.toString());
            setPatientInsurance(validatePatientInsuranceUserInput(patientInsuranceUpdate));
        }

    }

    const onChangeVisionRelation = (e: any, value: any) => {
        const patientInsuranceUpdate = updatePatientInsurance(patientInsurance, "VisionInsuranceRelationToPatient", value.value.toString());
        setPatientInsurance(validatePatientInsuranceUserInput(patientInsuranceUpdate));
    }

    const onChangeMedicalRelation = (e: any, value: any) => {
        const patientInsuranceUpdate = updatePatientInsurance(patientInsurance, "MedicalInsuranceRelationToPatient", value.value.toString());
        setPatientInsurance(validatePatientInsuranceUserInput(patientInsuranceUpdate));
    }


    // const onChangeDropdownSetForm = (e: any, key: string) => {
    const onChangeDropdownSetForm = (event: any, value: any) => {
        if (value.value.toString() === "VisionInsuranceRelationToPatient" || value.value.toString() === "MedicalInsuranceRelationToPatient") {
            const patientInsuranceUpdate = updatePatientInsurance(patientInsurance, value.value.toString().toLowerCase(), event);
            setPatientInsurance(validatePatientInsuranceUserInput(patientInsuranceUpdate));
        }

        if (event.toString().toLowerCase() === "no insurance" && value.value.toString() === "VisionInsuranceCarrier") {
            // Initialize the Form
            setHideVisionInsurance(true)
            let patientInsuranceUpdates = updatePatientInsurance(patientInsurance, 'VisionInsuranceSubscriberID', '');
            patientInsuranceUpdates = updatePatientInsurance(patientInsuranceUpdates, 'VisionInsuranceOthersName', '');
            patientInsuranceUpdates = updatePatientInsurance(patientInsuranceUpdates, 'VisionInsuranceSubscriberName', '');
            patientInsuranceUpdates = updatePatientInsurance(patientInsuranceUpdates, 'VisionInsuranceCarrierServiceNum', '');
            //   patientInsuranceUpdates = updatePatientInsurance(patientInsuranceUpdates, 'VisionInsuranceSubscriberDOB', visionDOB);     
            patientInsuranceUpdates = updatePatientInsurance(patientInsuranceUpdates, 'VisionInsuranceGroupNumber', '');
            patientInsuranceUpdates = updatePatientInsurance(patientInsuranceUpdates, value.toString().toLowerCase(), event);
            setPatientInsurance(patientInsuranceUpdates);
            setHideVisionOtherInsurance(true)
        }
        else if (event.toString().toLowerCase() !== "no insurance" && value.value.toString() === "VisionInsuranceCarrier") {
            if (event.toString().toLowerCase() === "other insurance")
                setHideVisionOtherInsurance(false);
            else
                setHideVisionOtherInsurance(true)

            setHideVisionInsurance(false)
            const patientInsuranceUpdate = updatePatientInsurance(patientInsurance, value.toString(), event);
            setPatientInsurance(validatePatientInsuranceUserInput(patientInsuranceUpdate));
        }

        if (event.toString().toLowerCase() === "no insurance" && value.value.toString() === "MedicalInsuranceCarrier") {
            setHideMedicalInsurance(true)
            let patientInsuranceUpdates = updatePatientInsurance(patientInsurance, 'MedicalInsuranceSubscriberID', '');
            patientInsuranceUpdates = updatePatientInsurance(patientInsuranceUpdates, 'MedicalInsuranceOthersName', '');
            patientInsuranceUpdates = updatePatientInsurance(patientInsuranceUpdates, 'MedicalInsuranceSubscriberName', '');
            patientInsuranceUpdates = updatePatientInsurance(patientInsuranceUpdates, 'MedicalInsuranceCarrierServiceNum', '');
            //   patientInsuranceUpdates = updatePatientInsurance(patientInsuranceUpdates, 'MedicalInsuranceSubscriberDOB', medicalDOB);     
            patientInsuranceUpdates = updatePatientInsurance(patientInsuranceUpdates, 'MedicalInsuranceGroupNumber', '');
            patientInsuranceUpdates = updatePatientInsurance(patientInsuranceUpdates, value.value.toString(), event);
            setPatientInsurance(patientInsuranceUpdates);
            setHideMedicalOtherInsurance(true)
        }
        else if (event.toString().toLowerCase() !== "no insurance" && value.value.toString() === "MedicalInsuranceCarrier") {
            if (event.toString().toLowerCase() === "other insurance")
                setHideMedicalOtherInsurance(false)
            else
                setHideMedicalOtherInsurance(true)

            setHideMedicalInsurance(false)
            const patientInsuranceUpdate = updatePatientInsurance(patientInsurance, value.value.toString(), event);
            setPatientInsurance(validatePatientInsuranceUserInput(patientInsuranceUpdate));
        }

    }
    const hideVisionInsuranceSection =
        patientInsurance.VisionInsuranceCarrier.enabled == -1 &&
        patientInsurance.VisionInsuranceCarrierServiceNum.enabled == -1 &&
        patientInsurance.VisionInsuranceGroupNumber.enabled == -1 &&
        patientInsurance.VisionInsuranceRelationToPatient.enabled == -1 &&
        patientInsurance.VisionInsuranceSubscriberDOB.enabled == -1 &&
        patientInsurance.VisionInsuranceSubscriberID.enabled == -1 &&
        patientInsurance.VisionInsuranceSubscriberName.enabled == -1

    const hideMedicalInsuranceSection =
        patientInsurance.MedicalInsuranceCarrier.enabled == -1 &&
        patientInsurance.MedicalInsuranceCarrierServiceNum.enabled == -1 &&
        patientInsurance.MedicalInsuranceGroupNumber.enabled == -1 &&
        patientInsurance.MedicalInsuranceRelationToPatient.enabled == -1 &&
        patientInsurance.MedicalInsuranceSubscriberDOB.enabled == -1 &&
        patientInsurance.MedicalInsuranceSubscriberID.enabled == -1 &&
        patientInsurance.MedicalInsuranceSubscriberName.enabled == -1

    const handleClick = (data: any) => {
        setLoading(true)
        setInsuranceValid(false);
        let strValDate = valMonth as any + valDay as any + valYear as any;
        let visionDate = new Date(strValDate.replace(/(\d\d)(\d\d)(\d\d\d\d)/, "$3-$1-$2"));
        let strMedDate = medMonth as any + medDay as any + medYear as any;
        let medicalDate = new Date(strMedDate.replace(/(\d\d)(\d\d)(\d\d\d\d)/, "$3-$1-$2"));

        let newVal = {
            ...newVissionInsurance,
            VisionInsuranceCarrier: patientInsurance.VisionInsuranceCarrier.value,
            VisionInsuranceSubscriberID: patientInsurance.VisionInsuranceSubscriberID.value,
            VisionInsuranceOthersName: patientInsurance.VisionInsuranceOthersName.value,
            VisionInsuranceGroupNumber: patientInsurance.VisionInsuranceGroupNumber.value,
            VisionInsuranceRelationToPatient: patientInsurance.VisionInsuranceRelationToPatient.value,
            VisionInsuranceCarrierServiceNum: patientInsurance.VisionInsuranceCarrierServiceNum.value,
            VisionInsuranceSubscriberDOB: visionDate.toLocaleDateString(),
            MedicalInsuranceCarrier: patientInsurance.MedicalInsuranceCarrier.value,
            MedicalInsuranceSubscriberID: patientInsurance.MedicalInsuranceSubscriberID.value,
            MedicalInsuranceOthersName: patientInsurance.MedicalInsuranceOthersName.value,
            MedicalInsuranceGroupNumber: patientInsurance.MedicalInsuranceGroupNumber.value,
            MedicalInsuranceRelationToPatient: patientInsurance.MedicalInsuranceRelationToPatient.value,
            MedicalInsuranceCarrierServiceNum: patientInsurance.MedicalInsuranceCarrierServiceNum.value,
            MedicalInsuranceSubscriberDOB: medicalDate.toLocaleDateString(),

        }
        if (state.patientInsurance) {

            //GA-08
			Event(
                false,
                'click_continue_insurance_screen',
                AppendLabel(state.session.CoverKey, state.session.LocKey?.toString(), state.session.AcctKey)
             )
             
            const provider = state.providers.providers.find((x) => x.AcctKey == state.availability?.availability.AcctKey);
            PatientInsuranceService.savePatientInsurance(newVal, { ...state.session, AcctKey: provider?.AcctKey })
                .then(() => {
                    setLoading(false)
                    NavigatePage(dispatch, "next", state, navigate, PageName.CustomQuestion)
/*                     const url = getProperURL('/CustomQuestion', state.embed)
                    Navigate(url) */
                    // Navigate('/CustomQuestion')
                })
                .catch((e) => {
                    setLoading(false)
                    console.error(e);
                });
        }
    }
    function daysInMonth(month : any, year : any) {
        return new Date(year, month, 0).getDate();
    }
    function isLeapYear(year: number) {
        return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)
    }

    function getDays(month: any, days: any, insType: any) {
        if (['04', '06', '09', '11'].includes(month)) {
            if (insType === "Vision") {
                if (days > 30) {
                    return setErrorVisionDay("Invalid Day");
                }
            }
            if (insType === "Medical") {
                if (days > 30) {
                    return setErrorMedicalDay("Invalid Day");
                }
            }
        }
        else if (month === '02') {
          
            if (insType === "Vision") {
            
                if (days > 28) {
                    if(isLeapYear(valYear as any)){
                        if(days > 29){
                            return setErrorVisionDay("Invalid Day"); 
                        }
                        else{
                            return setErrorVisionDay("");
                        }
                      
                    }
                    else{
                        return setErrorVisionDay("Invalid Day"); 
                    }
                   
                }

            }
            if (insType === "Medical") {
                if (days > 28) {
                    if(isLeapYear(medYear as any)){
                        if(days > 29){
                            return setErrorMedicalDay("Invalid Day");
                        }
                        else{
                            return setErrorMedicalDay("");
                        }
                        
                    }
                    else{
                        return setErrorMedicalDay("Invalid Day");
                    }
                  
                }
            }
        }
        else{
            if (insType === "Vision") {
                if (days < 32) {
                    return setErrorVisionDay("");
                }
            }
            if (insType === "Medical") {
                if (days < 32) {
                    return setErrorMedicalDay("");
                }
            }
        }
    

    }

    const checkFutureDays = (month :any,days:any,year:any,insType:any) =>{
        let dobInput = new Date(year, month - 1, days);
        let currentDate = new Date()
       
        if(insType === "Vision"){
            if(dobInput >= currentDate){
          
                return setErrorVisionYear("DOB must be in the past")  
            }
            else{
                return setErrorVisionYear("")   
            }
        }
        else{
            if(dobInput >= currentDate){
                return setErrorMedicalYear("DOB must be in the past")  
            } 
            else{
                return setErrorMedicalYear("")   
            }
        }
    }
 
     
    const changeValMonth = (event: any) => {
        let onlyDigits = event.target.value.replace(/\D/g, "");
        if (patientInsurance.VisionInsuranceSubscriberDOB.enabled === 1) {
      
            if (onlyDigits < 0 || onlyDigits > 12) {
                setErrorVisionMonth("Invalid Month");
            }
            else if(onlyDigits === "00" ||onlyDigits === "0" ){
                setErrorVisionMonth("Invalid Month");
            }
            else {
                setErrorVisionMonth("");
            }
            if (onlyDigits !== "") {
                setReqVisionMonth("")
            }
            getDays(onlyDigits,valDay,"Vision");
            if(onlyDigits === "00" ||onlyDigits === "0" ){
                setErrorVisionMonth("Invalid Month");
            }
            checkFutureDays(onlyDigits,valDay,valYear,"Vision")
        }
        else {
            setErrorVisionMonth("");
        }
        setValMonth(onlyDigits)
    }
    const changeValDay = (event: any) => {
        let onlyDigits = event.target.value.replace(/\D/g, "");
        if (patientInsurance.VisionInsuranceSubscriberDOB.enabled === 1) {
            if (onlyDigits < 0 || onlyDigits > 31) {
                setErrorVisionDay("Invalid Day");
            }
            else if(onlyDigits === "00" ||onlyDigits === "0" ){
                setErrorVisionDay("Invalid Day"); 
            }
            
            else {
                setErrorVisionDay("");
            }
            if (onlyDigits !== "") {
                setReqVisionDay("")
            }
            getDays(valMonth,onlyDigits,"Vision");
            if(onlyDigits === "00" ||onlyDigits === "0" ){
                setErrorVisionDay("Invalid Day"); 
            }
            checkFutureDays(valMonth,onlyDigits,valYear,"Vision")

        }
        setValDay(onlyDigits)
    }
 
    const changeValYear = (event: any) => {
        let onlyDigits = event.target.value.replace(/\D/g, "");
        const leapYear = isLeapYear(onlyDigits);
        if (patientInsurance.VisionInsuranceSubscriberDOB.enabled === 1) {
            if (onlyDigits < 1900) {
                setErrorVisionYear("Invalid Year");
            }
            else if(leapYear){
                if(valDay as any >= 29){
                    setErrorVisionDay("")
                }
            }
            else if(!leapYear){
                if(valDay as any >= 29){
                    setErrorVisionDay("Invalid Day")
                }
            }
            else if(onlyDigits > Today.getFullYear() )
            {
                setErrorVisionYear("Invalid Year");
            }
            else {
                setErrorVisionYear("");
            }
            if (onlyDigits !== "") {
                setReqVisionYear("")
            }
           
               checkFutureDays(valMonth,valDay,onlyDigits,"Vision")
               if (onlyDigits < 1900) {
                setErrorVisionYear("Invalid Year");
            }
        }
        setValYear(onlyDigits)
    }
    const changeMedMonth = (event: any) => {
        let onlyDigits = event.target.value.replace(/\D/g, "");
        if (patientInsurance.MedicalInsuranceSubscriberDOB.enabled === 1) {
            if (onlyDigits < 0 || onlyDigits > 12) {
                setErrorMedicalMonth("Invalid Month");
            }
            else if(onlyDigits === "00" ||onlyDigits === "0" ){
                setErrorMedicalMonth("Invalid Month");
            }
            else {
                setErrorMedicalMonth("");
            }
            if (onlyDigits !== "") {
                setReqMedicalMonth("")
            }
            getDays(onlyDigits,medDay,"Medical");
            if(onlyDigits === "00" ||onlyDigits === "0" ){
                setErrorMedicalMonth("Invalid Month");
            }
            checkFutureDays(onlyDigits,medDay,medYear,"Medical")
        }
        else {
            setErrorMedicalMonth("");
        }
        setMedMonth(onlyDigits)
    }
    const changeMedlDay = (event: any) => {
        let onlyDigits = event.target.value.replace(/\D/g, "");
        if (patientInsurance.MedicalInsuranceSubscriberDOB.enabled === 1) {
            if (onlyDigits < 0 || onlyDigits > 31) {
                setErrorMedicalDay("Invalid Day");
            }
            else if(onlyDigits === "00" ||onlyDigits === "0" ){
                setErrorMedicalDay("Invalid Day");
            }
         else {
                setErrorMedicalDay("");
            }
            if (onlyDigits !== "") {
                setReqMedicalDay("")
            }
            getDays(medMonth,onlyDigits,"Medical");
            if(onlyDigits === "00" ||onlyDigits === "0" ){
                setErrorMedicalDay("Invalid Day");
            }
            checkFutureDays(medMonth,onlyDigits,medYear,"Vision")
        }

        setMedDay(onlyDigits)
    }
    const changeMedYear = (event: any) => {
        let onlyDigits = event.target.value.replace(/\D/g, "");
         const leapYear = isLeapYear(onlyDigits);
        if (patientInsurance.MedicalInsuranceSubscriberDOB.enabled === 1) {
            if (onlyDigits < 1900) {
                setErrorMedicalYear("Invalid Year");
            }
            else if(leapYear){
                if(medDay as any >= 29){
                    setErrorMedicalDay("")
                }
            }
            else if(!leapYear){
                if(medDay as any >= 29){
                    setErrorMedicalDay("Invalid Day")
                }
            }
            else if(onlyDigits > Today.getFullYear() )
            {
                setErrorMedicalYear("Invalid Year");
            }
            else {
                setErrorMedicalYear("");
            }
            if (onlyDigits !== "") {
                setReqMedicalYear("")
            }
            checkFutureDays(medMonth,medDay,onlyDigits,"Medical")
            if (onlyDigits < 1900) {
                setErrorMedicalYear("Invalid Year");
            }
        }
        else {
            setErrorMedicalYear("");
        }
        setMedYear(onlyDigits)
    }
    const capitalize = (data: any) => {
        let capitalizeName = data.charAt(0).toUpperCase() + data.slice(1);
        setCapName(capitalizeName);
    }
    return (
        <div>
            <Box
                display={"flex"}
                alignItems={"center"}
                className="header-partical"
            >
                <Box flexGrow={1}>
                    <Typography variant="h6"
                        fontWeight={"500"}
                        sx={{
                            fontSize: "16px",
                            fontWeight: "600",
                        }} >
                        {locationName}
                    </Typography>
                </Box>
                <Box sx={{}}>
                    <Typography
                        variant="h6"
                        fontWeight={"500"}
                        sx={{
                            fontSize: "16px",
                            fontWeight: "600",
                        }}
                    >
                        <div>{dDate} - {TimeConvert(dTime)}</div>
                    </Typography>
                </Box>
            </Box>
            <Box borderBottom={"0.5px solid black !important"} className="Box-p">
                <div className="Typogrphy-pb title-name"><b>{providerName}</b><span className="patient-name-title"> with <b>{providerFirstname} {ProviderLastName},{providerDegree}</b></span></div>
            </Box>
            <div className="header-top">
                <div className="Typogrphy-pb title-name">Patient:<b>{" " + capName + " " + patientLastName}</b>  <span className="patient-name-title"> &nbsp;&nbsp;&nbsp;Email:<b>{patientEmail}</b></span></div>
            </div>

            {!hideVisionInsuranceSection &&
                <>
                    <form className="form-div"  >
                        <div className='insuranceForm'>
                            <div className='insurance-type-title'><b>Vision Insurance</b></div>
                            <div className="dropdowns">
                                <Box>
                                    <div className='form-control inputdetails inputAligns'>
                                        {patientInsurance.VisionInsuranceCarrier.enabled != -1 &&
                                            <>
                                                <label className="field-label">Insurance Provider</label>
                                                <div className="error">
                                                    <>  {patientInsurance.VisionInsuranceCarrier.error} </>
                                                </div>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    value={patientInsurance.VisionInsuranceCarrier.value as any}
                                                    options={insuranceTypeOptions}
                                                    sx={{ width: 300 }}
                                                    onChange={onChangVisionForm}
                                                    renderInput={(params) => <TextField {...params} placeholder='Choose a Carrier' className='styled-input' />}
                                                />

                                            </>
                                        }
                                    </div>
                                </Box>
                                <Box>

                                    {!hideVisionInsurance &&
                                        <>
                                            <div className="form-control border-radius inputAlign">
                                                {patientInsurance.VisionInsuranceSubscriberID.enabled != -1 &&
                                                    <>
                                                        <label className="field-label">Subscriber Id</label>
                                                        <div className="error">
                                                            <>  {patientInsurance.VisionInsuranceSubscriberID.error} </>
                                                            <input placeholder={patientInsurance.VisionInsuranceSubscriberID.enabled == 1 ? "Subscriber ID: *" : " Subscriber ID:"} onChange={(e) => onChangeSetForm(e, 'VisionInsuranceSubscriberID')} value={patientInsurance.VisionInsuranceSubscriberID.value} type="text" maxLength={50} />
                                                        </div>
                                                    </>}
                                            </div>
                                            <div className='form-control inputdetails inputAlign'>
                                                {patientInsurance.VisionInsuranceRelationToPatient.enabled != -1 &&
                                                    <>
                                                        <label className="field-label">Relation to Patient</label>
                                                        <div className="error">
                                                            <>  {patientInsurance.VisionInsuranceRelationToPatient.error} </>
                                                        </div>
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            value={patientInsurance.VisionInsuranceRelationToPatient.value as any}
                                                            options={relationToPatientOptions}
                                                            onChange={onChangeVisionRelation}
                                                            sx={{ width: 300, borderRadius: 50 }}
                                                            renderInput={(params) => <TextField {...params} placeholder='Choose Relation to Patient' className='styled-input' />} />
                                                    </>}
                                            </div>
                                        </>
                                    }
                                </Box>
                            </div>
                            {!hideVisionInsurance &&
                                <>
                                    {!hideVisionOtherInsurance &&
                                        <div className="form-control">
                                            {patientInsurance.VisionInsuranceOthersName.enabled != -1 &&
                                                <>
                                                    <label className="field-label">Other Insurance Name</label>
                                                    <div className="error">
                                                        <>  {patientInsurance.VisionInsuranceOthersName.error} </>
                                                        <input placeholder="Other Insurance Name: *" onChange={(e) => onChangeSetForm(e, 'VisionInsuranceOthersName')} value={patientInsurance.VisionInsuranceOthersName.value} type="text" maxLength={50} />
                                                    </div>
                                                </>}
                                        </div>
                                    }

                                    {patientInsurance.VisionInsuranceSubscriberDOB.enabled != -1 &&
                                        <>
                                            <label className="labelDate field-label">Subscriber DOB</label>
                                            <div className="dob birth-date">
                                                <div className="form-control">
                                                    <div className="error">
                                                        <>  {reqVisionMonth} </>
                                                        <input value={valMonth} onChange={changeValMonth} type="text" placeholder="MM" maxLength={2} />
                                                    </div>
                                                    <span className="errorMessage"><>{errorVisionMonth}
                                                        {/* {dobInput >= currentDate && 'Dob must in the past'} */}
                                                    </></span>
                                                </div>
                                                <div className="form-control">
                                                    <div className="error">
                                                        <>  {reqVisionDay} </>
                                                        <input value={valDay} onChange={changeValDay} type="text" placeholder="DD" maxLength={2} />
                                                    </div>
                                                    <span className="errorMessage"><>{errorVisionDay}</> </span>
                                                </div>
                                                <div className="form-control">
                                                    <div className="error">
                                                        <>  {reqVisionYear} </>
                                                        <input value={valYear} onChange={changeValYear} type="text" placeholder="YYYY" maxLength={4} />
                                                    </div>
                                                    <span className="errorMessage"><>{errorVisionYear}</> </span>
                                                </div>
                                            </div>

                                        </>
                                    }
                                    <div className="form-control">

                                        {patientInsurance.VisionInsuranceGroupNumber.enabled != -1 &&
                                            <>
                                                <label className="field-label">Group Number</label>
                                                <div className="error">
                                                    <> {patientInsurance.VisionInsuranceGroupNumber.error}  </>
                                                    <input placeholder={patientInsurance.VisionInsuranceGroupNumber.enabled == 1 ? "Group Number: *" : " Group Number:"} onChange={(e) => onChangeSetForm(e, 'VisionInsuranceGroupNumber')} value={patientInsurance.VisionInsuranceGroupNumber.value} type="text" maxLength={50} />
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="form-control">
                                        {patientInsurance.VisionInsuranceCarrierServiceNum.enabled != -1 &&
                                            <>
                                                <label className="field-label">Insurance Phone number</label>
                                                <div className="error">
                                                    <> <p>{patientInsurance.VisionInsuranceCarrierServiceNum.error}</p>  </>
                                                    <input placeholder={patientInsurance.VisionInsuranceCarrierServiceNum.enabled == 1 ? "Carrier Service Number: *" : " Carrier Service Number:"} onChange={(e) => onChangeSetForm(e, 'VisionInsuranceCarrierServiceNum')} value={patientInsurance.VisionInsuranceCarrierServiceNum.value} type="text" maxLength={10} />
                                                </div>
                                            </>
                                        }
                                    </div>
                                </>
                            }
                        </div>

                    </form>
                </>
            }
            {!hideMedicalInsuranceSection &&
                <>
                    <div>
                        <form className="form-div">
                            <div className='insuranceForm'>
                                <div className='insurance-type-title'><b>Medical Insurance</b></div>
                                <div className="dropdowns">
                                    <Box>
                                        <div className='form-control inputdetails inputAligns'>
                                            {patientInsurance.MedicalInsuranceCarrier.enabled != -1 &&
                                                <>
                                                    <label className="field-label">Insurance Provider</label>
                                                    <div className="error">
                                                        <>  {patientInsurance.MedicalInsuranceCarrier.error} </>
                                                    </div>
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        options={insuranceTypeOptions}
                                                        value={patientInsurance.MedicalInsuranceCarrier.value as any}
                                                        onChange={onChangMedicalForm}
                                                        sx={{ width: 300 }}
                                                        renderInput={(params) => <TextField {...params} placeholder='Choose a Carrier' className='styled-input' />}
                                                    />
                                                </>
                                            }
                                        </div>
                                    </Box>
                                    <Box>

                                        {!hideMedicalInsurance &&
                                            <>
                                                <div className="form-control border-radius inputAlign">
                                                    {patientInsurance.MedicalInsuranceSubscriberID.enabled != -1 &&
                                                        <>
                                                            <label className="field-label">Subscriber Id</label>
                                                            <div className="error">
                                                                <>  {patientInsurance.MedicalInsuranceSubscriberID.error} </>
                                                                <input placeholder={patientInsurance.MedicalInsuranceSubscriberID.enabled == 1 ? "Subscriber ID: *" : " Subscriber ID:"} onChange={(e) => onChangeSetForm(e, 'MedicalInsuranceSubscriberID')} value={patientInsurance.MedicalInsuranceSubscriberID.value} type="text" maxLength={50} />
                                                            </div>
                                                        </>
                                                    }
                                                </div>

                                                <div className='form-control inputdetails inputAlign'>
                                                    {patientInsurance.MedicalInsuranceRelationToPatient.enabled != -1 &&
                                                        <>
                                                            <label className="field-label">Relation to Patient</label>
                                                            <div className="error">
                                                                <>  {patientInsurance.MedicalInsuranceRelationToPatient.error} </>
                                                            </div>
                                                            <Autocomplete
                                                                id="combo-box-demo"
                                                                options={relationToPatientOptions}
                                                                value={patientInsurance.MedicalInsuranceRelationToPatient.value as any}
                                                                onChange={onChangeMedicalRelation}
                                                                sx={{ width: 300, borderRadius: 50 }}
                                                                renderInput={(params) => <TextField {...params} placeholder='Choose Relation to Patient' className='styled-input' />} />
                                                        </>
                                                    }
                                                </div>
                                            </>
                                        }
                                    </Box>
                                </div>

                                {!hideMedicalInsurance &&
                                    <>

                                        {!hideMedicalOtherInsurance &&
                                            <div className="form-control">
                                                {patientInsurance.MedicalInsuranceOthersName.enabled != -1 &&
                                                    <>
                                                        <label className="field-label">Other Insurance Name</label>
                                                        <div className="error">
                                                            <>  {patientInsurance.MedicalInsuranceOthersName.error} </>
                                                            <input placeholder="Other Insurance Name: *" onChange={(e) => onChangeSetForm(e, 'MedicalInsuranceOthersName')} value={patientInsurance.MedicalInsuranceOthersName.value} type="text" maxLength={50} />
                                                        </div>
                                                    </>}
                                            </div>
                                        }
                                        {patientInsurance.MedicalInsuranceSubscriberDOB.enabled != -1 &&
                                            <>
                                                <label className="labelDate field-label">Subscriber DOB </label>
                                                <div className="dob birth-date">
                                                    <div className="form-control">
                                                        <div className="error">
                                                        <>  {reqMedicalMonth} </>
                                                            <input value={medMonth} onChange={changeMedMonth} type="text" placeholder="MM" maxLength={2} />
                                                        </div>
                                                        <span className="errorMessage"><>{errorMedicalMonth}
                                                        </></span>
                                                    </div>
                                                    <div className="form-control">
                                                        <div className="error">
                                                        <>  {reqMedicalDay} </>
                                                            <input value={medDay} onChange={changeMedlDay} type="text" placeholder="DD" maxLength={2} />
                                                        </div>
                                                        <span className="errorMessage"><>{errorMedicalDay}</> </span>
                                                    </div>
                                                    <div className="form-control">
                                                        <div className="error">
                                                        <>  {reqMedicalYear} </>
                                                            <input value={medYear} onChange={changeMedYear} type="text" placeholder="YYYY" maxLength={4} />
                                                        </div>
                                                        <span className="errorMessage"><>{errorMedicalYear}</> </span>
                                                    </div>
                                                </div>
                                            </>}
                                        <div className="form-control">
                                            {patientInsurance.MedicalInsuranceGroupNumber.enabled != -1 &&
                                                <>
                                                    <label className="field-label">Group Number</label>
                                                    <div className="error">
                                                        <> {patientInsurance.MedicalInsuranceGroupNumber.error}  </>
                                                        <input placeholder={patientInsurance.MedicalInsuranceGroupNumber.enabled == 1 ? "Group Number: *" : " Group Number:"} onChange={(e) => onChangeSetForm(e, 'MedicalInsuranceGroupNumber')} value={patientInsurance.MedicalInsuranceGroupNumber.value} type="text" maxLength={50} />
                                                    </div>
                                                </>}
                                        </div>
                                        <div className="form-control">
                                            {patientInsurance.MedicalInsuranceCarrierServiceNum.enabled != -1 &&
                                                <>
                                                    <label className="field-label">Insurance Phone number</label>
                                                    <div className="error">
                                                        <>  {patientInsurance.MedicalInsuranceCarrierServiceNum.error} </>
                                                        <input placeholder={patientInsurance.MedicalInsuranceCarrierServiceNum.enabled == 1 ? "Carrier Service Number: *" : " Carrier Service Number:"} onChange={(e) => onChangeSetForm(e, 'MedicalInsuranceCarrierServiceNum')} value={patientInsurance.MedicalInsuranceCarrierServiceNum.value} type="text" maxLength={10} />
                                                    </div>
                                                </>}
                                        </div>
                                    </>
                                }
                            </div>
                        </form>
                    </div>
                </>
            }


            <Box className="footerdata">
                <span className="footer-form"></span>
                <Grid className="text-continue">
                    <Button style={{ backgroundColor: "var(--fpc-blue)" }} variant="contained" disabled={!isInsuranceValid || loading} onClick={handleClick}>Continue</Button>
                </Grid>
            </Box>
            {loading === true &&
                <>
                    <div className="spinner">
                        <Spinner />
                    </div>

                </>}
        </div>
    )
}

export default VisionInsurance