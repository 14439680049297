import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from '@mui/material'
import "./AppointmentConfirmation.css";
import { useNavigate } from 'react-router-dom';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { AppContext, AppContextType } from "../../App";
import EventIcon from '@mui/icons-material/Event';
import FlagIcon from '@mui/icons-material/Flag';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getFormattedDate } from "../provider/Header/Header";
import CustomMessagesService from "../../services/CustomMessagesService";
import { setSessionState } from "../../features/session/sessionSlice";
import { NavigatePage, PageName, getProperURL } from "../../utils/Util";
import { useNavigationForcedAlert } from "../hooks/useApptConfirmNav";
import { AppendLabel, Event , PageView} from '../../utils/GA';

function AppointmentConfirmation() {

    const state = useAppSelector(state => state)
    const startTime = state?.availability.availability.AppointmentDateTime;
    const endTime = new Date(state?.availability?.availability.AppointmentDateTime?.getTime() + 30*60000).toISOString().replace(/-/g, "").replace(/:\s*/g, "");
    const { formData, setFormData } = useContext(AppContext) as AppContextType;
    const ics = require('ics');
       const patientFirstName = state.patient.FirstName;
    const patientLastName = state.patient.LastName;
    const patientEmail = state.patient.Email;
    const homeNumber = state.patient.Home;
    const [customMessage, setCustomMessage] = useState("")
    const dispatch = useAppDispatch();

    //Display an alert if the user has tried to click the back button and was forced back to this page
    useNavigationForcedAlert();

    let dtFormat = new Intl.DateTimeFormat('en-US',{
        day:'2-digit',
        month:'short',
        year:'numeric',
        hour:'numeric',
        minute:'numeric'
    })

    useEffect(()  => {
        var test = state
		const getCustomMessages = async () => {
			let customEndingMessage = "";
			if (state.provider && state.provider !== null) {
				const response = await CustomMessagesService.retrieveEndingMessage({
					...state.session,
					AcctKey: state?.providers.provider.AcctKey,
				});

				customEndingMessage = response.endingMessage ?? ''

                    setCustomMessage(customEndingMessage);
			}
			else
            setCustomMessage(customEndingMessage);
		};  
        getCustomMessages();      
      }, []);

      useEffect(() => {
        PageView(
            `pageview_appointment_confirmation_screen_${AppendLabel(state.session.CoverKey, state.session.LocKey?.toString(), state.session.AcctKey)}`
        )
    },[])

    const Navigate = useNavigate();

    const googleLink = `https://calendar.google.com/calendar/u/0/r/eventedit?dates=${startTime}/${endTime}&location=${state?.appointmentData.locations[0].Address}&text=Appointment+at+${state?.appointmentData.locations[0].Name}&details=${state?.providers?.provider.Speciality} Appointment with${state?.providers?.provider.Title ? state?.providers?.provider.Title : ''} ${state?.providers?.provider.FirstName} ${state?.providers?.provider.LastName} at ${state?.appointmentData.locations[0].Name}`;

    const onScheduleAnotherFamily = () => {
          //GA-16
            Event(
                true,
                'click_schedule_family_member_appointment_confirmation_screen',
                AppendLabel(state.session.CoverKey, state.session.LocKey?.toString(), state.session.AcctKey)
            )
        const updateSession = {...state.session, LastPage : true}
          dispatch(setSessionState(updateSession))
          NavigatePage(dispatch,"next",state,Navigate,PageName.LandingPage)
          //const url =  getProperURL('/', state.embed)
          //Navigate(url)
        //Navigate('/')
    }

    
    const onGoogleCallClick = () => {
         //GA-17
         Event(
            true,
            'click_add_to_calendar_appointment_confirmation_screen',
            AppendLabel(state.session.CoverKey, state.session.LocKey?.toString(), state.session.AcctKey)
        )
        window.open(googleLink);
    }
    const onAppleCalClick = () => {
        ics.createEvent(Event, (error:any, value:any) => {
            if (error) {
                console.error(error);
            }

            const element = document.createElement('a');
            element.setAttribute('href', 'data:text/calendar;charset=utf-8,' + encodeURIComponent(value));
            element.setAttribute('download', "event.ics");

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        })
    }
  
    const handleClickBack = () => {
        Navigate(-1)
    }
    return (
        <div>
            <div className='confirmation-appointment-page'>
                <div className='header-texts'>
                    <Typography variant="h6"
                        fontWeight={"500"}
                        sx={{
                            fontSize: "18px",
                            fontWeight: "600",
                        }} >
                        Your appointment has been scheduled!
                    </Typography>
                    <p className='appointment-details-subheader'>Please check your email for a confirmation with appointment details.</p>
                </div>
                <div className='box details-box'>
                 {/* <div className='bg-Black'><p>{customMessage}</p></div>  */}
                 {<div className='bg-Black'
                        dangerouslySetInnerHTML={{__html: customMessage}}
                        />}
                    <div className='confirmation-appointment-label'>
                        <div className='mainPage inner-box details-container'>
                            <div className='header-text appointment-details-header-text'>
                                {/* <Box className="icon appointment-icon" sx={{ p: 6 }}><FlagIcon/></Box> */}
                                <div className="appointment-icon-container">
                                    <p className="icon appointment-icon"><FlagIcon/></p>
                                </div>
                                <p className='appointment-details-header header-texts'>Appointment Details.</p>
                            </div>
                            <div>
                                <p className='confirmationetials details-heading'>Time</p>
                                <p className='confirmation-detail-value'>{dtFormat.format(startTime)} </p>
                            </div>
                            <div>
                                <p className='confirmationetials details-heading'><b>Location</b></p>
                                <p className='confirmation-detail-value'> {`${state?.appointmentData.locations[0].Address}`}</p>
                            </div>
                            <div>
                                <p className='confirmationetials details-heading head-title confirmation-height'><b>Doctor</b></p>
                                <div className='confirmation-height confirmation-detail-value'>
                                    <p>Physician:  {`${state?.providers.provider.FirstName} ${state?.providers.provider.LastName}`}</p>
                                    <p>Appointment Type: {`${state?.appointmentData.appointmentType.Name}`}</p>
                                </div>
                            </div>
                            <div>
                                <p className='confirmationetials head-title confirmation-height'><b>Patient</b></p>
                                <div className='confirmation-height confirmation-detail-value'>
                                    <p>Name: {state.patient.FirstName + " " + state.patient.LastName}</p>
                                    <p>Email: {state.patient.Email ?? ''}</p>
                                    <p>Phone: {state.patient.Home ?? ''}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Box className="footer-data footer-details" >
                <div className='footer-calender'>
                    <div onClick={onAppleCalClick}><CalendarMonthIcon  /></div>
                    <div className='footer-icon'>
                        <div onClick={onGoogleCallClick}><EventIcon /></div>
                        <div><p>Add to Calendar</p></div>
                    </div>
                </div>
                <Button className='bg-white' variant="contained" type="submit" sx={{ borderRadius: 10 }} onClick={onScheduleAnotherFamily}>
                <span><PermIdentityIcon/></span>
                    <div className='button-box'>Schedule a family member</div>
                </Button>
            </Box>
        </div>
    )
}

export default AppointmentConfirmation