import DemographicsConfig, { createSampleDemographicsConfig } from './DemographicsConfig'
import Patient from './Patient'
import { SessionModel } from './Session';


export interface DemographicsState {
  demographics :Demographics,
   
}


export default interface Demographics extends Patient {
    CoverKey: string | undefined | null
    ReferredBy1: string | undefined | null
    , ReferredBy2: string | undefined | null
    , ReferredBy3: string | undefined | null
    , ReferredBy4: string | undefined | null
    , ReferredBy5: string | undefined | null
     , Config: DemographicsConfig
    , UpdateFromWSUI: boolean | undefined | null
}

export const mapDemographicsFromApi = (data: any): Demographics => {
    return {
        CoverKey: data.config.coverKey,
        AddKey: data.patient.addKey,
        AcctKey: data.config.acctKey,
        InsKey: data.patient.insKey,
        LocKey: data.config.locKey,
        Lang: data.patient.lang,
        PatientID: data.patient.patientID,
        Status: data.patient.status,
        FirstName: data.patient.firstName?.trim(),
        LastName: data.patient.lastName?.trim(),
        Title: data.patient.title?.trim(),
        Address1: data.patient.address1?.trim(),
        Address2: data.patient.address2?.trim(),
        City: data.patient.city?.trim(),
        State: data.patient.state?.trim(),
        Zip: data.patient.zip?.trim(),
        Home: data.patient.home?.trim(),
        Work: data.patient.work?.trim(),
        Cel: data.patient.cel?.trim() == '' ? data.patient.home?.trim() : data.patient.cel?.trim(),
        Email: data.patient.email?.trim(),
        Fax: data.patient.fax?.trim(),
        Created: data.patient.created,
        L4DSSN: data.patient.l4DSSN?.trim(),
        Country: data.patient.country?.trim(),
        DOB: data.patient.dob ? new Date(data.patient.dob) : null,
        ReferredBy1: data.referredBy1?.trim(),
        ReferredBy2: data.referredBy2?.trim(),
        ReferredBy3: data.referredBy3?.trim(),
        ReferredBy4: data.referredBy4?.trim(),
        ReferredBy5: data.referredBy5?.trim(),
        Config: {
            DHomePhone: data.config.dHomePhone,
            DWorkPhone: data.config.dWorkPhone,
            DCellPhone: data.config.dCellPhone,
            DEmail: data.config.dEmail,
            DDOB: data.config.ddob,
            DGender: data.config.dGender,
            DAddress1: data.config.dAddress1,
            DAddress2: data.config.dAddress2,
            DCity: data.config.dCity,
            DState: data.config.dState,
            DZip: data.config.dZip,
            DCountry: data.config.dCountry,
            DL4DSSN: data.config.dL4DSSN,
            DReferredBy1: data.config.dReferredBy1,
            DReferredBy2: data.config.dReferredBy2,
            DReferredBy3: data.config.dReferredBy3,
            DReferredBy4: data.config.dReferredBy4,
            DReferredBy5: data.config.dReferredBy5
        },
        IsValid: false,
        UpdateFromWSUI: false,
        Gender:data.patient.gender,
    };
}

export const mapDemographicsToApi = (demographics: Demographics, session: SessionModel): any => {
    return {
        "logKey": session.LogKey ?? '',
        "coverKey": session.CoverKey ?? '',
        "groupKey": session.GroupKey ?? '',
        "locKey": session.LocKey ?? '',
        "acctKey": session.AcctKey ?? '',
        "addKey": session.AddKey ?? '',
        "taskKey": session.TaskKey ?? '',
        "apptKey": session.ApptKey ?? '',
        "apptDateTime": session.ApptDateTime,
        "currentPage": session.CurrentPage ?? 0,
        "data": {
            "patient": {
                "addKey": demographics.AddKey,
                "acctKey": session.AcctKey,
                "insKey": demographics.InsKey,
                "locKey": session.LocKey,
                "lang": demographics.Lang,
                "patientID": demographics.PatientID,
                "status": demographics.Status,
                "firstName": demographics.FirstName?.trim(),
                "lastName": demographics.LastName?.trim(),
                "title": demographics.Title?.trim(),
                "address1": demographics.Address1?.trim(),
                "address2": demographics.Address2?.trim(),
                "city": demographics.City?.trim(),
                "state": demographics.State?.trim(),
                "zip": demographics.Zip?.trim(),
                "home": demographics.Home?.trim(),
                "work": demographics.Work?.trim(),
                "cel": demographics.Cel?.trim(),
                "email": demographics.Email?.trim(),
                "fax": demographics.Fax?.trim(),
                "created": demographics.Created,
                "l4DSSN": demographics.L4DSSN,
                "country": demographics.Country?.trim(),
                "dob": demographics.DOB?.toLocaleString('en-US'),
                "gender": demographics.Gender,
            },
            "config": {
                "coverKey": demographics.CoverKey,
                "locKey": demographics.LocKey,
                "acctKey": demographics.AcctKey,
                "dHomePhone": demographics.Config.DHomePhone,
                "dWorkPhone": demographics.Config.DWorkPhone,
                "dCellPhone": demographics.Config.DCellPhone,
                "dEmail": demographics.Config.DEmail,
                "ddob": demographics.Config.DDOB,
                "dGender": demographics.Config.DGender,
                "dAddress1": demographics.Config.DAddress1,
                "dAddress2": demographics.Config.DAddress2,
                "dCity": demographics.Config.DCity,
                "dState": demographics.Config.DState,
                "dZip": demographics.Config.DZip,
                "dL4DSSN": demographics.Config.DL4DSSN,
                "dReferredBy1": demographics.Config.DReferredBy1,
                "dReferredBy2": demographics.Config.DReferredBy2,
                "dReferredBy3": demographics.Config.DReferredBy3,
                "dReferredBy4": demographics.Config.DReferredBy4,
                "dReferredBy5": demographics.Config.DReferredBy5
            },
            "referredBy1": demographics.ReferredBy1,
            "referredBy2": demographics.ReferredBy2,
            "referredBy3": demographics.ReferredBy3,
            "referredBy4": demographics.ReferredBy4,
            "referredBy5": demographics.ReferredBy5,
            "updateFromWSUI": demographics.AddKey ? true : false
        }
    };
}