import React , { useContext, useEffect, useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useNavigate } from 'react-router-dom';
import "./MultipleAppointment.css";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import MultipleAppointmentDetails, { MultipleAppointmentDetailsData , mapMultipleAppointmentDetailsData} from '../../models/MultipleAppointments';
import { updateMultipleAppointments } from "../../features/MultipleAppointments/multipleAppointmentsSlice";
import MultipleAppointmentSelected, { MultipleAppointmentSelectedData, mapMultipleAppointmentSelectedData} from '../../models/MultipleAppointmentSelected';
import HashReturnObject from '../../models/HashReturnObject';
import { updateMultipleAppointmentSelected } from '../../features/MultipleAppointments/multipleAppointmentSelectedSlice';
import dayjs from 'dayjs';
import { boolean } from 'yup';
import { NavigatePage, PageName, getProperURL } from '../../utils/Util';
import useApptConfirmNav from '../hooks/useApptConfirmNav';
import { AppendLabel, Event , PageView} from '../../utils/GA';

function MultipleAppointments ()  {
    const Navigate = useNavigate()
    const dispatch = useAppDispatch()
    const state = useAppSelector(state => state)
    const [multipleAppointments,setMultipleAppointments] = useState<MultipleAppointmentDetails[]>(state.multipleAppointments.multipleAppointmentDetails);
    const [multipleSelectedAppointments,setMultipleSelectedAppointments] = useState<MultipleAppointmentSelected[]>(state.multipleAppointmentSelected.multipleAppointmentSelected);
    const [seletectedAppt, setSeletectedAppt] = useState({
        apptkeys: [''],
        response: [''],
      });
    

      useEffect(() => {
		if ( multipleSelectedAppointments.length > 0)
        {
            const Appt: any[] = multipleSelectedAppointments
            setSeletectedAppt({
                apptkeys: Appt,
                response: Appt,
              });
        }
	}, []);

    useEffect(() => {
        PageView(
            `pageview_multiple_appointments_screen_${AppendLabel(state.session.CoverKey, state.session.LocKey?.toString(), state.session.AcctKey)}`
        )
    },[])

    //If appointment confirmation is complete, force user back to the confirmation screen
    useApptConfirmNav();

    const handleClick = ()=>{
    //GA-10
	Event(
        false,
        'click_review_appointment_multiple_appointments_screen',
        AppendLabel(state.session.CoverKey, state.session.LocKey?.toString(), state.session.AcctKey)
    ) 
        
      dispatch(updateMultipleAppointmentSelected(seletectedAppt.apptkeys.filter((e) => e !== '')));
      NavigatePage(dispatch,"next",state,Navigate,PageName.AppointmentVisitDetails)
      //const url =  getProperURL('/appointmentvisitdetails', state.embed)
      //Navigate(url)         
     // Navigate('/appointmentvisitdetails')
    }

    const SelectAppointment =  (e: any) => {
        const { value, checked } = e.target;
        const { apptkeys } = seletectedAppt;
          
        //GA-11
	Event(
        false,
        'click_select_appointment_multiple_appointments_screen',
        AppendLabel(state.session.CoverKey, state.session.LocKey?.toString(), state.session.AcctKey)
    ) 
         
        if (checked) {
          setSeletectedAppt({
            apptkeys: [...apptkeys, value],
            response: [...apptkeys, value],
          });
        }  
        else {
          setSeletectedAppt({
            apptkeys: apptkeys.filter((e) => e !== value),
            response: apptkeys.filter((e) => e !== value),
          });
        }
        //console.log(seletectedAppt.apptkeys);
      };

    
      const isSelected = (AppKey: string) => {
            const Appt: any[] = multipleSelectedAppointments
            if (multipleSelectedAppointments.length > 0)
            {
               if (Appt.find((user) => user.includes(AppKey)))
               {
                  return  true
               }
               else
               {
                return  false;
               }
            }             
      };

    //   const isFound = multipleSelectedAppointments.some(element => {
    //     if (element === 1) {
    //       return true;
    //     }
    
    //     return false;
    //   });

      
    return (
        <div>
            <Box
                display={"flex"}
                alignItems={"center"}
                className="header-partical"
            >
                
                <Box flexGrow={1}>
                    <Typography variant="h6"
                        fontWeight={"500"}
                        sx={{
                            fontSize: "16px",
                            fontWeight: "600",
                        }} >
                        { state.availability.getInitialLocation.Name ?? ''}
                    </Typography>
                </Box>
                <Box sx={{}}>
                    <Typography
                        variant="h6"
                        fontWeight={"500"}
                        sx={{
                            fontSize: "16px",
                            fontWeight: "600",
                        }}
                    >
                        <div>{dayjs(state.availability.availability.AppointmentDateTime).format('dddd, MMMM DD')} - {dayjs(state.availability.availability.AppointmentDateTime).format('h:mm A')}</div>
                    </Typography>
                </Box>
            </Box>
            <Box borderBottom={"0.5px solid black !important"} className="Box-p">
                <div className="Typogrphy-pb top-heading">
                    <div>
                        <b>{state.appointmentData.appointmentType.Name}</b>
                        <span className="heading-subText"> with <b>{state.providers.provider.FirstName + ' ' + state.providers.provider.LastName + ', ' + state.providers.provider.Title ?? ''  }</b></span>
                    </div>
                </div>
            </Box>
            <div className="header-top">
                <div><label>Patient:</label> <b>{state.patient.FirstName + " " + state.patient.LastName}</b></div>
                <div><label>Email:</label> <b>{state.patient.Email ?? ''}</b></div>
            </div>
            <div className='multiple-appointment-page'>
                <div className='header-top-m d-flex booking-icon'>
                    <div className='header-text'>
                        <p className="mutiple-appointment-header">
                            <Box className="icon" sx={{p:6}}><CalendarMonthIcon sx={{ fontSize:50}}/></Box>
                            <span className='mutiple-appointment-sub'>We've found multiple appointments for you.
                                <p className='mutiple-appointment-subheader-desktop'>
                                    We noticed that you have another appointments(s) already scheduled.
                                </p>
                            </span>
                        </p>
                        <p className='mutiple-appointment-subheader'>We noticed that you have another appointments(s) already scheduled.</p>
                    </div>
                </div>
                <p className='existing-appointment'>Existing Appointment</p>
              
            {multipleAppointments.map((item) => (
            <div key={item.ApptKey} className='mutiple-appointment-label'>
            <div className='mainPage'>
                <div>
                    <p className='appointmentDetials'><b>Time</b></p>
                    <p><b>{dayjs(item.Appointment).format('dddd, MMMM DD')} - {dayjs(item.Appointment).format('h:mm A')}</b></p>
                </div>
                <div>
                    <p className='appointmentDetials location-text'><b>Location</b></p>
                    <p>{item.LocationName}</p>
                </div>
                <div>
                    <p className='appointmentDetials patient-text'><b>Patient</b></p>
                    <p>{item.PatientName}</p>
                </div>
            </div>
            <div className='appointmentBtn'>
                <button className='input-cb'><input type="checkbox" value={item.ApptKey} defaultChecked={ isSelected(item.ApptKey)}   onChange={(event) => SelectAppointment( event)} />
                    <span><b>Remove this Appointment</b></span>
                </button>
            </div>
        </div>
          ))}
                {/* <div className='mutiple-appointment-label borderBotton'>
                    <div className='mainPage'>
                        <div>
                            <p className='appointmentDetials'><b>Day/Date/Time</b></p>
                            <p><b>Monday, Mar 6th- 11am</b></p>
                        </div>
                        <div>
                            <p className='appointmentDetials location-text'><b>Location</b></p>
                            <p>Practical Vision-Smokey Mountain-NE Aurora</p>
                        </div>
                        <div>
                            <p className='appointmentDetials patient-text'><b>Patient</b></p>
                            <p>Robert Charmichael</p>
                        </div>
                    </div>
                    <div className='appointmentBtn'>
                        <button className='input-cb'><input type="checkbox" />
                            <span><b>Remove this Appointment</b></span>
                        </button>
                    </div>
                </div> */}
                <p className='appointment-changes'>Appointment changes will not be reflected untill you have fully completed booking.</p>
            </div>
            <Box className="footer-data" >
                <Grid className="text-continued">
                    <p>Choose the Appointments(s) to <b>remove,</b> or simply keep them, and move on to <b>"Review Appointment Details".</b> </p>
                </Grid>
                <Button style={{backgroundColor : "var(--fpc-blue)"}}  variant="contained" type="submit" sx={{borderRadius:10}} onClick={handleClick}>
                    <span className='submit-button'>Review Appointment Details</span>
                </Button>
            </Box>
        </div>
    )
}

export default MultipleAppointments